<template>
  <v-row wrap>
    <v-col sm="6">
      <UserForm
        :user="user"
        :submit="submit"
        :onInput="onInput"
        :loading="loading"
        :buttonText="$t('create')"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { onBeforeMount, defineComponent, ref, computed } from 'vue'
import { default as UserForm } from './Form.vue'
import Say from '@/utils/Say'
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { t } = useI18n()
const router = useRouter()

defineComponent({
  UserForm
})

const loading = ref(false)

const user = computed(() => {
  return store.getters.userCreate
})

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('userCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('user', 2), routeName: 'Users' },
    { text: t('userCreate') }
  ])
})

const submit = async() => {
  loading.value = true
  return await store.dispatch('users.create')
    .then((id) => {
      // Display success
      loading.value = false
      Say('success', t('userCreateSuccess'))
      // Redirect to the user edit screen
      router.push({ name: 'UsersEdit', params: { id: id } })
    }).catch((err) => {
      loading.value = false
      sayErrorResponse(err)
    })
}
const onInput = (name: string, value: any) => {
  store.commit('users.createUpdateInput', { name: name, value: value })
}
</script>
