<template>
  <v-form @submit.prevent="submitForm()" v-model="rules.valid" ref="form">
    <v-row wrap>
      <v-col>
        <v-card flat class="overflow-visible">
          <v-card-text>
            <v-tabs
              v-model="activeTab"
            >
              <!-- Tab headers -->
              <v-tab :key="`personal`">{{ $t('personalDetails') }}</v-tab>
              <v-tab :key="`employment`">{{ $t('employmentDetails') }}</v-tab>
              <v-tab :key="`user`">{{ $t('userDetails') }}</v-tab>
            </v-tabs>

            <!-- Tab bodies -->
            <v-window v-model="activeTab" class="overflow-visible">
                <v-window-item :key="`personal`">
                  <v-row class="pt-4">
                    <v-col sm="6">
                      <v-card flat :border="true" class="pa-4">
                    <v-text-field
                      :label="$t('name')"
                      v-model="employee.name"
                      required
                      :rules="rules.name"
                      @input="onInput('name', $event.target.value)"
                      variant="underlined"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('functionDescription')"
                      v-model="employee.function_description"
                      @input="onInput('function_description', $event.target.value)"
                      variant="underlined"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('phone')"
                      v-model="employee.phone"
                      :rules="rules.phone"
                      @input="onInput('phone', $event.target.value)"
                      variant="underlined"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('address')"
                      v-model="employee.address"
                      multi-line
                      auto-grow
                      rows="2"
                      @input="onInput('address', $event.target.value)"
                      variant="underlined"
                    ></v-text-field>
                  </v-card>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :key="`employment`">
                  <v-row class="pt-4">
                    <v-col sm="6">
                      <v-card flat :border="true" class="pa-4 overflow-visible">
                    <!-- Company -->
                    <v-checkbox
                      :label="$t('ownEmployee')"
                      v-model="va_employee"
                      hide-details
                    ></v-checkbox>
                    <v-text-field
                      :label="$t('company')"
                      v-model="employee.company"
                      @input="onInput('company', $event.target.value)"
                      persistent-hint
                      v-show="!va_employee"
                      variant="underlined"
                    ></v-text-field>

                    <!-- Employment start -->
                    <Datepicker
                      style="width: 250px;"
                      :placeholder="$t('dateStart')"
                      :model-value="employee.date_start"
                      :maxDate="employee.date_end ? employee.date_end : ''"
                      @update:value-datetime="onInput('date_start', $event)"
                    />

                    <!-- Employment end -->
                    <v-checkbox
                      :label="$t('isPreviousEmployee')"
                      v-model="date_end_datepicker_show"
                      hide-details
                    />
                    <Datepicker
                      style="width: 250px;"
                      :placeholder="$t('dateEnd')"
                      :model-value="employee.date_end"
                      @update:value-datetime="onInput('date_end', $event)"
                      :minDate="employee.date_start"
                      v-if="date_end_datepicker_show"
                    />

                    <!-- Flags -->
                    <v-checkbox
                      :label="$t('employeeCanBeProjectLeader')"
                      v-model="employee.flag_can_project_leader"
                      @update:model="onInput('flag_can_project_leader', $event.target.value)"
                      hide-details
                    />

                    <v-checkbox
                      :label="$t('employeeCanBeWorkOrderResponsible')"
                      v-model="employee.flag_can_work_order_responsible"
                      @update:model="onInput('flag_can_work_order_responsible', $event.target.value)"
                      hide-details
                    />

                  </v-card>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :key="`user`">
                  <v-row class="pt-4">
                    <v-col sm="6">
                      <v-card flat :border="true" class="pa-4">
                    <v-row  wrap class="vertical-center">
                      <v-col sm="5">
                        <v-btn @click="toggleShowCreateUser()" depressed v-if="showCreateUser" color="grey-darken-6">
                          {{ $t('userSelect') }}
                        </v-btn>
                        <div v-if="!showCreateUser">
                          <v-autocomplete
                            :loading="temporaryData.user.loading"
                            clearable
                            :items="onlyUnusedUsers(temporaryData.user.list, employee.user_id)"
                            :search-input.sync="temporaryData.user.search"
                            v-model="employee.user_id"
                            :label="$t('associatedUser')"
                            hint="Only available users will appear"
                            persistent-hint
                            item-value="id"
                            item-title="name"
                            variant="underlined"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                      <v-col  sm="2"  class="single-word">
                        {{ $t('or') }}
                      </v-col>
                      <v-col sm="5">
                        <v-btn @click="toggleShowCreateUser()" depressed v-if="!showCreateUser" color="grey-darken-6">
                          {{ $t('userCreate') }}
                        </v-btn>
                        <div>
                          <UserForm
                            :user="newUser"
                            :onInput="onUserInput"
                            :hideName="true"
                            :hideSubmit="true"
                            v-if="showCreateUser"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" >
              {{ buttonText }}
            </v-btn>
            <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { fetchUsers, createUser } from '@/api/users'
import Datepicker from '@/components/UI/Datepicker.vue'
import UserForm  from '@/components/Users/Form.vue'
import validationRules from '@/validations/employees'
import Say from '@/utils/Say'
import { onlyUnusedUsers } from "@/utils/filters"
import { watch, defineComponent, ref, computed, onBeforeMount } from 'vue'
import store from '@/store'
import emitArray from '@/utils/emit'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const emit = defineEmits(emitArray.value)
defineComponent({
  Datepicker,
  UserForm
})
const props = defineProps({
  employee: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  },
})

const activeTab = ref('user')
const newUser = ref({} as any)
const showCreateUser = ref(false)
const rules = validationRules
const temporaryData = ref({
  user: {
    search: '' as any,
    loading: false,
    list: [] as any[]
  }
})
const va_employee = computed({
  get () { return !props.employee.company },
  set (value) { props.onInput('company', value ? null : 'Company Name') }
})
const date_end_datepicker_show = computed({
  get () { return !!props.employee.date_end },
  set (value) { props.onInput('date_end', value ? '2018-01-01' : null) }
})

if(temporaryData.value.user.search.length !== 0) {
  watch(temporaryData.value.user.search, debounce( function(value: any){
    if (value && value.length !== 0) {
      getUsers(value)
    }
  }, 200))
}
onBeforeMount(() => {
  // Pre-fetch several pieces of data
  getUsers()
  newUser.value = Object.assign({}, store.getters.userCreate)
})
const getUsers = (value: any = null) => {
  temporaryData.value.user.loading = true
  fetchUsers({ search: value, rowsPerPage: -1 }).then((res) => {
    temporaryData.value.user.loading = false
    temporaryData.value.user.list = res.data.data
  }).catch((err) => {
    temporaryData.value.user.loading = false
    sayErrorResponse(err)
  })
}
const onUserInput = (key: any, value: any) => {
  newUser[key] = value
}
const toggleShowCreateUser = () => {
  showCreateUser.value = !showCreateUser.value
}
const determineUserId = () => {
  return new Promise((resolve, reject) => {
    if (showCreateUser.value && newUser.value.email.length !== 0) {
      newUser.value.name = props.employee.name
      // Try to create the user
      createUser(newUser.value).then((res) => {
        // Successful creation of user
        showCreateUser.value = false
        newUser.value.id = res.data.id
        temporaryData.value.user.list.push(newUser.value)
        resolve(res.data.id)
      }).catch((err) => {
        // Error out
        reject(err.response.data)
      })
    } else {
      resolve(props.employee.user_id)
    }
  })
}
const submitForm = () => {
  emit('update:loading', true)
  determineUserId().then((userId) => {
    props.onInput('user_id', userId)
    props.submit()
  }).catch((err) => {
    sayErrorResponse(err)
    emit('update:loading', false)
  })
}
</script>

<style lang="scss" scoped>
  .card {
    padding: 15px;
  }
  .vertical-center {
    align-items: center;
    .flex {
      text-align: center;
    }
  }
  .single-word {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }
</style>
