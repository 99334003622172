<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70"
      :width="4"></v-progress-circular>

    <div class="equipmentItemWrapper">

      <v-row wrap class="equipmentItemLocationsTable">
        <v-col sm="8">

          <router-link
            v-if="!hasJob"
            class="editEquipmentItem"
            :to="{ name: 'EquipmentItemsEdit', params: { id: equipmentItem.id }}">
            <v-btn color="primary" depressed size="small" class="mr-1">
              {{ t('edit') }}
            </v-btn>
          </router-link>

          <h2 v-if="!preLoading">{{ equipmentItem.equipment_parent ? equipmentItem.equipment_parent.name : equipmentItem.equipment.name }}</h2>

          <v-row v-if="!preLoading" wrap class="equipmentItemCard">
            <v-col sm="12" class="noPadding">
              <v-card flat>
                <table class="equipmentItemTable">
                  <tr>
                    <td>{{ $t('equipment') }}:</td>
                    <td>
                      <template v-if="equipmentItem.equipment_parent">
                        <img v-if="equipmentItem.equipment_parent.image_url" :src="equipmentItem.equipment_parent.image_url" class="equipment-img-detail" />
                        {{ equipmentItem.equipment_parent.name }}
                      </template>
                      <template v-else>
                        <img v-if="equipmentItem.image_url" :src="equipmentItem.image_url" class="equipment-img-detail" />
                        {{ equipmentItem.equipment.name }}
                      </template>
                    </td>
                  </tr>
                  <tr v-if="equipmentItem.equipment_parent">
                    <td>{{ $t('currentVariantInDatabase') }}:</td>
                    <td>
                      <template v-if="equipmentItem.equipment_parent">
                        <img v-if="equipmentItem.image_url" :src="equipmentItem.image_url" class="equipment-img-detail" />
                        {{ equipmentItem.equipment.name }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('uniqueFeature') }}:</td>
                    <td>{{ equipmentItem.unique_feature }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('status') }}:</td>
                    <td>{{ equipmentItem.status }} ({{ $t('since') }} {{ formatDate(equipmentItem.status_date) }})</td>
                  </tr>
                  <tr>
                    <td>{{ $t('rentedFrom') }}:</td>
                    <td>{{ equipmentItem.rental_supplier_name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('createdBySystem') }}:</td>
                    <td>{{ equipmentItem.system_created ? t('yes') : t('no') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('createdAt') }}:</td>
                    <td>{{ formatDateTime(equipmentItem.created_at) }}</td>
                  </tr>
                  <!-- <tr>
                    <td>{{ $t('currentLocation') }}:</td>
                    <td>{{ equipmentItem.location.location.location }}</td>
                  </tr> -->
                  <tr>
                    <td>{{ $t('createdBy') }}:</td>
                    <td>
                      <template v-if="equipmentItem.user_name !== 'SYSTEM'">
                        <router-link
                          :to="{ name: 'UsersEdit', params: { id: equipmentItem.user_id } }">
                          {{ equipmentItem.user_name }}
                        </router-link>
                      </template>
                      <template v-else>
                        {{ equipmentItem.user_name }}
                      </template>
                    </td>
                  </tr>
                </table>
              </v-card>
            </v-col>
          </v-row>

        </v-col>
        <v-col sm="4">

          <revisions-card
            :equipmentItem="equipmentItem"
            v-if="equipmentItem.revisions && !preLoading"
          />

        </v-col>
      </v-row>
    </div>


    <router-link
      v-if="!preLoading && !hasJob && equipmentItemStore.getters.equipmentItem.status !== 'USED'"
      class="editEquipmentItem"
      :to="{ name: 'EquipmentItemLocationCreate', query: { equipment_item_id: equipmentItemStore.getters.equipmentItem.id } }">
      <v-btn color="primary" depressed size="small" class="mr-1">
        {{ t('moveToOtherLocation') }}
      </v-btn>
    </router-link>

    <h2 v-if="!preLoading">{{ $t('locations') }}</h2>

    <v-row wrap class="equipmentItemLocationsTable">
      <v-col sm="12">
        <v-card flat border rounded>
          <v-card-text class="d-block px-0 py-0">
            <v-data-table-server
              v-if="equipmentItemLocationsList"
              :headers="headers"
              :items="equipmentItemLocationsList"
              :items-length="equipmentItemLocationsListTotalCount"
              :loading="equipmentItemLocationsListLoading"
              :search="search"
              :items-per-page-text="rowsPerPageText"
              :items-per-page-options="rowsPerPageItems"
              v-model:items-per-page="pagination.rowsPerPage"
              v-model:page="pagination.page"
              @update:options="getEquipmentItemLocations"
              @update:sort-by="sortEvent"
              id="equipment-item-locations-table"
              >
              <template v-slot:headers="{ isSorted, getSortIcon, toggleSort }">
                <tr>
                  <template v-for="header in headers" :key="header.key">
                    <th class="custom_header_table custom-bg" :style="{ width: `${header.width}px !important` }">
                      <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                        <span class="cursor-pointer">{{ capitalcase(header.title) }}</span>
                        <template v-if="isSorted(header)">
                          <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                        </template>
                      </span>
                    </th>
                  </template>
                </tr>
              </template>

              <template v-slot:item="{ item }: {item : any}">
                <tr>
                  <td>
                    <span v-if="!preLoading && equipmentItem.location.id === item.id && !item.is_consumable" class="badge">{{ $t('currentLocation') }}</span>
                    <span v-if="item.location.branch_id" class="badge badgeOrange">{{ $t('branch') }}</span>
                    <span v-if="item.location.rental_supplier_id" class="badge badgeBlue">{{ $t('rentalSupplierBrannch') }}</span>
                    {{ item.location.location }}
                  </td>
                  <td>
                    <img v-if="item.historical_equipment.image_url" :src="item.historical_equipment.image_url" class="equipment-img" />
                  </td>
                  <td>
                    {{ item.historical_equipment ? item.historical_equipment.name : '' }}
                  </td>
                  <td>
                    {{ item.composite_equipment ? item.composite_equipment.name : '' }}
                  </td>
                  <td>
                    <!-- show the name of each item in item.composite_equipment_item_locations, seperated by a comma -->
                    <template v-for="(compositeEquipementItemLocation, index) in item.composite_equipment_item_locations" :key="compositeEquipementItemLocation.id">
                      <router-link
                        :to="{ name: 'EquipmentItemView', params: { id: compositeEquipementItemLocation.equipment_item_id } }"
                        target="_blank">
                        {{ compositeEquipementItemLocation.historical_equipment.name }}
                      </router-link>
                      <span v-if="index < item.composite_equipment_item_locations.length - 1">, </span>
                    </template>
                  </td>
                  <td>
                    <template v-if="item.user_name !== 'SYSTEM'">
                      <router-link
                        :to="{ name: 'UsersEdit', params: { id: item.user_id } }">
                        {{ item.user_name }}
                      </router-link>
                    </template>
                    <template v-else>
                      {{ item.user_name }}
                    </template>
                  </td>
                  <td>
                    {{ formatDateTime(item.placed_at) }}
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'WorkOrdersView', params: { id: item.placed_by_work_order_id } }">
                      {{ item.placed_by_work_order }}
                    </router-link>
                  </td>
                  <td>
                    <template v-if="item.is_consumable && item.job_id">
                      {{ t('notApplicableAbbr') }}
                    </template>
                    <template v-else>
                      {{ formatDateTime(item.collected_at) }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.is_consumable && item.job_id">
                      {{ t('notApplicableAbbr') }}
                    </template>
                    <template v-else>
                      <router-link
                        :to="{ name: 'WorkOrdersView', params: { id: item.collected_by_work_order_id } }">
                        {{ item.collected_by_work_order }}
                      </router-link>
                    </template>
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'JobsView', params: { id: item.job_id } }">
                      {{ item.job_name }}
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table-server>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<style>
.v-table#equipment-item-locations-table th {
  white-space: nowrap !important;
}
.equipmentItemWrapper {
  width: 67%;
}
.equipmentItemCard {
  margin: 20px 0 30px 0;
}
.equipmentItemTable {
  width: 100%;
}
.equipmentItemTable td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.equipmentItemTable tr td:first-of-type {
  width: 180px;
}
.equipmentItemTable tr:last-child td {
  border-bottom: none;
}
.equipmentItemLocationsTable {
  margin-top: 5px;
}
.editEquipmentItem {
  float: right;
}
.noPadding {
  padding: 0;
}
.badge {
  background-color: #999;
  color: #fff;
  padding: 2px 5px;
  border-radius: 25px;
  text-wrap: nowrap;
}
.badgeOrange {
  background-color: #f57c00;
  color: #fff;
}
.badgeBlue {
  background-color: #1E88E5;
  color: #fff;
}
.equipment-img-detail {
  float: left;
  max-width: 30px;
  max-height: 20px;
  display: block;
  margin: -2px 10px 0 0;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { equipmentItemStore } from '@/store/equipmentItems'
import { onBeforeMount,defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { datatableOptions } from '@/mixins/datatableOptions'
import { equipmentItemLocationStore } from '@/store/equipmentItemLocations'
import { capitalcase } from '@/utils/filters'
import RevisionsCard from './cards/RevisionsCard.vue'

defineComponent({
  RevisionsCard
})

const { t } = useI18n()
const preLoading = ref(true)
const route = useRoute()

const formatDate = (dateString: string) => {
  if (!dateString) return ''
  dateString = dateString.toString()
  const parts = dateString.split('T')
  const date = parts[0]
  const dateParts = date.split('-')
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
}

const formatDateTime = (dateTimeString: string) => {
  if (! dateTimeString) return ''
  dateTimeString = dateTimeString.toString()
  let parts = []
  if (dateTimeString.includes('T')) {
    parts = dateTimeString.split('T')
  } else {
    parts = dateTimeString.split(' ')
  }
  const date = parts[0]
  const time = parts[1]
  const dateParts = date.split('-')
  const timeParts = time.split(':')
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`
}

const equipmentItem = computed(() => {
  return equipmentItemStore.getters.equipmentItem
})

const search = ref('')
const headers: any = ref([
  { title: t('location', 1), key: 'location', sortable: false },
  { title: t('', 1), key: 'image', sortable: false },
  { title: t('wasEquipmentAtLocation', 1), key: 'historical_equipment.name', sortable: false },
  { title: t('compositeAs', 1), key: 'composite_equipement.name', sortable: false },
  { title: t('compositeWith', 1), key: 'composite_equipment_item_locations', sortable: false },
  { title: t('createdBy', 1), key: 'userName', sortable: false },
  { title: t('placedAt', 1), key: 'placedAt', sortable: false },
  { title: t('placedByWorkOrder', 1), key: 'placedByWorkOrderId', sortable: false },
  { title: t('collectedAt', 1), key: 'collectedAt', sortable: false },
  { title: t('collectedByWorkOrder', 1), key: 'collectedByWorkOrderId', sortable: false },
  { title: t('job', 1), key: 'job', sortable: false },
])
const equipmentItemLocationsList = computed(() => equipmentItemLocationStore.getters.equipmentItemLocations)
const equipmentItemLocationsListLoading = computed(() => equipmentItemLocationStore.getters.equipmentItemLocationListLoading)
const equipmentItemLocationsListTotalCount = computed(() => equipmentItemLocationStore.getters.equipmentItemLocationListTotal)

const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions()

const getEquipmentItemLocations = () => {
  equipmentItemLocationStore.dispatch('fetchEquipmentItemLocations', Object.assign(
    pagination.value,
    {
      search: search.value,
      equipmentItemId: route.params.id,
    }
  ))
}

const hasJob = ref(false)

onBeforeMount(() => {
  equipmentItemStore.dispatch('fetchEquipmentItem', route.params.id).then(() => {
    const lastLocation = equipmentItemStore.getters.equipmentItem.location
    if (lastLocation.job_id) {
      hasJob.value = true
    }
    const equipment_name = equipmentItemStore.getters.equipmentItem.equipment_parent ? equipmentItemStore.getters.equipmentItem.equipment_parent.name : equipmentItemStore.getters.equipmentItem.equipment.name
    preLoading.value = false
    store.commit('app.setPageTitle', t('equipmentItemView') + ' | ' + equipmentItemStore.getters.equipmentItem.equipment.name)
    store.commit('app.setBreadcrumbs', [
      { text: t('inventory', 2), routeName: 'EquipmentItemsList' },
      { text: equipment_name }
    ])
  })
})

const sortEvent = (value: any) => {
  if (value.length === 0) {
    return
  }
  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}
</script>
@/store/equipmentItemLocations
