<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { getProForma, deleteProForma, finalizeProForma, getProFormaAsPdf, deleteProFormaLine, createProFormaLine, updateProFormaLine } from '@/api/proFormas';
import { ref, onBeforeMount, computed } from 'vue';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say';
import Datepicker from "@/components/UI/Datepicker.vue";
import branchStore from "@/store/branches";
import useBranchSelector from '@/composable/useBranchSelector'
const { branchSelected } = useBranchSelector()

// Temporary solution to show/hide financials
const showJobFinancials = computed(() => {
  return import.meta.env.VITE_APP_SHOW_JOB_FINANCIALS
})

const props = defineProps({
    proFormaId: {
        type: Number,
        required: true
    },
})

const {t} = useI18n()
const emit = defineEmits([
    'cancelEditingProForma',
    'deletedProForma',
    'finalizedProForma',
])

const proForma = ref([])

const proFormaLine = ref([])
const formSubmitted = ref(false)

const isFinalizeProFormaDialogVisible = ref(false)
const isDeleteProFormaDialogVisible = ref(false)

const isProFormaLineDialogVisible = ref(false)
const isDeleteProFormaLineDialogVisible = ref(false)

const headers: any = computed(() => {
    return [
        { title: t('internalArticleNumber'), key: 'internal_article_number', sortable:false },
        { title: t('description'), key: 'description', sortable:false },
        { title: t('dateOrPeriod'), key: 'date_or_period', sortable:false },
        { title: t('duration'), key: 'duration', sortable:false },
        { title: t('costCenter'), key: 'cost_center', sortable:false, },
        { title: t('vat'), key: 'vat_percentage', sortable:false, align: 'end' },
        { title: t('quantity'), key: 'quantity', sortable:false, align: 'end' },
        { title: t('pricePerUnitExclVat'), key: 'price_per_unit_excl_vat', sortable:false, align: 'end' },
        { title: t('totalBeforeDiscount'), key: 'total_before_discount_excl_vat', sortable:false, align: 'end' },
        { title: t('discountPercentage'), key: 'discount_percentage', sortable:false, align: 'end' },
        { title: t('discount'), key: 'discount_excl_vat', sortable:false, align: 'end' },
        { title: t('totalExclVat'), key: 'total_excl_vat', sortable:false, align: 'end' },
        { title: '', key: 'actions', sortable:false, width: '90px' },
    ]
})

onBeforeMount(async () => {
    loadProForma()
    await branchStore.dispatch('fetchBranches')
})

const loadProForma = async () => {
  try {
    const response = await getProForma(props.proFormaId);
    if(!response.data.data.id) {
      SayErrorResponse(response);
    } else {
        proForma.value = response.data.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const openProForma = async () => {
  const response = await getProFormaAsPdf(proForma.value.id);

  try {
    // Create a URL from the Blob (binary PDF data)
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  } catch (error) {
    SayErrorResponse(error);
  }
}

const formatDate = (date: string) => {
  if (!date) {return ''}
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}


const openFinalizeProFormaDialog = async (item: any) => {
    isFinalizeProFormaDialogVisible.value = true;
}
const closeFinalizeProFormaDialog = () => {
    isFinalizeProFormaDialogVisible.value = false;
}
const finalizeProFormaAction = async () => {
    if(!showJobFinancials.value || showJobFinancials.value == 'false') {
        Say('error', t('notAllowed'));
        return;
    }
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    try {
        await finalizeProForma(props.proFormaId);
        setTimeout(() => {
            Say('success', t('finalizedProFormaSuccesfully'));
            emit('finalizedProForma');
            formSubmitted.value = false
        }, 2000);
    } catch (error) {
        formSubmitted.value = false
        SayErrorResponse(error);
    }
}


const openDeleteProFormaDialog = async (item: any) => {
    isDeleteProFormaDialogVisible.value = true;
}
const closeDeleteProFormaDialog = () => {
    isDeleteProFormaDialogVisible.value = false;
}
const deleteProFormaAction = async () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    try {
        await deleteProForma(props.proFormaId);
        Say('success', t('deletedProFormaSuccesfully'));
        emit('deletedProForma');
        formSubmitted.value = false
    } catch (error) {
        formSubmitted.value = false
        SayErrorResponse(error);
    }
}


const openDeleteProFormaLineDialog = async (item: any) => {
    proFormaLine.value = item;
    isDeleteProFormaLineDialogVisible.value = true;
}
const closeDeleteProFormaLineDialog = () => {
    proFormaLine.value = [];
    isDeleteProFormaLineDialogVisible.value = false;
}
const deleteProFormaLineAction = () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    deleteProFormaLine(proForma.value.id, proFormaLine.value.id)
        .then((response: { data: { error: any; }; }) => {
            if (response.data.error) {
                SayErrorResponse(response);
            } else {
                closeDeleteProFormaLineDialog();
                loadProForma();
                Say('success', t('deletedProFormaLineSuccessfully'))
            }
        })
        .catch((error: any) => {
            SayErrorResponse(error);
        })
        .finally(() => {
            formSubmitted.value = false
        });
}


const openProFormaLineDialog = async (item: any) => {
    proFormaLine.value = item;
    startDateInputValidation.value = null
    endDateInputValidation.value = null

    formValues.value = {
        internal_article_number: item?.internal_article_number,
        cost_center: item?.cost_center,
        vat_percentage: item.quantity ? formatNumber(item?.vat_percentage, 2) : 0,
        description: item?.description,
        start_date: item?.start_date,
        end_date: item?.end_date,
        quantity: item.quantity ? formatNumber(item?.quantity, 2) : 0,
        discount_percentage: item.discount_percentage ? formatNumber(item?.discount_percentage, 2) : 0,
        duration: item?.duration,
        price_per_unit_excl_vat: item?.price_per_unit_excl_vat,
    }
    if (!item.id) {
        const preSelectedCostCenter = branchStore.getters.branches.filter(branch =>branch.id == branchSelected.value)
        if (preSelectedCostCenter[0]) {
            formValues.value.cost_center = preSelectedCostCenter[0]?.cost_center ?? null
        }
        formValues.value.vat_percentage = dropDownOptions.value.vat_percentage.filter(vat => vat.value == 21)[0].value
    } else {
        formValues.value.vat_percentage = dropDownOptions.value.vat_percentage.filter(vat => vat.value == formValues.value.vat_percentage)[0].value
    }
    isProFormaLineDialogVisible.value = true;
}
const closeProFormaLineDialog = () => {
    proFormaLine.value = [];
    isProFormaLineDialogVisible.value = false;
}
const createOrUpdateProFormaLine = () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true

    form.value.validate();

    if (!isValidDate(formValues.value.start_date) || formValues.value.start_date == 'Invalid date') {
        startDateInputValidation.value = true
    } else {
        startDateInputValidation.value = null
    }

    if (!isValidDate(formValues.value.end_date) || formValues.value.end_date == 'Invalid date') {
        endDateInputValidation.value = true
    } else {
        endDateInputValidation.value = null
    }

    if ((startDateInputValidation.value) || endDateInputValidation.value || !formValid.value) {
        formSubmitted.value = false
        return
    }

    // Check if end date is the same as, or after, start date
    if (new Date(formValues.value.end_date) < new Date(formValues.value.start_date)) {
        Say('error', t('endDateBeforeStartDate'));
        formSubmitted.value = false
        return
    }

    if (!proFormaLine.value.id) {
        createProFormaLine(proForma.value.id, formValues.value)
            .then((response: { data: { error: any; }; }) => {
                if (response.data.error) {
                    SayErrorResponse(response);
                } else {
                    closeProFormaLineDialog();
                    loadProForma();
                    Say('success', t('createdProFormaLineSuccessfully'))
                }
            })
            .catch((error: any) => {
                SayErrorResponse(error);
            })
            .finally(() => {
                formSubmitted.value = false
            });
    } else {
        updateProFormaLine(proForma.value.id, proFormaLine.value.id, formValues.value)
            .then((response: { data: { error: any; }; }) => {
                if (response.data.error) {
                    SayErrorResponse(response);
                } else {
                    closeProFormaLineDialog();
                    loadProForma();
                    Say('success', t('updatedProFormaLineSuccessfully'))
                }
            })
            .catch((error: any) => {
                SayErrorResponse(error);
            })
            .finally(() => {
                formSubmitted.value = false
            });
    }
}


const formValues = ref({
    internal_article_number: null,
    cost_center: null,
    vat_percentage: null,
    description: null,
    start_date: null,
    end_date: null,
    quantity: null,
    discount_percentage: null,
    duration: null,
    price_per_unit_excl_vat: null,
})

const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)

const startDateInputValidation = ref(null)
const endDateInputValidation = ref(null)

const costCentres = computed(() => {
    let returnValues = []
    for (const branch of branchStore.getters.branches) {
        returnValues.push({value:branch.cost_center, title: branch.cost_center, subtitle: branch.name})
    }
    return returnValues
})

const formRuleRequired = (value) => {
    if (value == null) return t('fieldRequired')
    if (value.length == 0) return t('fieldRequired')
    return true
}
const formRuleNotZero = (value) => {
    if (value == 0) return t('fieldRequired')
    return true
}

const formRules = ref({
    internal_article_number: [formRuleRequired],
    cost_center: [formRuleRequired],
    vat_percentage: [formRuleRequired],
    description: [formRuleRequired],
    quantity: [formRuleRequired, formRuleNotZero],
    price_per_unit_excl_vat: [formRuleRequired, formRuleNotZero],
    discount_percentage: [],
    duration: [],
    start_date: [formRuleRequired],
    end_date: [formRuleRequired],
})
const dropDownOptions = computed(() => {
  return {
    internal_article_number: [
      {value: 'sample-one', title: 'Sample One'},
    ],
    cost_center: costCentres.value,
    vat_percentage: [
      {value: '0', title: '0%'},
      {value: '9', title: '9%'},
      {value: '21', title: '21%'},
    ],
  }
})

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
</script>

<template>

    <div v-if="proForma.id" class="pro-forma-wrapper">
        <div v-if="!proForma.client_responsible_1 || !proForma.client_responsible_1.email">
            <span class="warning"><span class="mdi mdi-alert-circle"></span>
                {{ t('noClientResponsibleEmail') }}
                <router-link :to="{ name: 'JobsEdit', params: { id: proForma.job_id } }">{{ t('editJob') }}</router-link>
            </span>
        </div>

        <div class="pro-forma-wrapper-left">

            <table class="pro-forma-table">
                <tbody>
                    <tr>
                        <td colspan="2" class="label py-3">{{ proForma.number }} <span class="uppercase">({{ t('concept') }})</span></td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('period') }}:</td>
                        <td>{{ formatDate(proForma.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(proForma.end_date) }}</td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('addressedTo') }}:</td>
                        <td>
                            {{ proForma.addressee_line_one }}
                            <br v-if="proForma.addressee_line_two" />
                            {{ proForma.addressee_line_two }}
                            <br/>
                            {{ proForma.addressee_address }}
                            <br>
                            {{ proForma.addressee_postcode }} {{ proForma.addressee_city }}
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('emailAddress') }}:</td>
                        <td>
                            <template v-if="proForma.client_responsible_1 && proForma.client_responsible_1.email">
                                {{ proForma.client_responsible_1.email }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('jobDescription') }}:</td>
                        <td>
                            <template v-if="proForma.job_description">
                                {{ proForma.job_description }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('location') }}:</td>
                        <td>
                            <template v-if="proForma.location">
                                {{ proForma.location }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('yourReference') }}:</td>
                        <td>
                            <template v-if="proForma.client_reference">
                                {{ proForma.client_reference }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('totalExclVat') }}:</td>
                        <td>
                            &euro; {{ formatCurrency(proForma.total_excl_vat) }}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>


        <div class="pro-forma-wrapper-right pt-4">

            <span class="btn btn-blue" @click="emit('cancelEditingProForma')">{{ t('backToOverview') }}</span>
            <v-btn flat @click="openProForma()" class="ml-4 btn-border"><span class="mdi mdi-file-find-outline icon-in-btn"></span> {{ t('openPreview') }}</v-btn>
            <v-btn flat @click="openFinalizeProFormaDialog" class="btnOrange ml-4"
                :disabled="!showJobFinancials"
                >{{ t('finalizeProForma') }}</v-btn>
        </div>

        <div class="clearfix"></div>

        <v-btn flat @click="openProFormaLineDialog" class="float-right btnAdd btn-border">{{ t('createProFormaLine') }}</v-btn>

        <v-data-table-server
            id="pro-forma-lines-table"
            class="my-6"
            :headers="headers"
            :items="proForma.lines"
            :items-length="proForma.lines.length"
            :items-per-page="-1"
            :show-pagination="false"
            :hide-default-footer="true"
            >
            <template #[`item.internal_article_number`]="{item}">
                <div class="no-wrap">{{ item.internal_article_number ? item.internal_article_number : '-' }}</div>
            </template>
            <template #[`item.description`]="{item}">
                {{ item.description }}
            </template>
            <template #[`item.date_or_period`]="{item}">
                <div class="no-wrap">{{ item.start_date === item.end_date ? formatDate(item.start_date) : formatDate(item.start_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(item.end_date) }}</div>
            </template>
            <template #[`item.duration`]="{item}">
                <div class="no-wrap">{{ item.duration }}</div>
            </template>
            <template #[`item.cost_center`]="{item}">
                <div class="no-wrap">{{ item.cost_center ? item.cost_center : '-' }}</div>
            </template>
            <template #[`item.vat_percentage`]="{item}">
                <div class="no-wrap">{{ formatNumber(item.vat_percentage, 2) }}%</div>
            </template>
            <template #[`item.quantity`]="{item}">
                <div class="no-wrap">{{ formatNumber(item.quantity, 2) }}</div>
            </template>
            <template #[`item.price_per_unit_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.price_per_unit_excl_vat) }}</div>
            </template>
            <template #[`item.total_before_discount_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.total_before_discount_excl_vat) }}</div>
            </template>
            <template #[`item.discount_percentage`]="{item}">
                <div class="no-wrap">{{ item.discount_percentage > 0 ? formatNumber(item.discount_percentage, 2) + '%' : '-' }}</div>
            </template>
            <template #[`item.discount_excl_vat`]="{item}">
                <div class="no-wrap">{{ item.discount_percentage > 0 ? '&euro; -' + formatCurrency(item.discount_excl_vat) : '-' }}</div>
            </template>
            <template #[`item.total_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.total_excl_vat) }}</div>
            </template>
            <template #[`item.actions`]="{item}">
                <v-btn variant="text" @click="openProFormaLineDialog(item)" icon flat class="bg-transparent">
                    <v-icon color="grey">mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="openDeleteProFormaLineDialog(item)" icon flat class="bg-transparent">
                    <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table-server>

        <v-btn flat @click="openDeleteProFormaDialog" color="red" class="my-6 float-right">{{ t('deleteProForma') }}</v-btn>

    </div>


    <v-dialog
        v-model="isFinalizeProFormaDialogVisible"
        width="400"
        @afterLeave="closeFinalizeProFormaDialog"
        >
        <v-card
            prepend-icon="mdi-file-sign"
            :title="t('finalizeProForma')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <template v-if="!proForma.client_responsible_1 || !proForma.client_responsible_1.email">
                    <p>{{ t('noClientResponsibleEmail') }}</p>
                    <p class="mt-3">
                        <router-link :to="{ name: 'JobsEdit', params: { id: proForma.job_id } }">{{ t('editJob') }}</router-link>
                    </p>
                </template>
                <template v-else>
                    <p class="mb-3">{{ t('confirmFinalizeProForma') }}</p>
                    <p class="mb-3">{{ t('proFormaWillBeEmailedTo') }}:</p>
                    <p>{{ t('name') }}:</p>
                    <p class="mb-3"><b>
                        <template v-if="proForma.client_responsible_1.name">
                            {{ proForma.client_responsible_1.name }}
                        </template>
                        <template v-else>
                            &ndash;
                        </template>
                    </b></p>
                    <p>{{ t('email') }}:</p>
                    <p><b>{{ proForma.client_responsible_1.email }}</b></p>
                </template>
            </template>
            <template v-slot:actions>
                <v-btn
                    v-if="proForma.client_responsible_1 && proForma.client_responsible_1.email"
                    :text="t('finalize')"
                    :loading="formSubmitted"
                    @click="finalizeProFormaAction"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeFinalizeProFormaDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isDeleteProFormaDialogVisible"
        width="400"
        @afterLeave="closeDeleteProFormaDialog"
        >
        <v-card
            prepend-icon="mdi-delete"
            :title="t('deleteProForma')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <p>{{ t('confirmDeleteProForma') }}</p>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('remove')"
                    :loading="formSubmitted"
                    @click="deleteProFormaAction"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeDeleteProFormaDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isDeleteProFormaLineDialogVisible"
        width="400"
        @afterLeave="closeDeleteProFormaLineDialog"
        >
        <v-card
            prepend-icon="mdi-delete"
            :title="t('deleteProFormaLine')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <p>{{ t('confirmDeleteProFormaLine') }}</p>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('remove')"
                    :loading="formSubmitted"
                    @click="deleteProFormaLineAction"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeDeleteProFormaLineDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isProFormaLineDialogVisible"
        width="640"
        @afterLeave="closeProFormaLineDialog"
        >
        <v-card
            prepend-icon="mdi-pencil"
            :title="proFormaLine.id ? t('updateProFormaLine') : t('createProFormaLine')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <v-form
                    v-model="formValid"
                    ref="form"
                >
                <div class="input-group">
                <v-row>
                    <v-col md="6">
                        <div class="datepickerWrapper">
                            <div class="datepickerLabel">{{ t('fromDate') }}</div>
                            <Datepicker
                            v-model="formValues.start_date"
                            :placeholder="t('date')"
                            v-on:update:value-datetime="formValues.start_date = $event"
                            :teleport="true"
                            :min-date="proForma.start_date"
                            :max-date="proForma.end_date"
                            >
                            <template #below>
                                <div v-if="startDateInputValidation" class="text-dark-red">
                                {{ t('fieldRequired') }}
                                </div>
                            </template>
                            </Datepicker>
                        </div>
                    </v-col>
                    <v-col md="6">
                        <div class="datepickerWrapper">
                            <div class="datepickerLabel">{{ t('upToAndIncludingDate') }}</div>
                            <Datepicker
                            v-model="formValues.end_date"
                            :placeholder="t('date')"
                            v-on:update:value-datetime="formValues.end_date = $event"
                            :teleport="true"
                            :min-date="proForma.start_date"
                            :max-date="proForma.end_date"
                            >
                            <template #below>
                                <div v-if="endDateInputValidation" class="text-dark-red">
                                {{ t('fieldRequired') }}
                                </div>
                            </template>
                            </Datepicker>
                        </div>
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="8">
                    <v-text-field
                        v-model="formValues.description"
                        :rules="formRules.description"
                        :label="t('description')"
                        variant="underlined"
                        />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.duration"
                        :rules="formRules.duration"
                        :label="t('duration')"
                        variant="underlined"
                        />
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.quantity"
                        :rules="formRules.quantity"
                        :label="t('quantity')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.price_per_unit_excl_vat"
                        prepend-icon="mdi-currency-eur"
                        :rules="formRules.price_per_unit_excl_vat"
                        :label="t('pricePerUnitExclVat')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.discount_percentage"
                        prepend-icon="mdi-percent-outline"
                        :rules="formRules.discount_percentage"
                        :label="t('discountPercentage')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="4">
                    <v-select
                        v-model="formValues.internal_article_number"
                        :rules="formRules.internal_article_number"
                        :items="dropDownOptions.internal_article_number"
                        :label="t('internalArticleNumber')"
                        :item-props="true"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-select
                        v-model="formValues.cost_center"
                        :rules="formRules.cost_center"
                        :items="dropDownOptions.cost_center"
                        :label="t('costCenter')"
                        :item-props="true"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-select
                        v-model="formValues.vat_percentage"
                        :rules="formRules.vat_percentage"
                        :items="dropDownOptions.vat_percentage"
                        :label="t('vat')"
                        :item-props="true"
                        variant="underlined"
                    />
                    </v-col>
                </v-row>
                </div>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('save')"
                    :loading="formSubmitted"
                    @click="createOrUpdateProFormaLine"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeProFormaLineDialog"
                />
            </template>
        </v-card>
    </v-dialog>

</template>

<style scoped lang="scss">
.pro-forma-wrapper {
    min-width: 1100px;
}
.pro-forma-wrapper-left {
    width: 50%;
    float: left;
}
.pro-forma-wrapper-right {
    width: 50%;
    float: right;
    text-align: right;
}
.pro-forma-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 10.5px;
}

.pro-forma-table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pro-forma-table th, .pro-forma-table td {
    vertical-align: top;
}

.pro-forma-table th {
    padding: 12px 16px;
    text-align: left;
    background-color: #fff;
    font-weight: 500;
}

.pro-forma-table td {
    padding: 8px 16px;
}

.no-wrap {
    white-space: nowrap;
}

.label {
    font-weight: 500;
}

.clearfix {
    clear: both;
}

.btnOrange {
  background-color: #f57c00;
  color: #fff;
}

.btn {
  height: auto;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.btn-red {
  color: rgb(255, 0, 0);
}

.btn-blue {
  color: rgb(30, 136, 229);
}

.btn-icon {
    font-size: 16px;
    text-decoration: none;
}

.icon-in-btn {
    font-size: 16px;
    margin-left: -5px;
}

.btnAdd {
    margin-top: -20px;
}

.text-dark-red {
    color: rgb(176, 0, 32);
}

.btn-border {
    border: 1px solid #ddd;
}

.warning {
  display: inline-block;
  color: rgb(157, 131, 51);
  background: rgb(255, 255, 204);
  border: 0.5px solid rgb(194, 156, 39);
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.warning a {
  color: rgb(157, 131, 51);
}

.uppercase {
    text-transform: uppercase;
}
</style>