<template>
  <v-row wrap>
    <v-col sm="12" >
      <v-card flat>
        <v-card-title>
          <v-spacer/>
          <v-text-field
            append-icon="mdi-magnify"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-card-title>
      </v-card>
      <v-card flat border>
        <v-card-text class="px-0 py-0">
          <v-data-table-server
            :headers="headers"
            :items="usersList"
            :items-length="usersListTotalCount"
            :loading="usersListLoading"
            :search="search"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @upadte:options="getUsers"
            @update:sort-by="sortEvent"
          >

            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ header.title }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }: {item: any}">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'UsersEdit', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'UsersEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>{{ item.username }}</td>
                <td>{{ item.rolesList }}</td>
                <td class="text-right">
                  <v-btn variant="text" :to="{ name: 'UsersEdit', params: { id: item.id }}" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn variant="text" v-on:click="deleteUser(item.id)" icon flat >
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { datatableOptions } from '@/mixins/datatableOptions'
import Say from '@/utils/Say'

import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import useBranchSelector from '@/composable/useBranchSelector'
import { computedAsync } from '@vueuse/core'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { branchSelected } = useBranchSelector()
const { t } = useI18n()
const { pagination, rowsPerPageText, rowsPerPageItems } = datatableOptions()
const store = useStore()



const searchValue = ref('')
const search = computed({
  get() {
    return searchValue.value
  },
  set: debounce((value: string) => {
    searchValue.value = value
  }, 500)
})
const headers: any = ref([
  { title: 'Id', key: 'id', sortable: true, width: 65 },
  { title: t('name', 2), key: 'name', sortable: true, width: 300 },
  { title: t('username'), key: 'username', sortable: true, width: 100 },
  { title: t('role', 2), key: 'roles', sortable: false },
  { sortable: false,key: '', width: 100 }
])

const rolesList = computed(() => {
  return store.getters.rolesList
})
const usersList = computed(() => {
  return store.getters.usersList
})
const usersListTotalCount = computed(() => {
  return store.getters.usersListTotalCount
})
const usersListLoading = computed(() => {
  return store.getters.usersListLoading
})

onBeforeMount(() => {
  if (rolesList.value.length === 0) {
    store.dispatch('roles.fetch')
    store.dispatch('users.fetch', Object.assign(
      pagination.value,
      { search: searchValue.value }
    ))
  }

  // UI
  store.commit('app.setPageTitle', t('user', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('user', 2) }
  ])
})
const getUsers =  computedAsync(async () => {
  return await store.dispatch('users.fetch', Object.assign(
    pagination.value,
    { search: searchValue.value }
  ))
})
const deleteUser = (id: any) => {
  let confirmAction = confirm(t('userDeleteConfirm'))
  if (confirmAction) {
    store.dispatch('users.delete', id).then(() => {
      Say('success', t('userDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}
const sortEvent = (value: any) => {

  if(value.length === 0) {
    return
  }

  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false

  getUsers
}
</script>

<style scoped>
  table.table thead th {
    padding: 10px 24px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
</style>
