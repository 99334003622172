import Say from '@/utils/Say'

export const SayErrorResponse = (err: any) => {
  if(err) {
    if (err.response && err.response.data && err.response.data.errors && (Array.isArray(err.response.data.errors) || Object.keys(err.response.data.errors).length > 0)) {
      if (typeof err.response.data.errors === 'string') {
        Say('error', err.response.data)
      } else {
        for (let error in err.response.data.errors) {
          Say('error', err.response.data.errors[error])
        }
      }
  } else if (err.response && err.response.data && (Array.isArray(err.response.data) || Object.keys(err.response.data).length > 0)) {
      if (typeof err.response.data === 'string') {
        Say('error', err.response.data)
      } else {
        for (let field in err.response.data) {
          if(typeof err.response.data[field] === 'string') {
            Say('error', err.response.data[field])
          } else {
            Say('error', 'An error occurred')
          }
        }
      }
    } else if (err.response && err.response.data && err.response.data.message) {
      Say('error', err.response.data.message)
    } else if (err.message){
      Say('error', err.message)
    }  else if (err.error){
      Say('error', err.error)
    } else if (err.response) {
      Say('error', err.response)
    } else {
      if (err) {
        if (typeof err === 'string') {
          Say('error', err)
        } else {
          Say('error', 'An error occurred')
        }
      } else {
        Say('error', 'An error occurred')
      }
    }
  } else {
    Say('error', 'An error occurred')
  }

  if (window.location.href.indexOf('/login') === -1) {
    if (err.response && err.response.status && err.response.status === 401) {
      window.location.href = '/login'
    }
  }
}

export default SayErrorResponse
