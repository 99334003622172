<template>
  <v-card flat>
    <v-card-text>
      <v-row wrap>
        <v-col sm="12">
          <v-file-input multiple clearable flat variant="underlined" @change="onFileChange"/>
          <AttachedFilesList
            v-if="files.length"
            :files="files"
            :allow-edit="true"
            :show-description="true"
          />
          <div
            v-else
            class="no-files-uploaded"
          >
            <span class="mdi mdi-alert-circle-outline approve-icon approve-icon-warning"></span>
            {{ $t('noFilesUploaded' )}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showFileCommentModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h2>
            <a class="btn btn--flat btn--icon" color="blue-darken-1" target="_blank" rel="noopener">
              <v-icon>mdi-file-eye</v-icon>
            </a>
            {{ editFile.filename }}
          </h2>
        </v-card-title>
        <v-card-text>
          <!-- Description -->
          <v-text-field
            :label="$t('comment', 1)"
            v-model="editFileComments"
            textarea
            rows="2"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.stop="showFileCommentModal=false">{{ $t('cancel') }}</v-btn>
          <v-btn depressed color="orange-darken-2" @click.stop="saveFileComments()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script setup lang="ts">
import {uploadWorkOrderFiles, updateWorkOrderFileComments} from '@/api/workOrders'
import store from '@/store';
import Say from '@/utils/Say'
import emitArray from '@/utils/emit'
import {onMounted, ref, nextTick, computed, provide} from 'vue'
import {useI18n} from 'vue-i18n';
import {cloneDeep} from 'lodash-es';
import {onBeforeMount} from 'vue';
import sayErrorResponse from '@/mixins/sayErrorResponse';
import AttachedFilesList from "@/components/WorkOrders/cards/AttachedFilesList.vue";

const {t} = useI18n()
const emit = defineEmits(emitArray.value)
const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  }
})

const files = ref([] as any[])
const editFile = ref({} as any)
const editFileComments = ref(null)
const showFileCommentModal = ref(false)
const originalFiles = ref(null as any)

provide('editFile', editFile)
provide('editFileComments', editFileComments)
provide('showFileCommentModal', showFileCommentModal)

onBeforeMount(() => {
  originalFiles.value = cloneDeep(props.workOrder.files)
})
onMounted(() => {
  initializeInputs()
})

const initializeInputs = () => {
  files.value = originalFiles.value ? originalFiles.value : []
}

// When files are selected, attempt their upload
const onFileChange = (event: any) => {
  if (event.target.files.length !== 0) {
    var form = new FormData()
    if (props.workOrder) {
      form.append('work_order_id', props.workOrder.id)
    }
    for (let file of event.target.files) {
      // Check for allowed file types and sizes, don't send garbage to the server
      form.append('name[]', file.name)
      form.append('file[]', file)
    }
    uploadWorkOrderFiles(form).then((res) => {
      if (res.data.uploadedFiles.length !== 0) {
        let successMessage = 'Files ' + res.data.uploadedFiles.map((file: any) => {
          // Add the files in here too
          addFile(file)

          // Return the filename for further use
          return file.filename
        }).join(', ') + ' were uploaded.'
        Say('success', successMessage)
      }
      if (res.data.errors.length !== 0) {
        Say('error', res.data.errors.join(' '))
      }
    }).catch((err) => {
      if (err.code && err.code === 'ERR_NETWORK') {
        Say('error', 'There was a network error while trying to upload a file. The file might be too large, or the server might be too busy to handle an upload right now.')
      } else {
        sayErrorResponse(err)
      }
    })
  }
}
const addFile = (file: any) => {
  // $store.commit('workOrders.editAddFile', file)
  files.value.push(file)
  emit('change', {files: files})
}
const removeFile = async (id: any) => {
  if (!confirm(t('fileRemoveConfirm'))) {
    return false;
  }

  try {
    await store.dispatch('workOrders.deleteFile', id)
    files.value = files.value.filter(file => file.id !== id)
    Say('success', t('fileRemoveSuccess'))
  } catch (err: any) {
    sayErrorResponse(err)
  }
}
provide('removeFile', removeFile)
const saveFileComments = () => {
  const file = files.value.find((file: any) => file.id === editFile.value.id)
  nextTick(() => {
    updateWorkOrderFileComments(file.id, editFileComments.value).then(() => {
      file.comments = editFileComments.value
      editFile.value = {}
      editFileComments.value = null
      showFileCommentModal.value = false;
    })
  })
}

defineExpose({
  initializeInputs
});

</script>

<style scoped lang="scss">
.file-comments {
  color: #999;

  &:hover {
    cursor: pointer;

    .icon {
      display: inline;
    }
  }

  &.file-comments-add {
    font-style: italic;
  }

  .icon {
    font-size: 16px;
    display: none;
  }
}
.no-files-uploaded {
  padding-left: 35px;
  display: flex;
  align-items: center;
  .approve-icon {
    font-size: 24px!important;
    margin-right: 10px;
    color: orange;
  }
}
</style>
