<script setup lang="ts">
import {computed, onBeforeMount, provide, ref, watch, inject} from "vue";
import SayErrorResponse from '@/mixins/sayErrorResponse';
import {useI18n} from "vue-i18n";
import { fetchCheckoutPeriodsCostOverview } from '@/api/checkoutPeriods';

import CreateProforma from "@/components/Jobs/View/Invoices/CreateProforma";
import ApproveProforma from "@/components/Jobs/View/Invoices/ApproveProforma";
import RejectProforma from "@/components/Jobs/View/Invoices/RejectProforma";
import ConvertToSalesInvoice from "@/components/Jobs/View/Invoices/ConvertToSalesInvoice";
import CreditSalesInvoice from "@/components/Jobs/View/Invoices/CreditSalesInvoice";
import OverviewRowActions from "@/components/Jobs/View/Invoices/OverviewRowActions";
import CheckoutPeriodRow from "./Invoices/CheckoutPeriodRow.vue";
import EditProForma from "./Invoices/EditProForma.vue";

const {t} = useI18n()
const jobId = inject('jobId', 0)
const job = inject('job', {})
const costOverview = ref([])
const editProFormaId = ref(0)

provide('jobId', jobId)

onBeforeMount(async () => {
  loadCheckoutPeriodsCostOverview()
});

const loadCheckoutPeriodsCostOverview = async () => {
  try {
    const response = await fetchCheckoutPeriodsCostOverview(jobId.value);
    if(!response.data.generated_at) {
      SayErrorResponse(response.data.error);
    } else {
      costOverview.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const deletedProForma = () => {
  editProFormaId.value = 0
  loadCheckoutPeriodsCostOverview()
}

const finalizedProForma = () => {
  editProFormaId.value = 0
  loadCheckoutPeriodsCostOverview()
}

const formData = ref()

const dialogOpen = ref(false)
const dialogForm = ref(null)
const dialogFormModel = ref(null)
const checkoutPeriodSelected = ref(null)
const dialogTitle = ref(null)
const dialogLoading = ref(false)
provide('dialogTitle', dialogTitle)


watch(() => dialogForm.value, (newValue) => {
  dialogOpen.value = !!newValue
})

const formComponent = computed(() => {
  if (dialogForm.value == 'CreateProforma') {
    return CreateProforma;
  }
  if (dialogForm.value == 'ApproveProforma') {
    return ApproveProforma;
  }
  if (dialogForm.value == 'RejectProforma') {
    return RejectProforma;
  }
  if (dialogForm.value == 'ConvertToSalesInvoice') {
    return ConvertToSalesInvoice;
  }
  if (dialogForm.value == 'CreditSalesInvoice') {
    return CreditSalesInvoice;
  }
  return null
})

const dialogIcon = computed(() => {
  if (dialogForm.value == 'CreateProforma') {
    return 'mdi-file-plus-outline';
  }
  if (dialogForm.value == 'ApproveProforma') {
    return 'mdi-file-check-outline';
  }
  if (dialogForm.value == 'RejectProforma') {
    return 'mdi-file-remove-outline';
  }
  if (dialogForm.value == 'ConvertToSalesInvoice') {
    return 'mdi-file-check-outline';
  }
  return 'mdi-pencil';
})

const dialogBtnText = computed(() => {
  if (dialogForm.value == 'CreateProforma') {
    return t('create');
  }
  if (dialogForm.value == 'ApproveProforma') {
    return t('approve');
  }
  if (dialogForm.value == 'RejectProforma') {
    return t('reject');
  }
  if (dialogForm.value == 'ConvertToSalesInvoice') {
    return t('createSalesInvoice');
  }
  if (dialogForm.value == 'CreditSalesInvoice') {
    return t('creditSalesInvoice');
  }
  return t('save');
})

const triggerRowAction = ({action, invoice, checkoutPeriod}) => {
  checkoutPeriodSelected.value = null

  if (action === 'openApproveDialog') {
    dialogFormModel.value = invoice
    dialogForm.value = 'ApproveProforma'
  } else if (action === 'openRejectDialog') {
    dialogFormModel.value = invoice
    dialogForm.value = 'RejectProforma'
  } else if (action === 'openConvertToSalesInvoiceDialog') {
    dialogFormModel.value = invoice
    dialogForm.value = 'ConvertToSalesInvoice'
  } else if (action === 'openCreateDialog') {
    dialogFormModel.value = {
      dateFrom: checkoutPeriod.start_date,
      dateTo: checkoutPeriod.end_date
    }
    checkoutPeriodSelected.value = checkoutPeriod
    dialogForm.value = 'CreateProforma'
  } else if (action === 'openCreditSalesInvoiceView') {
    dialogFormModel.value = invoice
    dialogForm.value = 'CreditSalesInvoice'
  } else if (action === 'openEditView') {
    editProFormaId.value = invoice.id
  }else {
    throw Error(`Unknown Action: ${action}`)
  }
}

const dialogClosed = () => {
  dialogForm.value = null
  dialogFormModel.value = null
  dialogLoading.value = false
  loadCheckoutPeriodsCostOverview()
}

const formatDate = (date: string) => {
  if (!date) {return ''}
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}
provide('formatDate', formatDate)
</script>

<template>

  <v-row v-if="job.use_old_invoicing === true">
    <v-col>
      <p class="px-2 py-3"><i>{{ t('jobUsesOldInvoicingMethod') }}</i></p>
    </v-col>
  </v-row>

  <v-row v-else-if="editProFormaId === 0" class="mb-4">
    <v-col class="overflow-auto">
      <template v-if="job.id && costOverview.generated_at && (Object.keys(costOverview.equipment).length || Object.keys(costOverview.lettering).length || Object.keys(costOverview.hours).length || Object.keys(costOverview.additionalCosts).length)">
      <div v-if="job.client.name === null || job.client.postcode === null || job.client.city === null || job.client.address === null">
        <span class="warning"><span class="mdi mdi-alert-circle"></span>
          {{ t('clientHasIncompleteAddress') }}
          <router-link
            :to="{ name: 'ClientsEdit', params: { id: job.client.id } }"
          >
            {{ t('clickHereToCompleteTheAddress') }}
          </router-link>
        </span>
      </div>
      <div v-if="job.client.exact_online_account_id == null">
        <span class="warning"><span class="mdi mdi-alert-circle"></span>
          {{ t('clientIsNotLinkedToAnExactOnlineAccount') }}
          <router-link
            :to="{ name: 'ClientsEdit', params: { id: job.client.id } }"
          >
            {{ t('clickHereToLinkClientToAnExactOnlineAccount') }}
          </router-link>
        </span>
      </div>
      <table class="checkout-periods-table">
        <thead>
          <tr>
            <th class="custom_header_table custom-bg">{{ t('checkoutPeriod') }}</th>
            <!-- <th class="custom_header_table custom-bg">{{ t('type') }}</th> -->
            <th class="custom_header_table custom-bg">{{ t('number') }}</th>
            <th class="custom_header_table custom-bg"></th>
            <th class="custom_header_table custom-bg">{{ t('emailStatus') }}</th>
            <th class="custom_header_table custom-bg">{{ t('date') }}</th>
            <th class="custom_header_table custom-bg text-right">{{ t('invoiceTotalExcl') }}</th>
            <th class="custom_header_table custom-bg">{{ t('status') }}</th>
            <th class="custom_header_table custom-bg">{{ t('actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="checkoutPeriod in costOverview.checkout_periods" :key="checkoutPeriod.id">
            <template v-if="checkoutPeriod.id > 0">
              <!-- First row with checkout period date and first pro forma -->
              <tr>
                <td :rowspan="checkoutPeriod.invoices.length > 0 ? checkoutPeriod.invoices.length : 1" class="bg-white border-left">
                  {{ formatDate(checkoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(checkoutPeriod.end_date) }}
                </td>
                <template v-if="checkoutPeriod.invoices.length">
                  <CheckoutPeriodRow :invoice="checkoutPeriod.invoices[0]" @refreshInvoices="loadCheckoutPeriodsCostOverview" />
                  <td width="140px">
                    <OverviewRowActions :invoice="checkoutPeriod.invoices[0]" :checkout-period="checkoutPeriod" @trigger="triggerRowAction" :lastInvoiceIsRejectedProForma="checkoutPeriod.invoices.length === 1 && checkoutPeriod.invoices[0].status === 'REJECTED'" :lastInvoiceIsCreditNote="checkoutPeriod.invoices.length === 1 && checkoutPeriod.invoices[0].type === 'credit_note'" />
                  </td>
                </template>
                <template v-else>
                  <td colspan="6"></td>
                  <td>
                    <OverviewRowActions :invoice="{}" :checkout-period="checkoutPeriod" @trigger="triggerRowAction" />
                  </td>
                </template>
              </tr>
              <!-- Additional rows for the remaining invoices -->
              <tr v-for="invoice in checkoutPeriod.invoices.slice(1)" :key="invoice.id">
                  <CheckoutPeriodRow :invoice="invoice" @refreshInvoices="loadCheckoutPeriodsCostOverview" />
                  <td width="140px">
                    <OverviewRowActions :invoice="invoice" :checkout-period="checkoutPeriod" @trigger="triggerRowAction" :lastInvoiceIsRejectedProForma="checkoutPeriod.invoices.indexOf(invoice) === checkoutPeriod.invoices.length - 1 && invoice.status === 'REJECTED'" :lastInvoiceIsCreditNote="checkoutPeriod.invoices.indexOf(invoice) === checkoutPeriod.invoices.length - 1 && invoice.type === 'credit_note'" />
                  </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="border-left">{{ formatDate(checkoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(checkoutPeriod.end_date) }} &nbsp; ({{ t('concept') }})</td>
                <td colspan="7">
                  <template v-if="!checkoutPeriod.is_future">
                    <i>{{ t('conceptCheckoutPeriodInsctructions') }}</i>
                  </template>
                  <template v-else>
                    <i>{{ t('futureConceptCheckoutPeriodInsctructions') }}</i>
                  </template>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      </template>
      <div v-else-if="costOverview.generated_at">
        <p><i>{{ t('noDataAvailable') }}</i></p>
      </div>
      <div v-else>
        <p>
          <v-progress-circular indeterminate color="primary" :size="16" width="3" class="mr-2"></v-progress-circular>
          <i>{{ t('overviewIsBeingLoaded') }}</i>
        </p>
      </div>

    </v-col>
  </v-row>

  <template v-else>
    <EditProForma :proFormaId="editProFormaId" @cancelEditingProForma="editProFormaId = 0" @deletedProForma="deletedProForma()" @finalizedProForma="finalizedProForma()"/>
  </template>

  <v-dialog
    v-model="dialogOpen"
    width="600"
    id="invoicing-action-dialog"
    @update:model-value="dialogClosed()"
  >
    <v-card
      max-width="600"
      :prepend-icon="dialogIcon"
      :title="dialogTitle"
    >
      <template v-slot:text>
        <formComponent
          v-if="formComponent"
          ref="formData"
          :job="job"
          :invoice="dialogFormModel"
          :checkout-period="checkoutPeriodSelected"
          @created="dialogClosed()"
          @approved="dialogClosed()"
          @rejected="dialogClosed()"
          @converted-to-sales-invoice="dialogClosed()"
          @sales-invoice-credited="dialogClosed()"
          @cancel-form="dialogClosed()"
          @loading="dialogLoading = $event"
        />
      </template>
      <template v-slot:actions>
        <v-btn
          :text="dialogBtnText"
          @click="$refs.formData.submit()"
          type="submit"
          :loading="dialogLoading"
        />
        <v-btn
          :text="t('cancel')"
          @click="$refs.formData.cancel()"
          :disabled="dialogLoading"
        />
      </template>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-card,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-sheet,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > form > .v-card,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > form > .v-sheet {
  overflow: initial;
}

.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-card .v-card-item {
  background: var(--custom-bg) !important;
  margin-bottom: 15px;
}

.no-wrap {
  white-space: nowrap;
}

.checkout-periods-table {
  width: 100%;
  border-spacing: 0;
  background-color: #fff;
  font-size: 10.5px;
}

:deep(.checkout-periods-table tr) {
  transition-duration: 0.28s;
  transition-property: 'box-shadow', 'opacity', 'background', 'height';
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &.border-left {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    &:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }
  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }



}

.checkout-periods-table tr:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.checkout-periods-table th, .checkout-periods-table td {
  vertical-align: top;
  white-space: nowrap;
}

.checkout-periods-table th {
  padding: 12px 16px;
  text-align: left;
  background-color: #fff;
  font-weight: 500;
}

.checkout-periods-table td {
  padding: 10px 16px;
}

.bg-white {
  background-color: #fff;
}

.warning {
  display: inline-block;
  color: rgb(157, 131, 51);
  background: rgb(255, 255, 204);
  border: 0.5px solid rgb(194, 156, 39);
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.warning a {
  color: rgb(157, 131, 51);
}
</style>
