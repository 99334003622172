<template>
    <td>
        {{ invoice.number }}
    </td>
    <td>
      <template v-if="invoice.type === 'pro_forma'">
        <template v-if="invoice.status !== 'CONCEPT'">
          <span @click="openProFormaPdf(invoice)" class="pdf-link">{{ t('openPdf') }}</span>
          <span @click="downloadProFormaPdf(invoice)" class="pdf-link ml-3">{{ t('downloadPdf') }}</span>
        </template>
      </template>
      <template v-else-if="invoice.type === 'sales_invoice' || invoice.type === 'credit_note'">
          <span @click="openInvoicePdf(invoice, invoice.type)" class="pdf-link">{{ t('openPdf') }}</span>
          <span @click="downloadInvoicePdf(invoice, invoice.type)" class="pdf-link ml-3">{{ t('downloadPdf') }}</span>
      </template>
    </td>
    <td>
      <span :class="{
        'mr-1': true,
        'text-orange font-bold': invoice.email_status === 'PENDING',
        'text-red font-bold': invoice.email_status === 'FAILED' || invoice.email_status === 'PARTIALLY FAILED'
      }">
        {{ t('emailStatuses.' + invoice.email_status) }}
      </span>
      <EmailsDialog :invoice="invoice" @refreshInvoices="emit('refreshInvoices')"/>
    </td>
    <td>
      <template v-if="invoice.type === 'pro_forma'">
        {{ formatDate(invoice.date) }}
      </template>
      <template v-else-if="invoice.type === 'sales_invoice' || invoice.type === 'credit_note'">
        {{ formatDate(invoice.invoice_date) }}
      </template>
    </td>
    <td class="text-right">&euro; {{ formatCurrency(invoice.total_excl_vat) }}</td>
    <td class="uppercase">
      <template v-if="invoice.type === 'pro_forma'">
        {{ t('proFormaStatuses.' + invoice.status) }}
      </template>
      <template v-else-if="invoice.type === 'sales_invoice' || invoice.type === 'credit_note'">
        {{ invoice.status_description }}
      </template>
    </td>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { getProFormaAsPdf } from '@/api/proFormas';
import { getSalesInvoiceAsPdf } from '@/api/salesInvoices';
import { getCreditNoteAsPdf } from '@/api/creditNotes';
import { useI18n } from 'vue-i18n'
import SayErrorResponse from '@/mixins/sayErrorResponse';
import EmailsDialog from '@/components/UI/EmailsDialog.vue'

const {t} = useI18n()

const emit = defineEmits(['refreshInvoices'])

defineProps({
  invoice: {
    type: Object,
    required: true
  }
})

const formatDate = inject('formatDate')

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const openProFormaPdf = async (invoice: {
  number: any; id: any;
}) => {
  const response = await getProFormaAsPdf(invoice.id);

  try {
    // Create a URL from the Blob (binary PDF data)
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  } catch (error) {
    SayErrorResponse(error);
  }
}

const downloadProFormaPdf = async (invoice: {
  number: any; id: any;
}) => {
  try {
    const response = await getProFormaAsPdf(invoice.id);

    // Create a Blob from the binary PDF data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Generate a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = pdfUrl;

    // Set the desired file name for the download
    a.download = `${invoice.number}.pdf`;

    // Programmatically trigger a click on the anchor element to download the file
    a.click();

    // Revoke the URL to free up resources
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    SayErrorResponse(error);
  }
}

const openInvoicePdf = async (invoice: {
  number: any; id: any;
}, type) => {
  let response = null
  if(type === 'sales_invoice') {
    response = await getSalesInvoiceAsPdf(invoice.id);
  } else if(type === 'credit_note') {
    response = await getCreditNoteAsPdf(invoice.id);
  }

  try {
    // Create a URL from the Blob (binary PDF data)
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  } catch (error) {
    SayErrorResponse(error);
  }
}

const downloadInvoicePdf = async (invoice: {
  number: any; id: any;
}, type) => {
  try {
  let response = null
  if(type === 'sales_invoice') {
    response = await getSalesInvoiceAsPdf(invoice.id);
  } else if(type === 'credit_note') {
    response = await getCreditNoteAsPdf(invoice.id);
  }

    // Create a Blob from the binary PDF data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Generate a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = pdfUrl;

    // Set the desired file name for the download
    a.download = `${invoice.number}.pdf`;

    // Programmatically trigger a click on the anchor element to download the file
    a.click();

    // Revoke the URL to free up resources
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    SayErrorResponse(error);
  }
}
</script>

<style scoped>
.pdf-link {
  cursor: pointer;
  color: rgb(30, 136, 229);
  text-decoration: underline;
  white-space: nowrap;
}

.checkout-periods-table td {
  padding: 8px 16px;
}

.text-orange {
  color: rgb(245, 120, 40) !important;
}

.font-bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
td {
  white-space: nowrap;
}
</style>
