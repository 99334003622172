<!--

@todo
- check creating a client from the modal
- make sure cached clients is made dirty in every case (adding location, adding client) do this mainly from store

-->
<template>
  <v-card flat>
    <v-card-text>

      <!-- Branch -->
      <v-text-field
        v-model="currentVaLocation"
        :label="$t('branch')"
        variant="underlined"
        :value="(props.job && props.job.location_va) ? props.job.location_va.city : currentVaLocation.city"
        :readonly="true"
      ></v-text-field>

      <!-- Job description -->
      <v-text-field
        v-model="jobDescription"
        :label="$t('description')"
        clearable
        ref="jobDescriptionInput"
        variant="underlined"
        @change="$nextTick(() => $emit('change', { jobDescription }))"
      />

      <!-- Client -->
      <v-autocomplete
        v-model="clientId"
        :label="$t('client')"
        :loading="loadingClient"
        :items="clients"
        item-value="id"
        item-title="name"
        prepend-icon="mdi-plus"
        @click:prepend="() => { $emit('show-client-dialog') }"
        clearable
        variant="underlined"
        ref="nameInput"
      />

      <!-- Location to -->
      <div class="location-wrapper">
        <v-autocomplete
          v-model="locationToId"
          :items="locationToItems"
          :clearable="!isLocationReadonly"
          :disabled="isLocationReadonly || !client"
          :label="$t('location')"
          :loading="loadingClient"
          item-title="location"
          item-value="id"
          :prepend-icon="isLocationReadonly ? '' : 'mdi-plus'"
          variant="underlined"
          @click:prepend="() => { $emit('show-location-dialog') }"
          @change="$nextTick(() => $emit('change', { locationToId }))"
        />
        <MapsButton
          v-if="locationTo && !isLocationReadonly"
          :address="getMapsButtonUrl()"
          class="google-maps-button"
        />
      </div>

      <!-- Client reference -->
      <v-text-field
        v-model="clientRef"
        :label="$t('clientReference')"
        clearable
        ref="clientReferenceInput"
        @change="$nextTick(() => $emit('change', { clientRef }))"
        variant="underlined"
      />

      <v-checkbox
        v-model="useOldInvoicing"
        :label="$t('useOldInvoicingMethod')"
        @change="$nextTick(() => $emit('change', { useOldInvoicing }))"
      />

    </v-card-text>

  </v-card>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import { fetchClient } from '@/api/clients'
import Card from '@/components/UI/Card.vue'
import MapsButton from '@/components/UI/MapsButton.vue'
import { watch, computed, onMounted, onBeforeUnmount, defineComponent, ref } from 'vue'
import store from '@/store'
import emitArray from '@/utils/emit'
import useBranchSelector from '@/composable/useBranchSelector'
import { useEventBus } from '@vueuse/core'
import router from '@/router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { branchSelected } = useBranchSelector()

const createdBus = useEventBus('created');

const emit = defineEmits(emitArray.value)
defineComponent({
  Card,
  MapsButton
})

const props = defineProps({
  job: {
    type: Object,
    required: true
  }
})

const jobDescription= ref(null)
const clientId= ref(null as any)
const clientRef= ref(null)
const useOldInvoicing = ref(false)
const locationToId= ref(null)
const locationTo= ref()
const originalJob = ref(null as any)

// dialog and loading states
const showClientDialog= ref(false)
const showLocationDialog= ref(false)
const loadingClient= ref(false)
const isLocationReadonly= ref(false) // waarom deze?

const vaLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const currentVaLocation = computed(() => {
  return vaLocations.value.find((location: any) => location.branch_id === branchSelected.value)
})

// The list of clients in the store
const clients = computed(() => {
  const clients = store.getters['cache.clients']

  // If the client of the workOrder is not in the list of clients, then add it
  if(originalJob.value?.client && !clients.find((client: any) => client.id === originalJob.value.client.id)) {
    clients.push(originalJob.value.client)
  }

  return clients
})

// The store client that matches the set clientID
const client = computed(() => {
  // If no Client ID then there can be no client
  if(!clientId.value) return null

  // Filter from the clients in the store
  return clients.value.find((client: any) => client.id === clientId.value)
})

// Location Items that is available in the currently selected client
const locationToItems = computed(() => {
  return (client.value?.locations ?? []).filter((element: { id: null; location: string | string[]; latitude: any; longitude: any }) => {
    return !((element.location.includes('MISSING_LAT_LNG') || !element.latitude || !element.longitude) && (!originalJob.value.location || element.id != originalJob.value.location.id));
  })
})

// set client from id (which is set by the dropdown)
if(clientId) {
  watch(clientId, (newClientId) => {
    if (newClientId) {
      refreshClientFromServer()
    } else {
      locationToId.value = null
    }
    emit('change', { clientId: clientId.value})
  }, { deep: true })
}

// set locationTo from id (which is set by the dropdown), also used by MapsButton component
if(locationToId) {
  watch(locationToId, (location) => {
    if (location) {
      if(client.value.locations && client.value.locations.length > 0) {
        locationTo.value = cloneDeep(client.value.locations.find((location: any) => location.id === locationToId.value))
      }
    } else {
      locationTo.value = null
    }

    emit('change', {locationTo: locationTo.value})
  }, { deep: true })
}


createdBus.on(({ type, data }: any) => {
  // newClient is emitted  from Template . createClientLocal which in turn is called from Client . Form . submitFom
  // There is also a type === client which is emitted from Client . Form . submitFom,
  // but the new client data from there  do not have the client.id
  if (type === 'newClient') {
    clientId.value = data.id
  }
  if (type === 'location') {
    locationToId.value = data.id
  }
})

onBeforeUnmount(() => {
  createdBus.reset()
})


onMounted(async() => {
  showLocationDialog.value = false
  showClientDialog.value = false
  originalJob.value = cloneDeep(props.job)

  await initializeInputs()
})

const initializeInputs = async() => {
  // See if there is a clients list
  // If not the reload it
  if(clients.value.length === 0) {

    // set the clients list as dirty and the re-fetch
    store.commit('cache.clients.setDirty')
    await store.dispatch('cache.clients.fetch')

    // If still no clients then there is likely an issue in the API
    if(clients.value.length === 0) {

      alert(t('projectEditError'))
      if(originalJob.value?.id) {
        await router.push({ name: 'JobsView', params: { id: originalJob.value.id }})
      } else {
        await router.push({ name: 'JobsList'})
      }
      return;
    }
  }

  clientId.value = originalJob.value.client_id ? originalJob.value.client_id : null

  jobDescription.value = originalJob.value.job_description ? originalJob.value.job_description : null
  clientRef.value = originalJob.value.client_ref ? originalJob.value.client_ref : null
  useOldInvoicing.value = originalJob.value.use_old_invoicing ? originalJob.value.use_old_invoicing : false

  locationToId.value = null
  if (originalJob.value.location && Number.isInteger(originalJob.value.location)) {
    locationToId.value = originalJob.value.location
  } else if (originalJob.value.location && Number.isInteger(originalJob.value.location.id)) {
    locationToId.value = originalJob.value.location.id
  }
  locationTo.value = originalJob.value.location ? originalJob.value.location : null
}

// we want to re-fetch the client locations for this client
// and update the cache, since another user could have added
// a location since we cached the clients (we do this only once)
const refreshClientFromServer = async() => {
  if (!clientId.value) return

  const response = await fetchClient(clientId.value)

  response.data.locations = response.data.locations.sort((a: any, b: any) => {
    const locationA = a.location.toLowerCase()
    const locationB = b.location.toLowerCase()
    if (locationA < locationB) return -1
    if (locationA > locationB) return 1
    return 0
  })

  // if this is a new job, then set the locationTo to null
  if(!originalJob.value.id) {
    locationToId.value = null
  }

  store.commit('cache.clients.update', response.data)
}

const getMapsButtonUrl = ()  => {
  return locationTo.value.latitude && locationTo.value.longitude ? locationTo.value.latitude + ',' + locationTo.value.longitude : locationTo.value.location
}

defineExpose({
  initializeInputs
});
</script>
<style scoped>
.google-maps-button {
 margin-top: 18px !important;
}
</style>
