<template>
  <v-row class="entry">
    <template v-if="editedEntry">
      <v-col>
        <v-row class="flex-column ">
          <v-row class="align-center">
            <v-col cols="1" class="mr-1">
              <div class="label">{{ $t('start') }}:</div>
            </v-col>
            <v-col >
              <Datepicker
              id="date_start"
              :model-value="startDate"
              @update:value-datetime="startDate = $event, updateStartDateTime()"
              :max-date="endDate ? endDate : ''"
              @keyup.tab="focusNextInput('date_start')"
              :teleport="true"
            />
            </v-col>
            <v-col>
              <Timepicker
              id="time_start"
              :model-value="startTime"
              @update:value-datetime="startTime = $event, updateStartDateTime()"
              @keyup.tab="focusNextInput('time_start')"
              :teleport="true"
            />
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="1" class="mr-1">
              <div class="label">{{ $t('end') }}:</div>
            </v-col>
            <v-col>
              <Datepicker
                id="date_end"
                :model-value="endDate"
                @update:value-datetime="endDate = $event, updateEndDateTime()"
                :min-date="startDate ? startDate : ''"
                @keyup.tab="focusNextInput('date_end')"
                :teleport="true"
              />
            </v-col>
            <v-col>
              <Timepicker
                id="time_end"
                :model-value="endTime"
                @update:value-datetime="endTime = $event, updateEndDateTime()"
                :teleport="true"
              />
            </v-col>
          </v-row>

          <v-row class="align-baseline ">
            <v-col cols="1" class="mr-1">
              <div class="label">{{ $t('status') }}:</div>
            </v-col>
            <v-col cols="6">
              <!--<v-text-field class="ml-2" variant="underlined" v-model="editedEntry.activities"></v-text-field> -->
              <v-select variant="underlined" v-model="editedEntry.activities" hide-details :items="['Aangekomen', 'Vertrokken', 'Teruggekomen', 'Andere']"></v-select>

              <v-text-field v-show="editedEntry.activities === 'Andere'" placeholder="Handmatig" variant="underlined" v-model="activities"></v-text-field>

            </v-col>
          </v-row>
        </v-row>
      </v-col>

      <div class="actions ml-2">
        <v-icon class="ml-2 save" :class="{ disabled: incomplete }" @click="save">mdi-content-save</v-icon>

        <v-icon v-if="newEntry" class="ml-2 remove" @click="remove">mdi-delete</v-icon>
        <v-icon v-else class="ml-2 cancel" @click="cancel">mdi-undo</v-icon>
      </div>
    </template>

    <template v-else>
      <v-col class="entry-info">
        <p>
          <span class="date">
            {{ dayMonthFromDateTime(entry.datetime_start) }}
          </span>
          <span class="hours">
            {{ hourMinutesFromDateTime(entry.datetime_start) }} -
            <span v-if="entry.datetime_end">
              {{ hourMinutesFromDateTime(entry.datetime_end) }}
            </span>
            <span v-else>{{ $t('now') }}</span>
          </span>
          <br/>
          <span class="activity">
            {{ entry.activities ||t('workOrderStatusesOngoing.' + entry.work_order_ongoing_status) }}
          </span>
        </p>
      </v-col>

      <div class="time-amount">
        <p v-if="entry.datetime_end">
          <span class="red--text" v-if="entry.deleted_at">{{ $t('deleted') }}</span>
          <span class="amount" v-else>{{ differenceFromDateTime(entry.datetime_end ,entry.datetime_start) }}</span>
          <br/>
          <span class="entry-type">{{ entryTypes(entry) }}</span>
        </p>
      </div>

      <div class="actions">
        <v-icon v-if="editable" class="ml-2 edit" @click="edit">mdi-pencil</v-icon>
        <v-icon v-if="editable" class="ml-2 remove" @click="removeConfirmation = true">mdi-delete</v-icon>
      </div>
    </template>

    <v-dialog v-model="removeConfirmation" max-width="500px">
      <v-card>
        <v-card-title>
          <div class="headline">{{ $t('confirmDeleteItem') }}</div>
        </v-card-title>

        <v-card-text>
          <div class="red--text">{{ $t('cantUndo') }}</div>
        </v-card-text>

        <v-card-actions>
          <v-row class="justify-end">
            <v-btn color="primary" flat @click.stop="remove">{{ $t('yes') }}</v-btn>
            <v-btn color="primary" flat @click.stop="removeConfirmation = false">{{ $t('no') }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import Datepicker from '../UI/Datepicker.vue'
import Timepicker from '../UI/Timepicker.vue'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import emitArray from '@/utils/emit';
import { useI18n } from 'vue-i18n';
import { hourMinutesFromDateTime, dayMonthFromDateTime, differenceFromDateTime } from '@/utils/filters';
import { focusNextInput } from '@/utils/functions';

const {t } = useI18n()

const emit = defineEmits(emitArray.value)
defineComponent({
  Datepicker,
  Timepicker
})

const props = defineProps( {
  entry: {
    type: Object,
    required: true
  },
  editable: {
    type: Boolean,
    required: false,
    default: () => true
  },
})

const editedEntry = ref(null as any)
const startDate = ref(null as any)
const startTime = ref(null as any)
const endDate = ref(null)
const endTime = ref(null)
const removeConfirmation = ref(false)
const activities = ref(null as any)

const newEntry = computed(() => {
  return !props.entry.datetime_start && !props.entry.datetime_end && !props.entry.activities
})

const incomplete = computed(() => {
  return !!editedEntry.value && (!editedEntry.value.datetime_start || !editedEntry.value.datetime_end || !editedEntry.value.activities)
})


onBeforeMount(() => {
  if (newEntry.value) edit()
})

const entryTypes = (entry: any) => {
  const types = []
  // const statusesOngoing = Object.keys(t('workOrderStatusesOngoing'))
  const statusesOngoing = ['']

  if (!statusesOngoing.includes(entry.work_order_ongoing_status)) {
    types.push('handmatig')
  } else {
    types.push('automatisch')
  }

  if (entry.updated_at != null) {
    types.push('aangepast')
  }

  return types.join(', ')
}

const edit = () => {
  editedEntry.value = cloneDeep(props.entry)

  const [startDateLocal, startTimeLocal] = editedEntry.value.datetime_start.split(' ')

  if (startDateLocal && startTimeLocal) {
    startDate.value = startDateLocal

    // VueDatePicker need this split into hours, minutes, seconds
    const startTimeLocalSplit = startTimeLocal.split(':')
    startTime.value = {
      hours: startTimeLocalSplit[0],
      minutes: startTimeLocalSplit[1],
      seconds: startTimeLocalSplit[2],
    }
  }

  const [endDateLocal, endTimeLocal] = editedEntry.value.datetime_end.split(' ')
  if (endDateLocal && endTimeLocal) {
    endDate.value = endDateLocal

    // VueDatePicker need this split into hours, minutes, seconds
    const endTimeLocalSplit = endTimeLocal.split(':')
    endTime.value = {
      hours: endTimeLocalSplit[0],
      minutes: endTimeLocalSplit[1],
      seconds: endTimeLocalSplit[2],
    }
  }

  if (!editedEntry.value.activities) {
    editedEntry.value.activities = editedEntry.value.work_order_ongoing_status
  }
}

const remove = () => {
  emit('remove')
}

const save = () => {
  if (incomplete.value) return

  if (activities.value) {
    editedEntry.value.activities = activities.value
  }

  emit('update', editedEntry.value)

  editedEntry.value = null
}

const cancel = () => {
  editedEntry.value = null
}

const updateStartDateTime = () => {
  if (startDate.value && startTime.value) {
    editedEntry.value.datetime_start = `${startDate.value} ${startTime.value}:00`
  } else {
    editedEntry.value.datetime_start = null
  }
}

const updateEndDateTime = () => {
  if (endDate.value && endTime.value) {
    editedEntry.value.datetime_end = `${endDate.value} ${endTime.value}:00`
  } else {
    editedEntry.value.datetime_end = null
  }
}
</script>

<style lang="scss" scoped>
.entry {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 10px;

  &:not(:first-of-type) {
    border-top: none;
  }

  &.ongoing {
    opacity: .5;
  }

  p {
    margin-bottom: 0;
  }

  .hours {
    margin-left: 20px;
  }

  .entry-info, .time-amount, .edit-icon {
    padding: 10px;
  }

  .time-amount {
    text-align: right;

    .amount {
      font-weight: bold;
    }

    .entry-type {
      font-size: 12px;
      color: #999;
    }
  }
}

.label {
  width: 46px;
  flex: none;
}

.entry .input-group {
  padding: 0;
  max-width: 165px;

  > .input-group__details {
    min-height: 0;
  }
}

.actions {
  font-size: 0.8em;
  display: flex;
  align-items: center;

  .icon, .v-icon {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: lightgrey;
    }

    &:hover:not(.disabled) {
      &.edit {
        color: blue;
      }

      &.remove {
        color: red;
      }

      &.save {
        color: green;
      }

      &.cancel {
        color: blue;
      }
    }
  }
}
</style>
