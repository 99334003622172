<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {useI18n} from "vue-i18n";

// The string  translator
const { t } = useI18n()

const props = defineProps({
  previewFile: {
    default: null
  }
})

const emit = defineEmits(["close"]);

// Injected properties
const file = computed(() => {
  return props.previewFile;
})
const dialog = ref(true);

// computed properties
const dialogTitle = computed(() => {
  if (file.value?.name) {
    return `${file.value.name}${file.value.extension ? '.'+file.value.extension: ''}`
  }
  if (file.value?.filename) {
    return file.value.filename
  }
  return "Unknown Title"
})

const closeDialog = () => {
  emit('close')
}

</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-height="80vh"
      max-width="80vw"
      width="1024"
      height="800"
      @close="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card
        max-height="80vh"
        max-width="80vw"
        width="1024"
        height="800"
        prepend-icon="mdi-eye"
        text="Your application will relaunch automatically after the update is complete."
        :title="dialogTitle"
      >
        <template v-slot:text>
          <div class="image-container">
            <img
              :src="file.data ?? file.url"
              :alt="dialogTitle"
              class="image"
            />

          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
            text="Close"
            @click="emit('close')"
          />
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.image-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  .image {
    max-width: 100%;
    height:auto;
  }
}
</style>
