import { transform, isEqual, isObject } from 'lodash-es'
import moment from 'moment'
import {readFile} from "@/api/filesStorage";
import {isFileServingAllowed} from "vite";
import {Exception} from "sass";

/**
 * Appends seconds to datetime coming from datepicker
 * @param String date date in iso format
 * @param String time in hour:minutes format
 */
export function dateAndTimeToDatetime (date: String, time: String) {
  if (date && time) {
    return date + ' ' + time + ':00'
  } else {
    return null
  }
}

/**
 * Converts a regular iso datetime string to an object with the date and time properties
 * as they are consumed by the Vuetify components (YYYY-MM-DD for date, HH:mm for time - no seconds)
 * @param String datetime datetime in iso format
 * @return { date: YYYY-MM-DD, time: HH:mm }
 */
export function datetimeSplit (datetime: any) {
  if (datetime != null && datetime.length === 19 && moment(datetime).isValid()) {
    datetime = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
    return {
      date: datetime.format('YYYY-MM-DD'),
      time: datetime.format('HH:mm')
    }
  } else {
    return {
      date: null,
      time: null
    }
  }
}

/**
 * This takes a datetime from API in the format of YYYY-MM-DDTHH:mm:ss.000000Z
 * and then return it split into a date string and a time string and also the YY, MM, DD, HH, mm, ss parts
 * * THIS ignores any timezone modifications and return data 100% as it came from the API
 * @param datetime
 * @param splitChar
 */
export function dateTimeStringSplit (datetime: any, splitChar: string = 'T') {

  if(datetime.indexOf(splitChar) === -1) {
    throw new Error(`Usage of incorrect dateTime string split character, tried with ${splitChar}`);
  }

  // Get before the T
  let dateString = datetime.split(splitChar)[0];

  // Get after the T
  let timeString = datetime.split(splitChar)[1];

  // Remove the . part at the end
  timeString = timeString.split('.')[0]

  return {
    date: dateString,
    dateParts: {
      year: parseInt(dateString.split('-')[0]),
      month: parseInt(dateString.split('-')[1]),
      day: parseInt(dateString.split('-')[2]),
    },
    time: timeString,
    timeParts: {
      hours: parseInt(timeString.split(':')[0]),
      minutes: parseInt(timeString.split(':')[1]),
      seconds: parseInt(timeString.split(':')[2]),
    }
  }
}

/**
 * Deep diff between two object, using lodash-es
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference (object: any, base: any) {
  function changes (object: any, base: any) {
    return transform(object, function (result: any, value: any, key: any) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

/**
 * Returns an array with the translation keys for the days of the week
 */
export function getDaysOfWeek () {
  return [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ]
}

/**
 * Exports an array with week numbers as consumed by v-select
 */
export function getWeekNumberSelectOptions () {
  let items = []
  for (let i = 1; i <= 52; i++) {
    items.push({ title: i, value: i })
  }
  return items
}

/**
 * Exports an array with years as consumed by v-select
 */
export function getYearSelectOptions () {
  let items = []
  let currentYear = moment().year()
  for (let i = currentYear - 2; i <= currentYear; i++) {
    items.push({ title: i, value: i })
  }
  return items
}

/**
 * Turns an object (usually a state) to formdata, so that it's easier
 * to submit forms with flies on the fly
 * Also ommitting null values, as per axios's default behavior
 */
export function inputsToFormData (inputs: any) {
  let formData = new FormData()
  for (let key in inputs) {
    if (inputs[key] !== null) {
      formData.append(key, inputs[key])
    }
  }
  return formData
}

/**
 * Download a file from the server
 * @param file
 */
export function streamDownload(file) {
  // The request Object
  let fileRequest = {};

  if (file.hash) {
    fileRequest.hash = file.hash
  } else if (file.url) {
    fileRequest.filePath = new URL(file.url)?.pathname
  }

  // Do the API Call
  readFile(fileRequest).then(response => {

    // Read the mime_type and file name
    // The mime type received from the server can be a fallback
    const type = file.mime_type ?? response.data.type;
    const name = file.name ?? 'Unknown File';

    // create the file Blob
    const blob = new Blob([response.data], {
      type: type,
      name: name,
    })

    // Attach the link element and call the download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = name
    link.click()
    URL.revokeObjectURL(link.href)
  }).catch(console.error)
}

export function loadImageData(file, callback) {
  // The request Object
  let fileRequest = {};

  if (file.hash) {
    fileRequest.hash = file.hash
  } else if (file.url) {
    fileRequest.filePath = new URL(file.url)?.pathname
  }

  // Do the API Call
  readFile(fileRequest).then(response => {

    // Read the mime_type and file name
    // The mime type received from the server can be a fallback
    const type = file.mime_type ?? response.data.type;
    const name = file.name ?? 'Unknown File';

    // create the file Blob
    const blob = new Blob([response.data], {
      type: type,
      name: name,
    })

    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
    //  file.data = reader.result;
      if (callback) {
        callback(reader.result)
      }
    }
  }).catch(console.error)
}


/**
 * Export all
 */
export default {
  dateAndTimeToDatetime,
  datetimeSplit,
  difference,
  getDaysOfWeek,
  getWeekNumberSelectOptions,
  getYearSelectOptions,
  inputsToFormData
}
