<template>
  <v-card flat   id="suggested-equipment-card" class="mb-12">
    <v-card-text class="pt-0">
      <v-row class="custom-bg mt-0 mb-0 rounded-t">
        <v-col cols="1"></v-col>
        <v-col><strong>{{ ucfirst(t('equipmentSuggested')) }}</strong></v-col>
        <v-col cols="1"><strong>{{ ucfirst(t('quantity')) }}</strong></v-col>
        <v-col cols="2"><strong>{{ ucfirst(t('rentalSupplier')) }}</strong></v-col>
        <v-col cols="3"><strong>{{ ucfirst(t('notes')) }}</strong></v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <template  v-if="!disabled">
        <v-row
          class="mt-0 pl-2 mb-0 item-row align-center border pb-4"
          :class="{
            'border-b-0' : suggestedEquipment.length,
            'pb-4' : suggestedEquipment.length,
            'rounded-b': !suggestedEquipment.length
          }"
        >
          <v-col class="b-b" cols="1"><v-icon color="success">mdi-plus</v-icon></v-col>
          <v-col class="b-b">
            <v-autocomplete
              hide-details
              variant="plain"
              :items="allEquipment"
              item-title="name"
              return-object
              v-model="newEquipment"
              density="compact"
              class="pa-0 ma-0"
            ></v-autocomplete>
          </v-col>
          <v-col class="b-b"  cols="1"><v-text-field flat density="compact" hide-details variant="plain" v-model="newAmount" type="number" @keydown.enter="addEquipment"/></v-col>
          <v-col class="b-b"  cols="2">
            <v-autocomplete
              v-model="newRentalSupplierId"
              :items="suppliedByOptions"
              id="form-supplier_id"
              item-title="name"
              item-value="id"
              hide-details
              variant="plain"
              density="compact"
            />
          </v-col>
          <v-col class="b-b"  cols="3"><v-text-field density="compact" flat hide-details variant="plain" v-model="newNote" @keydown.enter="addEquipment"/></v-col>
          <v-col cols="1"><v-btn size="small" flat color="secondary" @click="addEquipment">{{ ucfirst(t('add')) }}</v-btn></v-col>
        </v-row>
      </template>

      <!-- header -->

      <v-row
        v-for="(item, index) in suggestedEquipment"
        :key="item.equipment_id"
        class="mt-0 mb-0 pl-2 item-row border border-t-0"
        :class="{
          'border-b-0' : index < suggestedEquipment.length -1,
          'pb-4' : index === suggestedEquipment.length -1,
          'rounded-b': index === suggestedEquipment.length -1
        }"
      >
        <v-col class="b-b eq-icon-container" cols="1">
          <span :style="{'background-image': `url(${getImageUrl(item.equipment.image_href)})`}"></span>
        </v-col>
        <v-col class="b-b suggestedItemRow">
          {{ item.equipment.name }}
          {{index}} - {{suggestedEquipment.length}}
        </v-col>
        <v-col class="b-b suggestedItemRow" cols="1">
          <v-text-field flat density="compact" hide-details variant="plain" v-model="item.quantity" type="number" :disabled="disabled" />
        </v-col>
        <v-col class="b-b suggestedItemRow" cols="2">
          {{ getRentalSupplierName(item.rental_supplier_id) }}
        </v-col>
        <v-col class="b-b suggestedItemRow" cols="3">
          <v-text-field density="compact" flat hide-details variant="plain" v-model="item.note" :disabled="disabled" />
        </v-col>
        <v-col class="suggestedItemRow" cols="1">
          <v-btn size="small" flat color="error" @click="removeEquipment(item)" v-show="!disabled"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
#suggested-equipment-card {
  .v-card-text {
    .v-row {
      gap: 10px;

      &.item-row {
        .v-col {
          height:60px;
          line-height:60px;
        }
      }

      .eq-icon-container {
        position: relative;

        span {
          background-size: contain;
          background-position: center;
          position: absolute;
          top:20px;
          left:20px;
          right:20px;
          bottom:20px;
        }
      }
    }
    .v-field__input {
      padding-top: 0!important;
    }
  }
}
</style>
<style lang="scss">
.suggestedItemRow {
  line-height: 16px !important;
  display: flex;
  align-items: center;
  .v-field__input {
    padding-top: 0 !important;
  }
}
</style>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import { fetchEquipment } from '@/api/equipment'
import { saveWorkOrderEquipment } from '@/api/workOrders'

import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n'
import emitArray from '@/utils/emit';
import { fromStorage, ucfirst } from '@/utils/filters';
import sayErrorResponse from '@/mixins/sayErrorResponse';
import store from '@/store'

const emit = defineEmits(emitArray.value)
const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  }
})
const allEquipment = ref([]) // for dropdown
const newEquipment = ref(null as any) // for adding equipment from the dropdown
const newAmount = ref(1) // for adding equipment from the dropdown
const newRentalSupplierId = ref(null) // for adding equipment from the dropdown
const newNote = ref('')
const suggestedEquipment = ref([] as any[]) // current state of suggestedEquipment
const isSaving: any = ref(false)

const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])

/**
 * This is the list of people that can supply equipment,
 * VA is always part of the list and at the top
 */
const suppliedByOptions = computed(() => {
  let options = [{id: null, name: ''}]
  for (const rentalSupplier of rentalSuppliers.value) {
    options.push({
      id: rentalSupplier.id,
      name: `${rentalSupplier.name} | ${ rentalSupplier.location.city}`,
    })
  }
  return options
})

const getRentalSupplierName = (rental_supplier_id) => {
  if(!rental_supplier_id) return ''
  return suppliedByOptions.value.filter((supplier) => supplier.id === rental_supplier_id )[0]?.name ?? ''
}

const { t } = useI18n()

const disabled = computed(() =>  {
  return props.workOrder.status !== 'PLANNED'
})

watch(suggestedEquipment, () => {
  if(suggestedEquipment) {
    emit('change', { equipment: suggestedEquipment.value })
  }
}, {deep: true })

onMounted(() => {
  initializeInputs()
  fetchEquipmentLocal()
  store.dispatch('rentalSuppliers.fetch');
})

const initializeInputs = () => {
  if (!props.workOrder.id) return
  suggestedEquipment.value = props.workOrder.suggestedEquipment ? cloneDeep(props.workOrder.suggestedEquipment) : []
}
const fetchEquipmentLocal = async() => {
  const response: Record<string, any> = await fetchEquipment(props.workOrder.suggestedEquipment)
  allEquipment.value = response.data.data
  emit('equipment:loaded', response.data.data)
}
const addEquipment = () => {
  if (!newEquipment.value) return

  let exists = false

  // Foreach equipment, check if it already exists in the suggestedEquipment array
  suggestedEquipment.value.forEach((equip: any) => {
    if (
      equip.equipment_id === newEquipment.value.id &&
      equip.rental_supplier_id === newRentalSupplierId.value &&
      equip.note === newNote.value
    ) {
      equip.quantity = (parseInt(equip.quantity) + parseInt(newAmount.value)) || 1
      equip.note = newNote.value
      equip.rental_supplier_id = newRentalSupplierId.value

      exists = true
      return
    }
  })

  if (!exists) {
    // If the equipment does not exist in the suggestedEquipment array, add it
    suggestedEquipment.value.push({
        equipment_id: newEquipment.value.id,
        quantity: parseInt(newAmount.value) || 1,
        note: newNote.value,
        rental_supplier_id: newRentalSupplierId.value,
        work_order_id: props.workOrder.id,
        equipment: newEquipment.value
    })
  }

  nextTick(() => {
    newAmount.value = 1
    emit('change', { equipment: suggestedEquipment.value })
  })
}
watch(() => newEquipment.value, () => {
  newNote.value = newEquipment.value?.note ?? ''
})
const removeEquipment = (item: any) => {
  // remove the equipment from the suggestedEquipment array, based on equipment_id, rental_supplier_id and note
  suggestedEquipment.value = suggestedEquipment.value.filter((equip: any) => {
    return !(equip.equipment_id === item.equipment_id && equip.rental_supplier_id === item.rental_supplier_id && equip.note === item.note)
  })
}
const getImageUrl = (imageHref: string) => {
  return fromStorage(imageHref)
}
const save = async() => {
  return new Promise(async () => {
    try {
      if (isSaving.value) throw new Error('Saving already in progress.')

      isSaving.value = true

      await saveWorkOrderEquipment(props.workOrder.id, suggestedEquipment.value)

    } catch(error: any) {
      // TODO maybe we should refresh table data
      sayErrorResponse(error)
    } finally {
      isSaving.value = false
      // initializeInputs()
    }
  })
}
defineExpose({
  initializeInputs,
  save
});
</script>

<style lang="scss" scoped>
.input-group {
  padding: 0;
}
</style>
