<template>
  <Card :title="`${$t('location', 2)} & ${$t('equipment', 1)}`" class="equipment-locations">
      <template #content>
        <v-data-table-server
          :loading="loading"
          :items-per-page-text="rowsPerPageText"
          :items-per-page-options="rowsPerPageItems"
          v-model:items-per-page="pagination.rowsPerPage"
          v-model:page="pagination.page"
          :items="tableData"
          :items-length="totalItems"
          :headers="headers"
          id="job-equipment-tab-table"
          item-value="id"
          @update:sort-by="sortEvent"
        >
          <template #[`item.image`]="{item}">
            <img v-if="item.image_url" :src="item.image_url" class="equipment-img" />
          </template>
          <template #[`item.job`]="{item}">
            <router-link
              v-if="item.job_id"
              :to="{ name: 'JobsView', params: { id: item.job_id } }"
              class="no-wrap"
            >
              {{ item.job_name }}
            </router-link>
          </template>
          <template #[`item.rent_started_at`]="{item}">
            <div class="dateTime">
              {{ item.rent_started_at }}
            </div>
          </template>
          <template #[`item.rent_ended_at`]="{item}">
            <div class="dateTime">
              {{ item.rent_ended_at }}
            </div>
          </template>
          <template #[`item.placed_by_work_order`]="{item}">
            <router-link
              v-if="item.placed_by_work_order_id"
              :to="{ name: 'WorkOrdersView', params: { id: item.placed_by_work_order_id } }"
            >
              {{ item.placed_by_work_order }}
            </router-link>
          </template>
          <template #[`item.name`]="{item}">
            <router-link
              v-if="!item.composite_equipment_id"
              class="custom-a"
              :to="{ name: 'EquipmentItemView', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
            <template v-else>
              {{ item.name }}
            </template>
          </template>
        </v-data-table-server>
      </template>
    </Card>
</template>

<script setup lang="ts">
import Card from '@/components/UI/Card.vue'
import {clientEquipmentItemLocationAPI as API} from '@/api/clients/clientEquipmentItemLocation'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import datatableOptions from '@/mixins/datatableOptions'
const { rowsPerPageText, rowsPerPageItems, pagination } = datatableOptions(false)
import { useI18n } from 'vue-i18n';
import {cloneDeep} from 'lodash-es'
const {t} = useI18n()

const props = defineProps({
  client: {
    type: Object,
    required: true
  },
})

const loading = ref(false)
const items = ref([])
const totalItems = ref(1)
const tableData = computed(() => {
  items.value.forEach((item) => {
    item.isFormatted = true
    item.originalItem = cloneDeep(item)

    item.rent_started_at = formatDateTime(item.rent_started_at)
    item.rent_ended_at = formatDateTime(item.rent_ended_at)
    item.placed_at = formatDateTime(item.placed_at)
    item.collected_at = formatDateTime(item.collected_at)

    if(item.system_created !== 0) {
      if(item.system_created === item.total_items) {
        item.system_created = t('yes');
      } else {
        item.system_created = `${item.system_created}/${item.total_items}`
      }
    } else {
      item.system_created = '';
    }

    item.amount = item.count
  })
  return items.value
})

const headers: any = computed(() => {
    return [
      { title: '', key: 'image', width:40, sortable:false },
      { title: t('amount'), key: 'amount', width:45, sortable:false },
      { title: t('name'), key: 'name', width:150, sortable:false },
      { title: t('RentalSupplierName'), key: 'rental_supplier_name', sortable:false },
      { title: t('SystemCreated'), key: 'system_created', sortable:false },
      { title: t('job'), key: 'job', sortable:false },
      { title: t('PlacedEquipmentAt'), key: 'placed_at', sortable:false },
      { title: t('PlacedByWorkOrder'), key: 'placed_by_work_order', width:140, sortable:false },
      { title: t('RentEquipmentStart'), key: 'rent_started_at', sortable:false },
      { title: t('RentEquipmentEnd'), key: 'rent_ended_at', sortable:false },
      { title: t('location'), key: 'location.location', sortable:false },
    ]
})

onMounted(() => {
  API.setClientId(props.client.id)
})

/**
 * Watch for changes in the table pagination
 */
watch([pagination], (function (){
  nextTick(() => {
    fetchItems()
  })
}), { immediate: true, deep: true})

const sortEvent = (value: any) => {
  pagination.value.sortBy = value[0]?.key
  pagination.value.descending = value[0]?.order === 'desc'
}

const fetchItems = () => {
  loading.value=true
  items.value = []
  API.index({
    rowsPerPage: pagination.value.rowsPerPage,
    page: pagination.value.page,
    sortBy: pagination.value.sortBy,
    descending: pagination.value.descending,
  })
    .then((response) => {
      items.value = response.data.data
      totalItems.value = response.data.total
      loading.value=false
    })
    .catch((error) => {
      alert(error)
    })
}

const formatDateTime = (dateTimeString: string) => {
  if (! dateTimeString) return ''
  dateTimeString = dateTimeString.toString()
  let parts = []
  if (dateTimeString.includes('T')) {
    parts = dateTimeString.split('T')
  } else {
    parts = dateTimeString.split(' ')
  }
  if (parts.length === 0) {
    return ''
  }
  const [date, time] = parts;
  const [year, month, day] = date.split('-');
  const formattedDate = `${day}-${month}-${year}`;
  if (!time) {
    return formattedDate;
  }
  const [hour, minute] = time.split(':');
  return `${formattedDate} ${hour}:${minute}`;
}
</script>

<style lang="scss">
.v-table#job-equipment-tab-table th {
  white-space: nowrap;
}
.equipment-img {
  max-width: 30px;
  max-height: 20px;
  display: block;
  margin: 0 auto;
}
.dateTime {
  min-width: 60px;
}
.no-wrap {
  white-space: nowrap;
}
</style>
