<template>
  <v-row wrap>
    <v-col sm="12" >
      <v-card flat>
        <v-card-title>
          <v-autocomplete
            :items="getEquipmentTypeOptions()"
            v-model="equipmentType"
            :label="t('type')"
            variant="underlined"
          ></v-autocomplete>
          <v-spacer/>
          <v-text-field
            append-icon="mdi-magnify"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-card-title>
      </v-card>
      <v-card flat border>
        <v-card-text class="px-0 py-0">
          <v-data-table-server
            :headers="headers"
            :items="equipmentList"
            :items-length="equipmentListTotalCount"
            :loading="equipmentListLoading"
            :search="search"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            @upadte:options="getEquipment"
            @update:sort-by="sortEvent"
          >

            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ header.title }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }: {item: any}">
              <tr>
                <!-- <td>
                  <router-link class="custom-a" :to="{ name: 'EquipmentEdit', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td> -->
                <td>
                  <img v-if="item.image_url" :src="item.image_url" class="equipment-img" />
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'EquipmentEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <template v-if="item.parent && item.parent?.name">
                    <router-link class="custom-a" :to="{ name: 'EquipmentEdit', params: { id: item.parent.id }}">
                      {{ item.parent.name }}
                    </router-link>
                  </template>
                </td>
                <td>
                  {{ getEquipmentType(item) }}
                </td>
                <td>
                  <v-icon v-if="item.is_consumable">mdi-check</v-icon>
                </td>
                <td>
                  <template v-if="item.category && item.category.name">
                    {{ item.category.name }}
                  </template>
                </td>
                <td>
                  {{ item.internal_article_number }}
                </td>
                <td>
                  {{ item.rate_type }}
                </td>
                <td class="text-right">
                  {{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.rate) }}
                </td>
                <td class="text-right">
                  {{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(item.lettering_cost) }}
                </td>
                <td class="text-right">
                  <v-btn variant="text" :to="{ name: 'EquipmentEdit', params: { id: item.id }}" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="item.deletable" variant="text" v-on:click="deleteEquipment(item.id)" icon flat>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { datatableOptions } from '@/mixins/datatableOptions'
import Say from '@/utils/Say'

import store from '@/store'
import { onBeforeMount, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { pagination, rowsPerPageItems, rowsPerPageText } = datatableOptions()
const { t } = useI18n()



const search = ref('')
const equipmentType = ref(null)
const headers: any = ref([
  // { title: 'Id', key: 'id', sortable: true, width: 10 },
  { title: '', key: 'image', sortable: false, width: 10 },
  { title: t('name', 1), key: 'name', sortable: true },
  { title: t('equipmentParent', 1), key: 'parent', sortable: false },
  { title: t('type'), key: 'bulk', sortable: false },
  { title: t('consumable'), key: 'consumable', sortable: false },
  { title: t('category', 1), key: 'category', sortable: false },
  { title: t('internalArticleNumber'), key: 'internal_article_number', sortable: true },
  { title: t('rateType'), key: 'rate_type', sortable: false },
  { title: t('rate'), key: 'rate', sortable: false },
  { title: t('letteringCost'), key: 'rate', sortable: false },
  { key: '', sortable: false, width: 100 }
])

const equipmentList = computed(() => store.getters.equipmentList)
const equipmentListLoading = computed(() => store.getters.equipmentListLoading)
const equipmentListTotalCount = computed(() => store.getters.equipmentListTotalCount)

watch(pagination, debounce(function (){
  getEquipment()
}, 300), { immediate: true, deep: true})

// watch the search to call the api instead of using the client side search
watch(search, debounce(() => {
  getEquipment()
}, 500))

watch(equipmentType, () => {
  getEquipment()
})

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('equipment', 2))
  store.commit('app.setBreadcrumbs', [
    { title: t('equipment', 2) }
  ])

  // getEquipment()
})

const getEquipment = () => {
  let isBulk = null
  let isCombi = null
  if (equipmentType !== null) {
    if (equipmentType.value === 'bulk') {
      isBulk = 1
    } else if (equipmentType.value === 'unique') {
      isBulk = 0
    } else if (equipmentType.value === 'combi') {
      isCombi = 1
    }
  }
  store.dispatch('equipment.fetch', Object.assign(
    pagination.value ,
    { search: search.value },
    { is_bulk: isBulk },
    { is_combi: isCombi }
  ))
}
const deleteEquipment = (id: any) => {
  let confirmAction = confirm(t('equipmentDeleteConfirm'))
  if (confirmAction) {
    store.dispatch('equipment.delete', id).then(() => {
      Say('success', t('equipmentDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}
const getEquipmentTypeOptions = () => {
  return [
    { title: t('equipmentAll'), value: null },
    { title: t('equipmentBulk'), value: 'bulk' },
    { title: t('equipmentUnique'), value: 'unique' },
    { title: t('equipmentCompositeAll'), value: 'combi' }
  ]
}
const sortEvent = (value: any) => {

  if(value.length === 0) {
    return
  }

  pagination.value.sortBy = `equipment.${value[0].key}`
  pagination.value.descending = value[0].order === 'desc' ? true : false

  getEquipment()
}
const getEquipmentType = (item: any) => {
  if (item.is_bulk === 1 && item.is_combi === 1) {
    return t('composite')
  } else if (item.is_bulk === 1) {
    return t('bulk')
  } else {
    return t('unique')
  }
}
</script>

<style scoped>
.equipment-img {
  max-width: 30px;
  max-height: 20px;
  display: block;
  margin: 0 auto;
}
</style>
