<template>
  <v-row wrap>
    <v-col sm="12">
      <v-card flat>
        <v-card-title>
          <v-spacer/>
          <v-text-field
            append-icon="search"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-card-title>
      </v-card>
      <v-card flat border>
        <v-card-text class="px-0 py-0">
          <v-data-table-server
            :headers="headers"
            :items="employeesList"
            :items-length="employeesListTotalCount"
            :loading="employeesListLoading"
            :search="search"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @update:options="getEmployees"
            @update:sort-by="sortEvent"
          >

            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ header.title }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'EmployeesEdit', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'EmployeesEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <span class="bg-orange-darken-2 ownEmployee" v-if="!item.company">
                    Van Amerongen
                  </span>
                  <span v-else>
                    {{ item.company }}
                  </span>
                </td>
                <td>{{ item.phone }}</td>
                <td class="text-right">
                  <v-btn v-if="!props.showArchived" variant="text" :to="{ name: 'EmployeesEdit', params: { id: item.id }}" :title="$t('edit')" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="!props.showArchived" variant="text" v-on:click="deleteEmployee(item.id)" :title="$t('archive')" icon flat>
                    <v-icon color="red">mdi-archive</v-icon>
                  </v-btn>
                  <v-btn v-else variant="text" v-on:click="restoreEmployee(item.id)" :title="$t('restore')" icon flat>
                    <v-icon color="red">mdi-restore</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { datatableOptions } from '@/mixins/datatableOptions'
import Say from '@/utils/Say'

import { defineComponent, computed, ref, onBeforeMount, watch } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'
import useBranchSelector from '@/composable/useBranchSelector'
import { computedAsync } from '@vueuse/core'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { branchSelected } = useBranchSelector()
const { pagination, rowsPerPageItems, rowsPerPageText } = datatableOptions()

const { t } = useI18n()

const props = defineProps({
  showArchived: {
    type: Boolean,
    default: false,
  },
})



const searchValue = ref('')
const search = computed({
  get() {
    return searchValue.value
  },
  set: debounce((value: string) => {
    searchValue.value = value
  }, 500)
})
const headers: any = ref([
  { title: 'Id', value: 'id', key: 'id', sortable: true, width: 65 },
  { title: t('name'), value: 'name', key: 'name', sortable: true, width: 300 },
  { title: t('company'), value: 'company', key: 'company', sortable: true, width: 300 },
  { title: t('phone'), sortable: false, key: 'phone' },
  { sortable: false, key: '', width: 100 }
])

const employeesList = computed(() => store.getters.employeesList)
const employeesListTotalCount = computed(() => store.getters.employeesListTotalCount)
const employeesListLoading = computed(() => store.getters.employeesListLoading)

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', props.showArchived ? t('employeeArchived', 2) : t('employee', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('employee', 2) }
  ])
  // getEmployees()
})

const getEmployees: any = computedAsync(async() => {

  return await store.dispatch('employees.fetch', Object.assign(
    pagination.value,
    { search: search.value, archived: props.showArchived }
  ))
})

const deleteEmployee = (id: any) => {
  let confirmAction = confirm(t('deleteEmployeeConfirm'))
  if (confirmAction) {
    store.dispatch('employees.delete', id).then(() => {
      store.commit('cache.employees.setDirty')
      Say('success', t('deleteEmployeeSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}
const restoreEmployee = (id: any) => {
  let confirmAction = confirm(t('restoreEmployeeConfirm'))
  if (confirmAction) {
    store.dispatch('employees.restore', id).then(() => {
      store.commit('cache.employees.setDirty')
      Say('success', t('restoreEmployeeSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}
const sortEvent = (value: any) => {

  if(value.length === 0) {
    return
  }

  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false

}
</script>

<style>
  .ownEmployee {
    padding: 4px 7px;
    border-radius: 5px;
    color: #fff;
  }
</style>
