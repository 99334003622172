import app from './app'
import cache from './cache'
import clients from './clients'
import employees from './employees'
import equipment from './equipment'
import permissions from './permissions'
import roles from './roles'
import users from './users'
import workOrders from './workOrders'
import jobs from './jobs'
import search from './search'
import rentalSuppliers from './rentalSuppliers'
import workInProgressReports from './workInProgressReports'
import Vuex from 'vuex'

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    app,
    cache,
    clients,
    employees,
    equipment,
    permissions,
    roles,
    users,
    workOrders,
    jobs,
    search,
    rentalSuppliers,
    workInProgressReports
  }
})

