<template>
  <div>
    <client-form
      :client="client"
      :submit="submit"
      :title="t('clientCreate')"
      :buttonText="t('create')"
      :onInput="onInput"
      :loading="loading"
    />
  </div>
</template>

<script setup lang="ts">
import ClientForm from './Form.vue'
import Say from '@/utils/Say'
import {defineComponent, computed, ref, onBeforeUnmount, onBeforeMount} from 'vue';
import store from '@/store';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { provide } from 'vue';
import sayErrorResponse from '@/mixins/sayErrorResponse'


defineComponent({
  ClientForm
})

const loading= ref(false)
const router = useRouter()
const { t } = useI18n()


const client = computed(() => {
  return store.getters.clientsCreate
})

provide('client', client)
onBeforeUnmount(() => {
  store.commit('clients.createClear')
})
onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('clientCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('client', 2), routeName: 'Clients' },
    { text: t('clientCreate') }
  ])
})

const submit = () => {
  loading.value = true
  store.dispatch('clients.create').then((id) => {
    loading.value = false
    store.commit('cache.clients.setDirty')
    Say('success', 'The client was created')
    router.push({ name: 'ClientsEdit', params: { id: id } })
  }).catch((err: any) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: any, value: any) => {
  store.commit('clients.createUpdateInput', { name: name, value: value })
}

</script>
