<template>
  <v-form v-on:submit.prevent="submit()" v-model="valid" ref="form">
    <v-card flat border>
      <v-card-text>
        <v-text-field
          variant="underlined"
          :label="$t('name')"
          v-model="rentalSupplier.name"
          :rules="rules.name"
          @keyup="onInput('name', $event.target.value)"
          v-if="!hideName"
        ></v-text-field>
        <v-text-field
          variant="underlined"
          :label="$t('phone')"
          v-model="rentalSupplier.phone"
          :rules="rules.phone"
          @keyup="onInput('phone', $event.target.value)"
        ></v-text-field>

        <!-- Location to -->
        <div class="location-wrapper">
          <v-autocomplete
            v-model="rentalSupplier.location"
            :items="[]"
            :label="$t('location')"
            item-title="location"
            item-value="id"
            :prepend-icon="'mdi-plus'"
            variant="underlined"
            @click:prepend="showCreateClientLocationDialog()"
            @change="$nextTick(() => $emit('change', { location: rentalSupplier.location }))"
          />
          <MapsButton
            v-if="rentalSupplier.location"
            :address="getMapsButtonUrl()"
            class="google-maps-button"
          />
        </div>
      </v-card-text>
      <v-card-actions v-if="!hideSubmit">
        <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" :disabled="!valid">{{ buttonText }}</v-btn>
        <v-progress-circular indeterminate color="orange-darken-1" v-show="loading"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-form>

  <!-- Add client location dialog -->
  <v-dialog v-if="dialog.showClientLocationDialog" v-model="dialog.showClientLocationDialog" max-width="1000px" content-class="client-location-dialog" ref="clientLocationDialog">
    <client-location-card
      :rentalSupplierId="rentalSupplier.id"
      @created="afterCreateLocation"
      :close="close"
      ref="clientLocationCard"
      :onInput="() => {}"
      @close="update('showClientLocationDialog', $event)"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import { default as defaultRules } from '@/validations/rentalSuppliers'
import { computed, ref, reactive } from 'vue'
import MapsButton from '@/components/UI/MapsButton.vue'
import ClientLocationCard from '@/components/Clients/LocationCard.vue'
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  rentalSupplier: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: false,
    default: () => { }
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    required: false,
    default: 'Save'
  },
  customRules: {
    type: Object,
    default: () => { }
  },
  hideName: {
    type: Boolean,
    default: false
  },
  hideSubmit: {
    type: Boolean,
    default: false
  }
})

const rentalSupplier = ref(props.rentalSupplier)

const valid = ref(false)

const rules = computed(() => {
  return Object.assign(defaultRules, props.customRules ? props.customRules : {})
})

const getMapsButtonUrl = ()  => {
  return rentalSupplier.value.location.latitude && rentalSupplier.value.location.longitude ? rentalSupplier.value.location.latitude + ',' + rentalSupplier.value.location.longitude : rentalSupplier.value.location.location
}

const dialog = reactive({
  showClientLocationDialog: false
})

const showCreateClientLocationDialog = () => {
  dialog.showClientLocationDialog = true
}
const afterCreateLocation = (location: any) => {
  if (!location) return

  rentalSupplier.value.location = location
  rentalSupplier.value.location_id = location.id

  props.onInput('location_id', location.id)

  dialog.showClientLocationDialog = false
}
const close = () => {
  dialog.showClientLocationDialog = false
}

const update = (name: any, value: Boolean) => {
  const dialogLocal: {[key: string]: any} = dialog
  dialogLocal[name] = value
}
</script>

<style scoped>
hr {
  margin: 30px 0;
}
</style>
