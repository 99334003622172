<template>
  <div
    id="equipment-items-list"
    :class="{
      'is-loading': equipmentItemsListLoading
    }"
  >
    <Portal to="page-buttons">
      <div class="pageButtons">
        <template v-if="showAsGroupedItems && locationId > 0">
          <router-link :to="{ name: 'EquipmentItemsPrint', query: printRouteQuery }" target="_blank" rel="noopener">
            <v-btn
              color="primary"
              depressed
              size="small"
              class="mr-1"
            >
              {{ t('print') }}
            </v-btn>
          </router-link>
        </template>
        <template v-else>
          <div class="printOnlyPossibleForGroupedView">
            {{ t('printOnlyPossibleForGroupedViewWithLocation') }}
          </div>
        </template>
      </div>
    </Portal>
    <v-row wrap>
      <v-col sm="12">
        <v-card flat>
          <v-card-title class="mb-4">

            <v-chip-group
              v-model="showAsGroupedItems"
              mandatory
              selected-class="bg-blue-darken-1"
              class="align-center"
              @update:model-value="getEquipmentItems">
              <h4 class="pr-5 equipmentItemFilterLabel">
                {{ t('showAs') }}:
              </h4>
              <v-chip
                :value="true"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('grouped') }}
              </v-chip>
              <v-chip
                :value="false"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('individual') }}
              </v-chip>
            </v-chip-group>

            <v-chip-group
              v-model="status"
              mandatory
              selected-class="bg-blue-darken-1"
              class="align-center"
              @update:model-value="getEquipmentItems">
              <h4 class="pr-5 equipmentItemFilterLabel">
                {{ t('status') }}:
              </h4>
              <v-chip
                :value="''"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ t('all') }}
              </v-chip>
              <template v-for="status in statuses">
                <v-chip
                  :value="status"
                  :disabled="equipmentItemsListLoading"
                  flat>
                  {{ status }}
                </v-chip>
              </template>
            </v-chip-group>

            <v-chip-group
              v-model="systemCreated"
              mandatory
              selected-class="bg-blue-darken-1"
              class="align-center"
              @update:model-value="getEquipmentItems">
              <h4 class="pr-5 equipmentItemFilterLabel twoRows">
                {{ t('createdBySystem') }}:
              </h4>
              <v-chip
                :value="''"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('all') }}
              </v-chip>
              <v-chip
                :value="false"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('no') }}
              </v-chip>
              <v-chip
                :value="true"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('yes') }}
              </v-chip>
            </v-chip-group>

            <v-chip-group
              v-model="isAttachedToJob"
              mandatory
              selected-class="bg-blue-darken-1"
              class="align-center"
              @update:model-value="getEquipmentItems">
              <h4 class="pr-5 equipmentItemFilterLabel twoRows">
                {{ t('isAttachedToJob') }}:
              </h4>
              <v-chip
                :value="''"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('all') }}
              </v-chip>
              <v-chip
                :value="false"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('no') }}
              </v-chip>
              <v-chip
                :value="true"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('yes') }}
              </v-chip>
            </v-chip-group>

            <v-chip-group
              v-model="ownEquipment"
              mandatory
              selected-class="bg-blue-darken-1"
              class="align-center"
              @update:model-value="getEquipmentItems">
              <h4 class="pr-5 equipmentItemFilterLabel">
                {{ t('type') }}:
              </h4>
              <v-chip
                :value="''"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('all') }}
              </v-chip>
              <v-chip
                :value="true"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('ownEquipment') }}
              </v-chip>
              <v-chip
                :value="false"
                :disabled="equipmentItemsListLoading"
                flat>
                {{ $t('rentedEquipment') }}
              </v-chip>
            </v-chip-group>

            <v-chip-group>
              <h4 class="pr-5 equipmentItemFilterLabel equipmentItemFilterLabelInput">
                  {{ t('rentedFrom') }}:
                </h4>
              <v-autocomplete
                v-model="rentalSupplierId"
                :items="rentalSupplierOptions"
                item-value="id"
                item-title="name"
                @update:model-value="getEquipmentItems"
                clearable
                :disabled="equipmentItemsListLoading"
                :class="['filterInput']"
                density="compact"
                hide-details="true"
              />
            </v-chip-group>

            <v-chip-group>
              <h4 class="pr-5 equipmentItemFilterLabel equipmentItemFilterLabelInput">
                  {{ t('location') }}:
                </h4>
              <v-autocomplete
                v-model="locationId"
                :items="locationOptions"
                item-value="id"
                item-title="location"
                :disabled="equipmentItemsListLoading"
                @update:model-value="getEquipmentItems"
                clearable
                :class="['filterInput']"
                density="compact"
                hide-details="true"
              />
            </v-chip-group>

            <v-chip-group>
              <h4 class="pr-5 equipmentItemFilterLabel equipmentItemFilterLabelInput">
                  {{ t('equipment') }}:
                </h4>
              <v-autocomplete
                v-model="equipmentId"
                :items="equipmentOptions"
                item-value="id"
                item-title="equipment"
                :disabled="equipmentItemsListLoading"
                @update:model-value="getEquipmentItems"
                clearable
                :class="['filterInput']"
                density="compact"
                hide-details="true"
              />
            </v-chip-group>

          </v-card-title>
        </v-card>
        <v-card flat border>
          <v-card-text class="d-block px-0 py-0">
            <v-data-table-server
              v-if="equipmentItemsList"
              :headers="showAsGroupedItems ? headersGrouped : headersIndividual"
              :items="equipmentItemsList"
              :items-length="equipmentItemsListTotalCount"
              :loading="equipmentItemsListLoading"
              :items-per-page-text="rowsPerPageText"
              :items-per-page-options="rowsPerPageItems"
              v-model="selectedItems"
              v-model:items-per-page="pagination.rowsPerPage"
              v-model:page="pagination.page"
              @update:options="getEquipmentItems"
              @update:sort-by="sortEvent"
              :show-select="true"
              item-selectable="selectable"
              id="equipment-items-table"
            >
              <template v-slot:headers="{isSorted, getSortIcon, toggleSort, columns }">
                <tr>
                  <template v-for="column in columns" :key="column.key" >
                    <th class="custom_header_table custom-bg" :style="{width: `${column.width}px !important`}" >
                      <span class="d-flex align-center" @click="column.sortable ? toggleSort(column) : ''">
                        <span :class="column.sortable ? 'pointer' : ''" class="font-weight-bold">{{ capitalcase(column.title) }}</span>
                        <template v-if="isSorted(column)">
                          <v-icon :icon="getSortIcon(column)" size="small" style="margin: 5px"></v-icon>
                        </template>
                        <template v-if="column.sortable && !isSorted(column)">
                          <v-icon icon="mdi-sort" size="small" style="margin: 5px"></v-icon>
                        </template>
                      </span>
                    </th>
                  </template>
                </tr>

              </template>
              <template #[`item.image`]="{item}">
                <img v-if="item.image_url" :src="item.image_url" class="equipment-img" />
              </template>
              <template #[`item.equipment.name`]="{item}">
                <template v-if="!showAsGroupedItems">
                  <router-link class="custom-a" :to="{ name: 'EquipmentItemView', params: { id: item.id }}">
                    {{ item.equipment.name }}
                  </router-link>
                </template>
                <template v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ props }">
                    <span v-bind="props">
                        {{ item.equipment.name }}
                    </span>
                    </template>
                    <span>{{ t('itemsAreOnlyClickableInViewWithIndividualItems') }}</span>
                  </v-tooltip>
                </template>
              </template>

              <template #[`item.system_created`]="{item}">
                {{ item.system_created ? t('yes') : '' }}
              </template>

              <template #[`item.job_name`]="{item}">
                <div v-if="item.job_id">
                  <router-link
                    v-if="item.job_id"
                    :to="{ name: 'JobsView', params: { id: item.job_id } }">
                    {{ item.job_name }}
                  </router-link>
                </div>
              </template>

              <!-- <template #[`item.client_id`]="{item}">
                <div v-if="item.client_id">
                  <router-link
                    v-if="item.client_id"
                    :to="{ name: 'ClientsEdit', params: { id: item.client_id } }">
                    {{ item.client_id }}
                  </router-link>
                </div>
                <div v-if="item.location?.location?.client_id">
                  <router-link
                    v-if="item.location?.location?.client_id"
                    :to="{ name: 'ClientsEdit', params: { id: item.location.location.client_id } }">
                    {{ item.location.location.client_id }}
                  </router-link>
                </div>
              </template> -->

              <template #[`item.location`]="{item}">
                <template v-if="!showAsGroupedItems">
                  <span v-if="item.location?.location.branch_id" class="badge badgeOrange">{{ $t('branch') }}</span>
                  <span v-if="item.location?.location.rental_supplier_id" class="badge badgeBlue">{{ $t('rentalSupplierBrannch') }}</span>
                  {{ item.location?.location.location }}
                </template>
                <template v-else>
                  <span v-if="item.location_branch_id" class="badge badgeOrange">{{ $t('branch') }}</span>
                  <span v-if="item.location_rental_supplier_id" class="badge badgeBlue">{{ $t('rentalSupplierBrannch') }}</span>
                  {{ item.location_searchable_address }}
                </template>
              </template>

              <template #[`item.amount`]="{item}">
                <template v-if="!showAsGroupedItems">
                  1
                </template>
                <template v-else>
                  {{ item.count}}
                </template>
              </template>

              <template #[`item.rental_supplier_name`]="{item}">
                <router-link
                  v-if="item.rental_supplier_id"
                  :to="{ name: 'RentalSuppliersEdit', params: { id: item.rental_supplier_id } }">
                  {{ item.rental_supplier_name }}
                </router-link>
              </template>

              <template #[`item.actions`]="{item}" style="width: 28px">
                <template v-if="!showAsGroupedItems && item.selectable">
                  <v-btn variant="text" :to="{ name: 'EquipmentItemsEdit', params: { id: item.id }}" flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </template>
            </v-data-table-server>
          </v-card-text>
        </v-card>
        <span>{{$t('dialogBulk')}}</span>
        <v-btn :text="t('status')" :disabled="!selectedItems.length" class="ml-2 mr-2 mt-0 mb-0" variant="tonal"  @click="bulkUpdate('status')"/>
        <v-btn :text="t('moveToOtherLocation')" :disabled="!selectedItems.length" class="mt-0 mb-0" variant="tonal" @click="bulkUpdate('moveToOtherLocation')"/>
      </v-col>
    </v-row>

    <v-dialog
      v-model="bulkDialogVisible"
      width="400"
      id="equipment-items-bulk-update-dialog"
      @afterLeave="bulkForm.reset()"
    >
      <v-form
        v-model="bulkFormValid"
        ref="bulkForm"
        @submit.prevent="submitBulkForm"
      >
        <v-card
          max-width="400"
          prepend-icon="mdi-pencil"
          :title="bulkDialogTitle"
        >
          <template v-slot:text>
            <template v-if="bulkDialogType === 'moveToOtherLocation'">
              <v-radio-group
                v-model="bulkFormFields.toLocationType"
                :rules="bulkFormFieldRules.toLocationType"
                row
              >
                <v-radio
                  :label="$t('vaLocation')"
                  value="vaLocation"
                />
                <v-radio
                  :label="$t('rentalSuppliersLocation')"
                  value="rentalSuppliersLocation"
                  :disabled="hasRentalSupplierItems === 0"
                />
              </v-radio-group>
              <v-autocomplete
                v-if="bulkFormFields.toLocationType === 'vaLocation' || hasRentalSupplierItems.length === 0"
                :items="vaLocations"
                v-model="bulkFormFields.va_location_id"
                :rules="bulkFormFieldRules.va_location_id"
                :label="$t('location')"
                item-value="id"
                item-title="location"
                variant="underlined"
              />
              <v-autocomplete
                v-if="bulkFormFields.toLocationType === 'rentalSuppliersLocation'"
                :items="selectedRentalSuppliersLocations"
                v-model="bulkFormFields.rental_suppliers_location_id"
                :rules="bulkFormFieldRules.rental_suppliers_location_id"
                :label="$t('location')"
                item-value="id"
                item-title="location"
                variant="underlined"
              />

              <template v-if="itemsAtLocation.length > 0 || (hasRentalSupplierItems > 0 && hasVAFItems > 0) || selectedRentalSuppliers.length > 1">
                <v-alert>
                  <p v-if="itemsNotAtLocation.length === 0" class="mb-1">
                    {{t('itemsAlreadyAtLocationAll')}}
                  </p>
                  <p v-else-if="itemsAtLocation.length > 0" class="mb-1">
                    {{itemsAtLocationMessage}}
                  </p>
                  <p v-if="(hasRentalSupplierItems > 0 && hasVAFItems > 0) || selectedRentalSuppliers.length > 1" class="mb-1">
                    {{t('warningMixedSupplierTypes')}}
                  </p>
                  <p class="mb-1">
                    <v-btn flat prepend-icon="mdi-information" class="mt-1" @click="bulkDialogSummaryVisible = true">{{t('see')}}</v-btn>
                  </p>

                  <v-switch
                    v-if="itemsNotAtLocation.length !== 0"
                    hide-details
                    :label="t('itemsAlreadyAtLocationMove')"
                    v-model="allowPartialLocationMove"
                  />
                </v-alert>
              </template>
            </template>
            <template v-if="bulkDialogType === 'status'">
              <v-select
                :items="statusOptions"
                :label="t('status', 1)"
                v-model="bulkFormFields.status"
                :rules="bulkFormFieldRules.status"
                required
                variant="underlined"
              />
              <div class="datepickerWrapper">
                <div class="datepickerLabel">{{t('statusDate', 1)}}</div>
                <Datepicker
                  v-model="bulkFormFields.status_date"
                  :required="true"
                  v-on:update:value-datetime="bulkFormFields.status_date = $event"
                  variant="underlined"
                  :teleport="false"
                >
                  <template #below>
                    <span
                      v-if="customFormErrors.status_date"
                      class="error"
                    >
                      {{customFormErrors.status_date}}
                    </span>
                  </template>
                </Datepicker>
              </div>
            </template>
          </template>
          <template v-slot:actions>
            <v-btn
              :disabled="saveButtonDisabled"
              :text="t('save')"
              type="submit"
            />
            <v-btn :text="t('cancel')" type="reset" @click="bulkDialogVisible = false; bulkDialogType = ''" />
          </template>
        </v-card>
      </v-form>
    </v-dialog>


    <v-dialog
      max-width="800"
      v-model="bulkDialogSummaryVisible"
    >
      <v-card>
        <template v-slot:text>
          <v-data-table
            :headers="bulkDialogSummaryDataHeaders"
            :items="bulkDialogSummaryData"
            density="compact"
          />
        </template>
        <template v-slot:actions>
          <v-btn :text="t('ok')" type="reset" @click="bulkDialogSummaryVisible = false;" />
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.v-table#equipment-items-table th {
  white-space: nowrap !important;
}
body #equipment-items-list.is-loading {
  cursor: wait;
}
body #equipment-items-list .v-chip,
body #equipment-items-list .v-field {
  opacity: 1!important;
  transition: opacity 0.25s;
}
body #equipment-items-list .v-chip--disabled,
body #equipment-items-list .v-field--disabled {
  opacity: 0.65!important;
  transition: opacity 0.25s;
}
</style>
<style scoped deep>
.equipmentItemFilterLabel {
  padding-top: 10px;
  width: 120px;
}
.equipmentItemFilterLabel.twoRows {
  padding-top: 0;
}
.printOnlyPossibleForGroupedView {
  color: #999;
  font-size: 12px;
}
.equipmentItemFilterLabelInput {
  padding-top: 15px;
}
.v-dialog#equipment-items-bulk-update-dialog > .v-overlay__content > .v-card,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > .v-sheet,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-card,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-sheet {
  overflow: initial;
}
.filterInput {
  max-width: 500px !important;
}
.filterInput > .v-field__input {
  padding-top: 10px !important;
}
.badge {
  background-color: #999;
  color: #fff;
  padding: 2px 5px;
  border-radius: 25px;
  text-wrap: nowrap;
}
.badgeOrange {
  background-color: #f57c00;
  color: #fff;
}
.badgeBlue {
  background-color: #1E88E5;
  color: #fff;
}
.equipment-img {
  max-width: 30px;
  max-height: 20px;
  display: block;
  margin: 0 auto;
}
</style>

<script setup lang="ts">
import { datatableOptions } from '@/mixins/datatableOptions'
import { ref, computed, onBeforeMount, watch } from 'vue'
import store from '@/store'
import { equipmentItemStore } from '@/store/equipmentItems'
import { useI18n } from 'vue-i18n'
import { Portal } from 'portal-vue'
import emitArray from "@/utils/emit";
import Datepicker from '@/components/UI/Datepicker.vue'
import Say from '@/utils/Say';
import sayErrorResponse from '@/mixins/sayErrorResponse';
import {capitalcase} from "@/utils/filters";

const emit = defineEmits(emitArray.value)

const {t} = useI18n()

const showAsGroupedItems = ref(true)
const locationId = ref<number>()
const rentalSupplierId = ref<number>()
const equipmentId = ref<number>()
const locationBranchId = ref<number>(0)
const locationRentalSupplierId = ref<number>(0)
const locationName = ref('')
const rentalSupplierName = ref('')
const equipmentName = ref('')
const ownEquipment = ref('')
const status = ref('')
const systemCreated = ref('')
const isAttachedToJob = ref('')
const selectedItems = ref([])
const printRouteQuery = computed(() => {
  return {
    equipmentId: equipmentId.value,
    equipmentName: equipmentName.value,
    locationId: locationId.value,
    rentalSupplierId: rentalSupplierId.value,
    locationBranchId: locationBranchId.value,
    locationRentalSupplierId: locationRentalSupplierId.value,
    locationName: locationName.value,
    rentalSupplierName: rentalSupplierName.value,
    ownEquipment: ownEquipment.value,
    status: status.value,
    systemCreated: systemCreated.value,
    isAttachedToJob: isAttachedToJob.value,
  }
})
const headersIndividual: any = ref([
  { title: t('amount', 1), key: 'amount', sortable: false, width: 50 },
  { title: '', key: 'image', sortable:false },
  { title: t('equipment', 1), key: 'equipment.name', sortable: true },
  { title: t('uniqueFeature', 1), key: 'unique_feature', sortable: true },
  { title: t('status', 1), key: 'status', sortable: true, width: 50 },
  { title: t('rentedFrom', 1), key: 'rental_supplier_name', sortable: false },
  { title: t('createdBySystem', 1), key: 'system_created', sortable: true, width: 120 },
  { title: t('location', 1), key: 'location', sortable: false },
  { title: t('job', 1), key: 'job_name', sortable: false, width: 140 },
  // { title: t('client', 1), key: 'client_id', sortable: false },
  { title: '', key: 'actions', sortable: false },
])

const headersGrouped: any = ref([
  { title: t('amount', 1), key: 'amount', sortable: false, width: 50 },
  { title: '', key: 'image', sortable:false },
  { title: t('equipment', 1), key: 'equipment.name', sortable: true },
  { title: t('uniqueFeature', 1), key: 'unique_feature', sortable: true },
  { title: t('status', 1), key: 'status', sortable: true, width: 50 },
  { title: t('rentedFrom', 1), key: 'rental_supplier_name', sortable: false },
  { title: t('createdBySystem', 1), key: 'system_created', sortable: true, width: 120 },
  { title: t('location', 1), key: 'location', sortable: false },
  { title: t('job', 1), key: 'job_name', sortable: false, width: 140 },
  // { title: t('client', 1), key: 'client_id', sortable: false },
  { title: '', key: 'actions', sortable: false },
])

const equipmentItemsList = computed(() => equipmentItemStore.getters.equipmentItems)
const equipmentItemsListLoading = computed(() => equipmentItemStore.getters.equipmentItemListLoading)
const equipmentItemsListTotalCount = computed(() => equipmentItemStore.getters.equipmentItemListTotal)
const vaLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const rentalSuppliersLocations = computed(() => store.getters['cache.rentalSuppliersLocations'])
const locationOptions = computed(() => {
  const vaLoc = vaLocations.value.map((location: any) => {
    return {
      id: location.id,
      location: location.location
    }
  })
  const rentalLoc = rentalSuppliersLocations.value.map((location: any) => {
    return {
      id: location.id,
      location: location.location
    }
  })
  return [
    ...vaLoc,
    ...rentalLoc
  ]
})
const equipmentOptions = computed(() => {
  let options = store.getters.equipmentNonCombiItems.map((item: any) => {
    return {
      id: item.id,
      equipment: item.name,
      parent_id: item.parent_id
    }
  })

  return options
})
const rentalSupplierOptions = computed(() => {
  return store.getters.rentalSuppliersList
})

const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions(false)

const bulkDialogVisible = ref(false)
const bulkDialogType = ref('')
const bulkDialogSummaryVisible = ref(false)

const bulkForm = ref(null as HTMLFormElement | null)
const bulkFormValid = ref(false)
const bulkDialogTitle = computed(() => {
  if(bulkDialogType.value === 'status') {
    return t('updateStatus')
  }
  if(bulkDialogType.value === 'moveToOtherLocation') {
    return t('moveToOtherLocation')
  }
  return t('dialogBulk')
})

const bulkFormFields = ref({
  status: null,
  status_date: null,
  toLocationType: null,
  va_location_id: null,
  rental_suppliers_location_id: null,
})

const customFormErrors = ref({})

const defaultRules =ref({
  value: value => {
    if (value) return true
    return t('validationRequired')
  },
})
const bulkFormFieldRules = ref({
  status : [defaultRules.value.value],
  va_location_id : [defaultRules.value.value],
  rental_suppliers_location_id : [defaultRules.value.value],
  toLocationType : [defaultRules.value.value],
})

const selectedItemsRealIds = computed(() => {
  return selectedItems.value
})
const selectedItemsObjects = computed(() => {
  let returnData = []
  for (const item of equipmentItemsList.value) {
    if(selectedItems.value.includes(item.id)) {
      returnData.push(item)
    }
  }
  return returnData
})

const allowPartialLocationMove = ref(false)
const itemsAtLocation = computed(() => {
  return selectedItemsObjects.value.filter(
    item => (showAsGroupedItems.value ? item.location_id : item.location?.location_id) === bulkSubmitData.value.newLocation
  )
})
const itemsNotAtLocation = computed(() => {
  return selectedItemsObjects.value.filter(
    item => (showAsGroupedItems.value ? item.location_id : item.location?.location_id) !== bulkSubmitData.value.newLocation
  )
})
const itemsAtLocationMessage = computed(() => {
  let count = 0
  for (const item of itemsAtLocation.value) {
    count += item?.count
  }
  return t('itemsAlreadyAtLocation', {
    count: count
  })
})
const hasRentalSupplierItems = computed(() => {
  return selectedItemsObjects.value.filter(
    item => item.equipment_item?.rental_supplier_id || item.rental_supplier_id
  ).length
})
const hasVAFItems = computed(() => {
  return selectedItemsObjects.value.filter(
    item => !item.equipment_item?.rental_supplier_id && !item.rental_supplier_id
  ).length
})
const saveButtonDisabled = computed(() => {
  if(itemsAtLocation.value.length > 0 && !allowPartialLocationMove.value) {
    return true
  }
  if(
    bulkDialogType.value === 'moveToOtherLocation'
    && ((hasRentalSupplierItems.value > 0 && hasVAFItems.value > 0) || selectedRentalSuppliers.value.length > 1)
    && !allowPartialLocationMove.value
  ) {
    return true
  }
  return false
})
const selectedRentalSuppliers = computed(() => {
  let data = []
  for (const item of selectedItemsObjects.value) {
    let rsID = item.equipment_item?.rental_supplier_id ?? item.rental_supplier_id
    if(!rsID || data.includes(rsID)) continue
    data.push(rsID)
  }
  return data
})
const selectedRentalSuppliersLocations = computed(() => {
  let options = []
  let rentalSuppliersLocationsInList = []
  for (const location of rentalSuppliersLocations.value) {
    if(
      selectedRentalSuppliers.value.includes(location.rental_supplier_id)
      && !rentalSuppliersLocationsInList.includes(location.id)
    ) {
      options.push(location)
      rentalSuppliersLocationsInList.push(location.id)
    }
  }
  return options
})


const bulkDialogSummaryDataHeaders = ref([
  { key: 'amount', title: t('amount', 1), width: 25 },
  { key: 'name', title: t('equipment', 1) },
  { key: 'status', title: t('status', 1), width: 25 },
  { key: 'rental_supplier_name', title: t('rentedFrom', 1), width: 150 },
  { key: 'system_created', title: t('SystemCreated', 1), width: 150 },
  { key: 'location', title: t('location', 1), width: 150  },
  { key: 'job_name', title: t('job', 1), width: 150  },
  { key: 'atLocation', title: t('atLocation', 1), width: 150 },
])

const bulkDialogSummaryData = computed(() => {
  const columnKeys = bulkDialogSummaryDataHeaders.value.map((column) => column.key);
  let tableData = []

  const itemsList = [...itemsAtLocation.value, ...itemsNotAtLocation.value]
  for (const item of itemsList) {
    tableData.push(columnKeys.reduce((result, key) => {
      let value

      if(key === 'location') {
        value = item?.location_searchable_address ?? item?.location?.location?.location
      } else if(key === 'amount') {
        value = item?.count
      } else if(key === 'atLocation') {
        value = ((showAsGroupedItems.value ? item.location_id : item.location?.location_id) === bulkSubmitData.value.newLocation) ? t('yes') : ''
      } else if(key === 'system_created') {
        value = item.system_created ? t('yes') : ''
      } else {
        value = item[key]
      }
      return { ...result, [key]: value}
    }, {}))
  }
  return tableData
})

// These are the values that will be submitted to the server
// The correct fields are filtered from the bulkFormFields
const bulkSubmitData = computed(() => {

  const returnData = {
    ids: selectedItemsRealIds.value,
    updateData: [],
    updateType: bulkDialogType.value,
    grouped: showAsGroupedItems.value,
  }

  if(bulkDialogType.value === 'status') {
    returnData.updateData = ['status', 'status_date'].reduce((result, key) => ({ ...result, [key]: bulkFormFields.value[key] }), {});
  }

  if(bulkDialogType.value === 'moveToOtherLocation') {
    returnData.newLocation = bulkFormFields.value.toLocationType === 'vaLocation'
      ? bulkFormFields.value.va_location_id
      : bulkFormFields.value.rental_suppliers_location_id
  }

  return returnData;
})

onBeforeMount(() => {
  store.commit('app.setPageTitle', t('inventory', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('inventory', 2) }
  ])
  store.dispatch('cache.vanAmerongenLocations.fetch')
  store.dispatch('cache.rentalSuppliersLocations.fetch')
  store.dispatch('equipment.fetchNonCombiItems')
  store.dispatch('rentalSuppliers.fetch')
})

const getEquipmentItems = () => {

  // Reset bulk selection
  selectedItems.value = []

  equipmentItemStore.dispatch('fetchEquipmentItems', Object.assign(
    pagination.value,
    {
      showAsGroupedItems: showAsGroupedItems.value,
      locationId: locationId.value,
      rentalSupplierId: rentalSupplierId.value,
      ownEquipment: ownEquipment.value,
      status: status.value,
      systemCreated: systemCreated.value,
      equipmentId: equipmentId.value,
      isAttachedToJob: isAttachedToJob.value,
    }
  ))
}

const sortEvent = (value: any) => {
  if(value.length === 0) {
    return
  }
  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}

const statuses = computed(() => {
  return [
    'ACQUIRED',
    'LOST',
    'STOLEN',
    'DAMAGED',
    'RETIRED',
    'USED',
  ]
})
const statusOptions = computed(() => {
  return statuses.value.filter((status: string) => status !== 'USED')
})

watch(locationId, () => {
  // update locationName
  locationName.value = vaLocations.value.find((location: any) => location.id === locationId.value)?.location || ''
  // if locationName is empty
  if(!locationName.value) {
    locationName.value = rentalSuppliersLocations.value.find((location: any) => location.id === locationId.value)?.location || ''
  }
  locationBranchId.value = vaLocations.value.find((location: any) => location.id === locationId.value)?.branch_id || 0
  locationRentalSupplierId.value = rentalSuppliersLocations.value.find((location: any) => location.id === locationId.value)?.rental_supplier_id || 0
})
watch(equipmentId, () => {
  // update equipmentName
  equipmentName.value = equipmentOptions.value.find((equipment: any) => equipment.id === equipmentId.value)?.equipment || ''
})
watch(rentalSupplierId, () => {
  // update rentalSupplierName
  rentalSupplierName.value = rentalSupplierOptions.value.find((rentalSupplier: any) => rentalSupplier.id === rentalSupplierId.value)?.name || ''
})

watch(bulkFormFields, () => {
  if(bulkDialogType.value === 'status') {
    if (bulkFormFields.value.status_date && bulkFormFields.value.status_date !== 'Invalid date') {
      customFormErrors.value.status_date = null
    }
  }
}, {deep: true})


watch(bulkDialogVisible, () => {
  // Reset all the fields, do so whenever the dialog opens or closes
  bulkFormFields.value = {
    status: null,
    status_date: null,
    toLocationType: null,
    va_location_id: null,
    rental_suppliers_location_id: null,
  }
  customFormErrors.value = {}
  allowPartialLocationMove.value = false
})

const bulkUpdate = (type: string) => {
  bulkDialogVisible.value = true
  bulkDialogType.value = type
}

const submitBulkForm = () => {

  if(bulkDialogType.value === 'status') {
    if (!bulkFormFields.value.status_date || bulkFormFields.value.status_date === 'Invalid date') {
      customFormErrors.value.status_date = t('validationRequired')
      return
    }
  }

  if(!bulkFormValid.value) return

  equipmentItemStore.dispatch(
    'bulkUpdateEquipmentItems',
    bulkSubmitData.value
  ).then(() => {
    getEquipmentItems()
    bulkDialogVisible.value = false
    bulkDialogType.value = ''

    Say('success', t('equipmentItemEditSuccess', 1))
  }).catch((err) => {
    getEquipmentItems()
    bulkDialogVisible.value = false
    bulkDialogType.value = ''
    sayErrorResponse(err.response?.data?.message ?? err.message)
  })
}
</script>
