<script setup lang="ts">
import {computed, inject, onBeforeMount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import Datepicker from "@/components/UI/Datepicker.vue";
import {jobAdditionalCostsAPI as API} from "@/api/jobs/JobAdditionalCosts";
import SayErrorResponse from "@/mixins/sayErrorResponse";
import Say from "@/utils/Say";
import branchStore from "@/store/branches";
import useBranchSelector from '@/composable/useBranchSelector'
import {capitalcase} from "@/utils/filters";
const { branchSelected } = useBranchSelector()

const {t} = useI18n()

const jobId = inject('jobId', null)
API.setJobId(jobId.value)

const headers: any = ref([
  {title: t('description'), key: 'description'},
  {title: t('date'), key: 'date'},
  {title: t('internalArticleNumber'), key: 'internal_article_number'},
  {title: t('costCenter'), key: 'cost_center'},
  {title: t('vat'), key: 'vat_percentage', align: 'end'},
  {title: t('quantity'), key: 'quantity', align: 'end'},
  {title: t('pricePerUnitExclVat'), key: 'price_per_unit_excl_vat', align: 'end'},
  {title: t('amountExclVat'), key: 'total_excl_vat', align: 'end'},
  {width: 100, align: 'end', key: ''},
]);
const items = ref([]);

const dialogOpen = ref(null)
const dialogEditing = ref(null)
const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const formSubmitted = ref(false)

const formValues = ref({
  internal_article_number: null,
  cost_center: null,
  vat_percentage: null,
  description: null,
  date: null,
  quantity: null,
  price_per_unit_excl_vat: null,
})

const dateInputValidation = ref(null)

const costCentres = computed(() => {
  let returnValues = []
  for (const branch of branchStore.getters.branches) {
    returnValues.push({value:branch.cost_center, title: branch.cost_center, subtitle: branch.name})
  }
  return returnValues
})

const formRuleRequired = (value) => {
  if (value == null) return t('fieldRequired')
  if (value.length == 0) return t('fieldRequired')
  return true
}
const formRuleNotZero = (value) => {
  if (value == 0) return t('fieldRequired')
  return true
}

const formRules = ref({
  internal_article_number: [formRuleRequired],
  cost_center: [formRuleRequired],
  vat_percentage: [formRuleRequired],
  description: [formRuleRequired],
  quantity: [formRuleRequired, formRuleNotZero],
  price_per_unit_excl_vat: [formRuleRequired, formRuleNotZero],
  date: [formRuleRequired],
})
const dropDownOptions = computed(() => {
  return {
    internal_article_number: [
      {value: 'sample-one', title: 'Sample One'},
    ],
    cost_center: costCentres.value,
    vat_percentage: [
      {value: '0', title: '0%'},
      {value: '9', title: '9%'},
      {value: '21', title: '21%'},
    ],
  }
})

const openDialog = (editing = null) => {
  dateInputValidation.value = null
  dialogEditing.value = editing
  formValues.value = {
    internal_article_number: editing?.internal_article_number,
    cost_center: editing?.cost_center,
    vat_percentage: editing?.vat_percentage,
    description: editing?.description,
    date: editing?.date,
    quantity: editing?.quantity,
    price_per_unit_excl_vat: editing?.price_per_unit_excl_vat,
  }
  if (!dialogEditing.value ) {
    const preSelectedCostCenter = branchStore.getters.branches.filter(branch =>branch.id == branchSelected.value)
    if (preSelectedCostCenter[0]) {
      formValues.value.cost_center = preSelectedCostCenter[0]?.cost_center ?? null
    }
    formValues.value.vat_percentage = dropDownOptions.value.vat_percentage.filter(vat => vat.value == 21)[0].value
  }
  dialogOpen.value = true
}
const closeDialog = () => {
  dialogOpen.value = false
  dialogEditing.value = null

  dateInputValidation.value = null
  formValues.value = {
    internal_article_number: null,
    cost_center: null,
    vat_percentage: null,
    description: null,
    date: null,
    quantity: null,
    price_per_unit_excl_vat: null,
  }

}
onBeforeMount(async() => {
  await branchStore.dispatch('fetchBranches')
})
onMounted(() => {
  loadIndex()
})

const loadIndex = () => {
  API.index().then(
    (data) => {
      items.value = data.data
    },
    (error) => {
      SayErrorResponse(error)
    },
  )
}
const submit = () => {
  if (formSubmitted.value) {
    return
  }

  form.value.validate();

  formSubmitted.value = true

  if (!formValues.value.date || formValues.value.date == 'Invalid date') {
    dateInputValidation.value = true
  } else {
    dateInputValidation.value = null
  }

  if ((dateInputValidation.value) || !formValid.value) {
    formSubmitted.value = false
    return
  }

  if (!dialogEditing.value) {
    API.store(formValues.value).then(
      (data) => {
        loadIndex()
        closeDialog()
        Say('success', t('costCreated'))
        formSubmitted.value = false
      },
      (error) => {
        SayErrorResponse(error)
        formSubmitted.value = false
      },
    )
  } else {
    API.update(dialogEditing.value.id, formValues.value).then(
      (data) => {
        loadIndex()
        closeDialog()
        Say('success', t('costUpdated'))
        formSubmitted.value = false
      },
      (error) => {
        SayErrorResponse(error)
        formSubmitted.value = false
      },
    )
  }
}
const deleteRow = (id) => {
  if (!confirm(t('confirm'))) {
    return
  }
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true

  API.delete(id).then(
    (data) => {
      loadIndex()
      Say('success', t('costDeleted'))
      formSubmitted.value = false
    },
    (error) => {
      SayErrorResponse(error)
      formSubmitted.value = false
    },
  )
}

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          :text="t('addAdditionalCost')"
          @click="openDialog(null)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat rounded border>
          <v-data-table-server
            id="additional-costs-table"
            :headers="headers"
            :items="items"
            :items-length="items.length"
            :items-per-page="items.length"
            :hide-default-footer="true"
            disable-sort
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span :class="header.sortable ? 'pointer' : ''" class="font-weight-bold">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small" style="margin: 5px"></v-icon>
                      </template>
                      <template v-if="header.sortable && !isSorted(header)">
                        <v-icon icon="mdi-sort" size="small" style="margin: 5px"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>
            <template #[`item.description`]="rowData">
              <span class="allow-white-space">{{ rowData.item.description }}</span>
            </template>
            <template #[`item.vat_percentage`]="rowData">
              {{ rowData.item.vat_percentage }}%
            </template>
            <template #[`item.quantity`]="rowData">
              {{ formatNumber(rowData.item.quantity, 2) }}
            </template>
            <template #[`item.price_per_unit_excl_vat`]="rowData">
              &euro; {{ formatCurrency(rowData.item.price_per_unit_excl_vat) }}
            </template>
            <template #[`item.total_excl_vat`]="rowData">
              &euro; {{ formatCurrency(rowData.item.total_excl_vat) }}
            </template>
            <template #[`item.`]="rowData">
              <template v-if="rowData.item.editable">
                  <v-btn variant="text" @click="openDialog(rowData.item)" icon flat class="bg-transparent">
                      <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn @click="deleteRow(rowData.item.id)" icon flat class="bg-transparent">
                      <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
              </template>
              <template v-else>
                  <v-btn v-tooltip="t('additionalCostCannotBeEdited')" icon flat class="bg-transparent btn-disabled">
                      <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn  v-tooltip="t('additionalCostCannotBeDeleted')" icon flat class="bg-transparent btn-disabled">
                      <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
              </template>
            </template>
          </v-data-table-server>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogOpen"
      width="640"
    >
      <v-card
        max-width="640"
        prepend-icon="mdi-pencil"
        :title="dialogEditing ?  t('dialogEditCost') : t('dialogAddCost')"
      >
        <template v-slot:text>

          <v-form
            v-model="formValid"
            ref="form"
          >
            <div class="input-group">
              <v-row>
                <v-col md="6">
                  <div class="datepickerWrapper">
                <div class="datepickerLabel">{{ t('date') }}</div>
                <Datepicker
                  v-model="formValues.date"
                  :placeholder="t('date')"
                  v-on:update:value-datetime="formValues.date = $event"
                  :teleport="false"
                >
                  <template #below>
                    <div v-if="dateInputValidation" class="text-dark-red">
                      {{ t('fieldRequired') }}
                    </div>
                  </template>
                </Datepicker>
              </div>
                </v-col>
              </v-row>
            </div>
            <div class="input-group">
              <v-row>
                <v-col>
                <v-text-field
                  v-model="formValues.description"
                  :rules="formRules.description"
                  :label="t('description')"
                  variant="underlined"
                />
                </v-col>
              </v-row>

            </div>
            <div class="input-group">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="formValues.quantity"
                    :rules="formRules.quantity"
                    :label="t('quantity')"
                    type="number"
                    step="0.01"
                    min="0"
                    variant="underlined"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="formValues.price_per_unit_excl_vat"
                    prepend-icon="mdi-currency-eur"
                    :rules="formRules.price_per_unit_excl_vat"
                    :label="t('pricePerUnitExclVat')"
                    type="number"
                    step="0.01"
                    min="0"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="input-group">
              <v-row>
                <v-col md="4">
                  <v-select
                    v-model="formValues.internal_article_number"
                    :rules="formRules.internal_article_number"
                    :items="dropDownOptions.internal_article_number"
                    :label="t('internalArticleNumber')"
                    :item-props="true"
                    variant="underlined"
                  />
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="formValues.cost_center"
                    :rules="formRules.cost_center"
                    :items="dropDownOptions.cost_center"
                    :label="t('costCenter')"
                    :item-props="true"
                    variant="underlined"
                  />
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="formValues.vat_percentage"
                    :rules="formRules.vat_percentage"
                    :items="dropDownOptions.vat_percentage"
                    :label="t('vat')"
                    :item-props="true"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </template>
        <template v-slot:actions>
          <v-btn
            :text="t('save')"
            @click="submit"
          />
          <v-btn
            :text="t('cancel')"
            @click="closeDialog"
          />
        </template>
      </v-card>
    </v-dialog>


  </div>
</template>

<style scoped lang="scss">
::v-deep .v-table#additional-costs-table td {
  white-space: nowrap;
}
.allow-white-space {
  white-space: normal;
}
.btn {
  height: auto;
  margin-right: 16px;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.btn-red {
  color: rgb(255, 0, 0);
}

.btn[disabled] {
  color: rgba(245, 120, 40, 0.5);
  cursor: default !important;
}

.btn.btn-red[disabled] {
  color: rgba(255, 0, 0, 0.5);
  cursor: default !important;
}

.btn-disabled {
  opacity: 0.5;
  cursor: default;
}

.text-dark-red {
    color: rgb(176, 0, 32);
}
</style>
