import { fetchWorkInProgressReports, fetchWorkInProgressReport  } from '@/api/workInProgressReports'
import { Commit } from 'vuex'
import { WorkInProgressReportsState } from '../state'

const workInProgressReportsStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
  },
  getters: {
    'workInProgressReportsList': (state: WorkInProgressReportsState) => {
      return state.list
    },
    'workInProgressReportsListTotalCount': (state: WorkInProgressReportsState) => {
      return state.listTotal
    },
    'workInProgressReportsListLoading': (state: WorkInProgressReportsState) => {
      return state.listLoading
    }
  },
  mutations: {
    'workInProgressReports.setListTo': (state: WorkInProgressReportsState, payload: any) => {
      state.list = payload
    },
    'workInProgressReports.setListTotalCount': (state: WorkInProgressReportsState, payload: any) => {
      state.listTotal = payload
    },
    'workInProgressReports.setListLoadingTo': (state: WorkInProgressReportsState, payload: any) => {
      state.listLoading = payload
    },
    'workInProgressReports.addToList': (state: WorkInProgressReportsState, payload: any) => {
      state.list.push(payload)
    },
  },
  actions: {
    'workInProgressReports.fetch': ({ commit }: { commit: Commit }, pagination: any) => {
      commit('workInProgressReports.setListLoadingTo', true)
      fetchWorkInProgressReports().then((res) => {
        let workInProgressReports = res.data.data
        commit('workInProgressReports.setListTo', workInProgressReports)
        commit('workInProgressReports.setListTotalCount', res.data.total)
      })
    },
  }
}

export default workInProgressReportsStore
