<template>
  <v-app id="inspire">
    <template v-if="templateUIVisible">
      <navigation fixed :drawer="{ open: drawerOpen }" @open-search="openSearchModal"/>
      <v-app-bar
        :elevation="0"
        color="blue-darken-1"
        dark
        app
        fixed
        height="55"
        :style="[drawerOpen && auth.check() ? {'width': 'calc((100% - 300px) - 0px)', 'left': '300px'} : {'width': 'calc((100% - 0px) - 0px)', 'left': '0px'}]"
      >
        <v-app-bar-title class="ml-0 pl-3" style="font-size: 20px;">
          <v-app-bar-nav-icon @click.stop="drawerOpen = !drawerOpen" v-if="auth.check()"/>
          <span class="hidden-sm-and-down font-weight-medium">
            <template v-if="getEnvironment() !== 'production'">
              <span :class="{'mr-2': true, 'local': getEnvironment() === 'local'}" id="environment-badge">{{ getEnvironment() }}</span>
            </template>
            {{ pageTitle }}
          </span>
        </v-app-bar-title>
        <v-spacer/>

        <!-- <router-link :to="{ name: 'Settings' }" tag="v-btn" class="btn--flat">
        <v-icon>settings</v-icon>
      </router-link> -->
        <v-col cols="2" v-if="me">
          <v-select v-model="branchSelected" variant="underlined" :items="me.branches" item-title="city" item-value="id" :return-object="false"> </v-select>
        </v-col>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              flat
              v-bind="props"
              append-icon="mdi-face-man"
              v-if="auth.check()"
            >
              {{ me.name }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="logOut()" v-if="auth.check()">
              <v-list-item-title>{{ $t('logOut') }}</v-list-item-title>
            </v-list-item>
            <router-link :to="{ name: 'LogIn' }" tag="v-list-item" v-if="!auth.check()">
              <v-list-item-title>{{ $t('logIn') }}</v-list-item-title>
            </router-link>
          </v-list>
        </v-menu>
      </v-app-bar>
    </template>

    <v-main
      :style="[drawerOpen && auth.check() && templateUIVisible ? {'--v-layout-left': '300px'} : {'--v-layout-left': '0px'}]"
    >
      <template v-if="templateUIVisible && !exceptedRoutePath.includes($route.path)">
        <section style="border-bottom: 1px solid #ddd; height: 45px;">
          <v-breadcrumbs
            v-if="breadcrumbs.length > 0"
            :items="breadcrumbs"
            class="breadcrumbs h-100"
            color="blue-darken-1"
            bg-color="#fff"
          >
            <template v-slot:title="{ item }">
              <v-breadcrumbs-item
                :title="(item as any).text"
                :to="{ name: (item as any).routeName, params: (item as any).routeParams }"
                :color="breadcrumbs.indexOf(item) === breadcrumbs.length - 1 ? 'grey' : 'blue'"
              >
            </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>


          <v-row class="page-buttons align-center">
            <PortalTarget name="page-buttons" />
            <v-btn
              depressed
              size="small"
              color="orange-darken-2"
              v-if="toggleButton"
              :title="toggleButton.title ?? ''"
              :class="{
                'disabled': toggleButton.disabled
              }"
              @click="toggleButton.disabled ? false : $router.push(toggleButton.route)"
            >
              {{ toggleButton.text }}
            </v-btn>
          </v-row>
        </section>
      </template>

      <Search
        :visible.sync="isSearchModalOpen"
        max-width="50%"
        :scrollable="true"
        @click:outside="closeSearchModal"
        @update:visible="isSearchModalOpen = $event"
      />

      <router-view @show-template-ui="showTemplateUI" @hide-template-ui="hideTemplateUI"/>
    </v-main>
    <Notifications position="bottom right"/>
  </v-app>
</template>

<script setup lang="ts">
import Navigation from '@/components/Navigation.vue'
import { PortalTarget } from 'portal-vue'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, provide, onUnmounted, watch} from 'vue'
import { defineComponent } from 'vue'
import { useAuth } from 'vue-auth3'
import { useStore } from 'vuex'
import bus from './utils/eventBus'
import Search from './components/UI/Search.vue'
import useBranchSelector from './composable/useBranchSelector'

const { branchSelected } = useBranchSelector()
const store = useStore()
const auth = useAuth()

const isSearchModalOpen = ref(false)
const isFromSidebar = ref(false)

onMounted(() => {
  window.addEventListener('keydown', handleKeyPress);
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyPress);
});

const handleKeyPress = (event: any) => {
  if (event.ctrlKey && event.key === ' ') {
    openSearchModal()
    event.preventDefault()
  }
}

defineComponent({
  Navigation,
  PortalTarget,
})

const drawerOpen = ref(true)
const templateUIVisible = ref(true)

const searchResults = ref({} as Record<string, any>)

provide('searchResults', searchResults)
provide('isFromSidebar', isFromSidebar)

const pageTitle = computed(() => {
  return store.getters.appGetPageTitle
})
const breadcrumbs = computed(() => {
  return store.getters.appGetBreadcrumbs
})
const toggleButton = computed(() => {
  return store.getters.toggleButton
})
const me: Record<string, any> = computed(() => {
  return auth.user()
})

const exceptedRoutePath = ref([
  '/login'
])

const openSearchModal = (sidebar: boolean = false) => {
  isFromSidebar.value = sidebar

  isSearchModalOpen.value = true;
}
const closeSearchModal = () => {
  isSearchModalOpen.value = false;
}

onBeforeMount(() => {
  bus.on('show-template-ui', showTemplateUI)
  bus.on('hide-template-ui', hideTemplateUI)
})

onBeforeUnmount(() => {
  bus.off('show-template-ui', showTemplateUI)
  bus.off('hide-template-ui', hideTemplateUI)
})

const logOut = () => {
  store.commit('app.clearBreadcrumbs')
  store.commit('app.setPageTitle', 'Van Amerongen')
  auth.logout({ redirect: { name: 'LogIn' } })
}

const showTemplateUI = () => {
  templateUIVisible.value = true
}

const hideTemplateUI = () => {
  templateUIVisible.value = false
}

const getEnvironment = () => {
  if (window.location.host === 'admin.vaf-infra.nl') {
    return 'production'
  } else if (window.location.host === 'admin-staging.vaf-infra.nl') {
    return 'staging'
  } else {
    return 'local'
  }
}


watch(me, (val) => {
  if (val) {
    branchSelected.value = val.default_branch_id
  }
}, {immediate: true, deep: true})

defineExpose({
  openSearchModal,
  closeSearchModal,
})
</script>

<style lang="scss">
.v-main {
  background-color: var(--main-theme-background);
  padding-bottom: 0 !important;
}
.v-data-table-footer div[role="combobox"] .v-field__input {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 0;
}
.page-buttons {
  padding: 7px 15px;
}
#environment-badge {
  padding: 4px 12px;
  border-radius: 100px;
  background-color: rgb(245, 124, 0);
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
}
#environment-badge.local {
  background-color: #fff;
  color: rgb(30, 136, 229);
}
.v-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
