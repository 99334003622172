<template>
    <div>
    <template v-if="preLoading">
      <v-row class="align-center justify-center" style="height:calc(100vh - 132px)">
        <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
      </v-row>
    </template>

    <!-- MAIN FORM -->
    <v-form v-on:submit.prevent="saveWorkOrder" v-model="formValid" ref="form">
      <v-row>
        <v-col sm="10">
          <v-row>
            <v-col sm="6" style="position: relative">
              <primary-details-card
                v-if="!preLoading"
                :work-order="workOrder"
                :vanAmerongenLocations="vanAmerongenLocations"
                :rentalSuppliersLocations="rentalSuppliersLocations"
                @change="updatePrimaryDetails"
                @show-client-dialog="showCreateClientDialog"
                @show-location-dialog="showCreateClientLocationDialog"
                ref="primaryDetailsCard"
                border
              />
              <instructions-card
                v-if="!preLoading"
                :work-order="workOrder"
                @change="updateInstructions"
                ref="instructionsCard"
                class="mt-2"
                border
              />
              <dates-and-times-card
                v-if="!preLoading"
                :work-order="workOrder"
                @change="updateDatesAndTimes"
                ref="datesAndTimesCard"
                class="mt-2"
                border
              />
            </v-col>
            <v-col sm="6" >
              <employees-card
                v-if="!preLoading"
                :work-order="workOrder"
                @change="updateEmployees"
                @show-contact-dialog="showCreateClientContactDialog"
                ref="employeesCard"
                border
              />
              <attachments-card
                v-if="!preLoading"
                :work-order="workOrder"
                @change="updateAttachments"
                ref="attachmentsCard"
                class="mt-2"
                border
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <suggested-equipment-card
                v-if="!preLoading && workOrder.id"
                :work-order="workOrder"
                @change="updateEquipment"
                ref="suggestedEquipmentCard"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="2" class="sidebar">
          <actions-card
            v-if="!preLoading"
            :work-order="workOrder"
            :loading="preLoading"
            :valid="valid"
            :hasChanges="hasChanges || suggestedEquipmentHasChanges"
            @change="updateStatus"
            @reset="resetWorkOrder"
            border
          />
          <status-card
            v-if="!preLoading"
            :work-order="workOrder"
            @change="updateStatus"
            ref="statusCard"
            class="mt-2"
            border
          />
          <revisions-card
            v-if="!preLoading"
            :work-order="workOrder"
            class="mt-2"
            border
          />
        </v-col>
      </v-row>
    </v-form>

    <!-- MODALS -->
    <!-- Add client dialog -->
    <v-dialog v-if="dialog.showClientDialog" v-model="dialog.showClientDialog" max-width="500px" content-class="client-dialog" ref="clientDialog">
      <client-form
        class="client-form"
        :client="newClient"
        :onInput="() => {}"
        :is-dialog="true"
        :submit="createClientLocal"
        ref="clientForm"
        @close="update('showClientDialog', $event)"
      />
    </v-dialog>

    <!-- Add client location dialog -->
    <v-dialog v-if="dialog.showClientLocationDialog" v-model="dialog.showClientLocationDialog" max-width="1000px" content-class="client-location-dialog" ref="clientLocationDialog">
      <client-location-card
        :clientId="workOrder.client_id"
        @created="afterCreateLocation"
        :close="close"
        ref="clientLocationCard"
        :onInput="() => {}"
        @close="update('showClientLocationDialog', $event)"
      />
    </v-dialog>

    <!-- Add client contact dialog -->
    <v-dialog v-if="dialog.showClientContactDialog" v-model="dialog.showClientContactDialog" max-width="500px" content-class="client-contact-dialog" ref="clientContactDialog">
      <client-contact-card
        :clientId="workOrder.client_id"
        :client-contact="clientContactEditing"
        @created="afterCreateClientContact"
        ref="clientContactCard"
        @close="update('showClientContactDialog', $event)"
      />
    </v-dialog>

  </div>
</template>

<script setup lang="ts">
import PrimaryDetailsCard from '../cards/PrimaryDetailsCard.vue'
import InstructionsCard from '../cards/InstructionsCard.vue'
import DatesAndTimesCard from '../cards/DatesAndTimesCard.vue'
import EmployeesCard from '../cards/EmployeesCard.vue'
import AttachmentsCard from '../cards/AttachmentsCard.vue'
import SuggestedEquipmentCard from '../cards/SuggestedEquipmentCard.vue'
import ActionsCard from '../cards/ActionsCard.vue'
import StatusCard from '../cards/StatusCard.vue'
import RevisionsCard from '../cards/RevisionsCard.vue'

// components for dialogs
import ClientForm from '@/components/Clients/Form.vue'
import ClientLocationCard from '@/components/Clients/LocationCard.vue'
import ClientContactCard from '@/components/Clients/ContactCard.vue'
import { computed, inject, nextTick, onBeforeUnmount, onMounted, provide, ref } from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'
import { createClient } from '@/api/clients'
import { createWorkOrder, updateWorkOrder } from '@/api/workOrders'
import { dateAndTimeToDatetime } from '@/helpers'
import router from '@/router'
import store from '@/store'
import PreventNavigation from '@/utils/PreventNavigation'
import Say from '@/utils/Say'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import emitArray from '@/utils/emit'
import { reactive } from 'vue'
import sayErrorResponse from '@/mixins/sayErrorResponse'

import useBranchSelector from '@/composable/useBranchSelector'
import { watch } from 'vue'
import {useEventBus} from '@vueuse/core'
const createdBus = useEventBus('created');

const { branchSelected } = useBranchSelector()

const emit = defineEmits(emitArray.value)
// Inject from parent
const workOrder: any = inject('workOrder')

// Provide for children
provide('workOrder', workOrder)

const originalWorkOrder= ref(null as any)
const newClient= ref({})
const formValid= ref(false)
const preLoading= ref(true)

const dialog = reactive({
  showClientDialog: false,
  showClientLocationDialog: false,
  showClientContactDialog: false
})
const clientContactEditing = ref(null)

const primaryDetailsCard = ref()
const instructionsCard = ref()
const datesAndTimesCard = ref()
const employeesCard = ref()
const statusCard = ref()
const clientForm = ref()
const clientLocationCard = ref()
const clientContactCard = ref()
const suggestedEquipmentCard = ref()
const suggestedEquipmentHasChanges = ref(false)
const route = useRoute()
const {t} = useI18n()

const props = defineProps({
  page: {
    type: String,
    required: true
  }
})

watch(() => branchSelected.value, async() => {
  if (props.page === 'create' && workOrder.value) {
    workOrder.value.location_va = vanAmerongenLocations.value[Number(branchSelected.value as any) - 1]
    workOrder.value.branch_id = branchSelected.value
  }
})

watch(() => dialog.showClientContactDialog, async(newValue) => {
  if (!newValue) {
    clientContactEditing.value = null
  }
})

const valid = computed(() => {
  const hasClient = !!workOrder.value.client_id
  const hasLocation = !!workOrder.value.location
  const hasLocationVa = !!workOrder.value.location_va
  const hasInstructions = !!workOrder.value.instructions
  const hasCategory = !!workOrder.value.category
  const hasStatus = !!workOrder.value.status
  const hasStartDate = !!workOrder.value.date_start
  const hasStartTime = !!workOrder.value.time_start

  if(workOrder.value.is_for_equipment_movement) {
    return formValid && hasInstructions && hasCategory &&
      hasStatus && hasStartDate && hasStartTime
  } else {
    return formValid && hasClient && hasLocation && hasInstructions && hasCategory &&
      hasStatus && hasStartDate && hasStartTime
  }
})

const hasChanges = computed(() => {
  if (!isEqual(originalWorkOrder.value, workOrder.value)) {
    return true
  }
  return false
})

const vanAmerongenLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const rentalSuppliersLocations = computed(() => store.getters['cache.rentalSuppliersLocations'])

onMounted (async() => {
  // fetch the location of Van Amerongen (vestigingen) and clients
  // this data is cached in the cache store
  await Promise.all([
    maybeFetchEmployees(),
    maybeFetchClients(),
    maybeFetchVanAmerongenLocations(),
    maybeFetchRentalSuppliersLocations()
  ])

  const job = ref(null as any)
  if (route.params.job_id) {
    job.value = await store.dispatch('jobs.fetchSingle', route.params.job_id)
  }

  // see if we are in edit or create mode
  if (route.params && route.params.id) {
    // UPDATE MODE

    await store.dispatch('workOrders.fetchSingleForEdit', route.params.id)

    store.commit('app.setPageTitle', t('workOrderEdit') + ' ' + workOrder.value.standard_name_prefix)
    store.commit('app.setBreadcrumbs', [
      { text: t('workOrder', 2), routeName: 'WorkOrdersList' },
      { text: t('workOrderEdit') }
    ])
    store.commit('app.setToggleButton', {
      route: { name: 'WorkOrdersView', id: route.params.id },
      text: t('see')
    })

    nextTick(() => {
      originalWorkOrder.value = cloneDeep(workOrder.value)
    })
  } else {
    // CREATE MODE (2 modes; one creating from job and one creating directly)
    store.commit('app.setPageTitle', t('workOrderCreate'))
    store.commit('app.setBreadcrumbs', [
      { text: t('workOrder', 2), routeName: 'WorkOrdersList' },
      { text: t('workOrderCreate') }
    ])

    // get an empty template of a wordOrder from the store
    const workOrderTemplate = store.getters.workOrdersCreate
    workOrderTemplate.status = 'PLANNED'
    workOrderTemplate.location_va = vanAmerongenLocations.value[Number(branchSelected.value as any) - 1]
    workOrderTemplate.branch_id = branchSelected.value

    workOrder.value = cloneDeep(workOrderTemplate)

    if (job.value) {
      // created from job; prefill all fields
      workOrder.value.job_id = job.value.id
      workOrder.value.branch_id = job.value.branch_id
      workOrder.value.project_name = job.value.job_description
      workOrder.value.category = job.value.category
      workOrder.value.instructions = job.value.instructions
      // client
      workOrder.value.client_id = job.value.client_id
      workOrder.value.client_ref = job.value.client_ref
      workOrder.value.client = job.value.client
      // locations
      workOrder.value.location = job.value.location
      workOrder.value.location_va = job.value.location_va
      // start and end dates
      workOrder.value.date_start = job.value.date_start
      workOrder.value.time_start = job.value.time_start
      workOrder.value.date_end = job.value.date_end
      workOrder.value.time_end = job.value.time_end
      // people
      workOrder.value.employee_id = job.value.responsible ? job.value.responsible.id : null
      workOrder.value.responsible = job.value.responsible ? job.value.responsible : {}
      workOrder.value.client_responsible_1_id = job.value.client_responsible_1 ? job.value.client_responsible_1.id : null
      workOrder.value.client_responsible_1 = job.value.client_responsible_1 ? job.value.client_responsible_1: {}
    }

    originalWorkOrder.value = cloneDeep(workOrder.value)

    if (job.value) {
      // job itself (trigger a change)
      workOrder.value.job = job.value
    }
  }

  preLoading.value = false

  // Bind browser tab navigation confirmation
  nextTick(() => {
    PreventNavigation.inBrowser(originalWorkOrder.value, workOrder.value)
  })
})
onBeforeUnmount(() => {
  store.commit('app.clearToggleButton')
  store.dispatch('cache.vanAmerongenLocations.fetch')
})

// Bind app route navigation confirmation
onBeforeRouteLeave ((to, from, next) => {
  PreventNavigation.inVueRouter(originalWorkOrder.value, workOrder.value, next)
})
const resetWorkOrder = () => {

  nextTick(() => {
    primaryDetailsCard.value.initializeInputs()
    instructionsCard.value.initializeInputs()
    datesAndTimesCard.value.initializeInputs()
    employeesCard.value.initializeInputs()
    statusCard.value.initializeInputs()
    workOrder.value = cloneDeep(originalWorkOrder.value)
  })
}

const maybeFetchEmployees = async() => {
  return store.dispatch('cache.employees.fetch')
}
const maybeFetchClients = async() => {
  return store.dispatch('cache.clients.fetch')
}
const maybeFetchVanAmerongenLocations = async() => {
  return store.dispatch('cache.vanAmerongenLocations.fetch')
}
const maybeFetchRentalSuppliersLocations = async() => {
  return store.dispatch('cache.rentalSuppliersLocations.fetch')
}
const showCreateClientDialog = () => {
  const clientTemplate = cloneDeep(store.getters.clientsCreate)
  newClient.value = clientTemplate
  dialog.showClientDialog = true
  nextTick(() => {
    clientForm.value.$refs.nameInput.focus()
  })
}
const showCreateClientLocationDialog = () => {
  if (workOrder.value.client_id) {
    dialog.showClientLocationDialog = true
    nextTick(() => {
      clientLocationCard.value.$refs.searchLocationInput.focus()
    })
  }
}
const showCreateClientContactDialog = (clientResponsibleId = null) => {
  if (workOrder.value.client_id) {
    if (clientResponsibleId) {
      clientContactEditing.value = clientResponsibleId
    }
    dialog.showClientContactDialog = true
    nextTick(() => {
      clientContactCard.value.$refs.nameInput.focus()
    })
  }
}
// this function is called BEFORE creating the client
// in the database, in the create client dialog
const createClientLocal = async(client: any) => {
  // create client
  const response: Record<string, any> = await createClient(client)
  if (!response.data) return

  const newClient = response.data

  // update cache
  store.commit('cache.clients.add', newClient)

  // I am emitting a separate event here
  // This problem with the event being emitted by client-form is that there is no waiting for this method to finish
  // and that the even is emitted before the save to API is done, and therefore the id is NULL on the emitted new client.
  // TODO: perhaps resolve this in a better way
  createdBus.emit({ type: 'newClient', data: newClient })

  // set client on workOrder, workOrder.client_id is watched
  // on the PrimaryDetailsCard, which will update locations etc
  //  - There was a watcher on this, but there was also a listener on the created event, the combination caused a problem.
  workOrder.value.client_id = newClient.id
  workOrder.value.client = newClient

  // close modal
  dialog.showClientDialog = false
}
// this function is called AFTER a new location has been created
// in the create location dialog
const afterCreateLocation = (location: any) => {
  if (!location) return

  // update the cache
  const client = store.getters['cache.clients'].find((client: any) => client.id === workOrder.value.client_id)
  client.locations.push(location)
  store.commit('cache.clients.update', client)

  // update location on workOrder.value, workOrder.value.location is watched
  // on the PrimaryDetailsCard, which will update the locations etc
  workOrder.value['location'] = location

  // close modal
  dialog.showClientLocationDialog = false

  // set focus to the next field
  nextTick(() => {
    primaryDetailsCard.value.$refs.clientReferenceInput.focus()
  })
}
// this function is called AFTER a new contact has been created
// in the create location dialog
const afterCreateClientContact = async(contact: any) => {
  if (!location) return

  await employeesCard.value.fetchClientContacts({
    clientId: workOrder.value.client_id
  })
  nextTick(() => {
    employeesCard.value.setResponsibleId(contact.id)
    // close modal
    dialog.showClientContactDialog = false
  })
}
const updatePrimaryDetails = (data: any) => {
  // console.log('Updating primary details %o', data)
  if (data.jobId !== undefined) {
    workOrder.value['job_id'] = data.jobId
  }
  if (data.clientId !== undefined) {
    if(workOrder.value['client_id'] !== data.clientId) {
      employeesCard.value.setResponsibleId(null)
    }
    workOrder.value['client_id'] = data.clientId
  }
  if (data.locationTo !== undefined) {
    workOrder.value['location'] = data.locationTo
  }
  if (data.clientRef !== undefined) {
    workOrder.value['client_ref'] = data.clientRef
  }
  if (data.category !== undefined) {
    workOrder.value['category'] = data.category
  }
  if (data.isForEquipmentMovement !== undefined) {
    workOrder.value['is_for_equipment_movement'] = data.isForEquipmentMovement
    if (workOrder.value['is_for_equipment_movement']) {
      workOrder.value['client_ref'] = null
    }
  }
}
const updateInstructions = (data: any) => {
  // console.log('Updating instructions %o', data)
  if (data.instructions !== undefined) {
    workOrder.value['instructions'] = data.instructions
  }
}
const updateDatesAndTimes =  (data: any) => {
  let value = data.value

  if (data.value !== undefined) {
    switch (data.name) {
      case 'date_start':
      case 'date_end':
        value = data.value ? moment(data.value).format('YYYY-MM-DD') : null
      break;
      case 'time_start':
      case 'time_end':
        value = data.value ? `${(data.value.hours).toString().padStart(2, "0")}:${(data.value.minutes).toString().padStart(2, "0")}` : null
      break;
    }

    workOrder.value[`${data.name}`] = value
  }
}
const updateEmployees = (data: any) => {
  // console.log('Updating employees %o', data)
  if (data.employeeId !== undefined) {
    workOrder.value['employee_id'] = data.employeeId
  }
  if (data.employees !== undefined) {
    workOrder.value['employees'] = data.employees
  }
  if (data.clientResponsibleId !== undefined && Number.isInteger(data.clientResponsibleId)) {
    workOrder.value['client_responsible_1_id'] = data.clientResponsibleId
  }
  // doublecheck for reset
  if (data.clientResponsibleId === null) {
    workOrder.value['client_responsible_1_id'] = data.clientResponsibleId
  }
}
const updateAttachments = (data: any) => {
  // console.log('Updating attachments %o', data)
  if (data.files !== undefined) {
    workOrder.value['files'] = data.files
  }
}
const updateEquipment = (data: any) => {
  // console.log('Updating suggestedEquipment %o', data)
  if (workOrder.value.suggestedEquipment === undefined || data.equipment.toString() !== workOrder.value.suggestedEquipment.toString()) {
    suggestedEquipmentHasChanges.value = true
  }
  workOrder.value['suggestedEquipment'] = data.equipment ?? []
}
const updateStatus = (data: any) => {
  // console.log('Updating status %o', data)
  if (data.status !== undefined) {
    workOrder.value['status'] = data.status
  }
}

const saveEquipment = async() => {
  await suggestedEquipmentCard.value.save()
}

// create workOrder in the database
const saveWorkOrder = async() => {
  const workOrderLocal = cloneDeep(workOrder.value)
  workOrderLocal.datetime_start = dateAndTimeToDatetime(workOrderLocal.date_start, workOrderLocal.time_start)
  workOrderLocal.datetime_end = dateAndTimeToDatetime(workOrderLocal.date_end, workOrderLocal.time_end)
  workOrderLocal.files = workOrderLocal.files.map((file: any) => {
    return file.id
  })
  // Ensure the location is the id
  if (workOrderLocal.location instanceof Object && workOrderLocal.location.id !== undefined) {
    workOrderLocal.location = workOrderLocal.location.id
  }
  // Ensure the Van Amerongen location is the id
  if (workOrderLocal.location_va instanceof Object && workOrderLocal.location_va.id !== undefined) {
    workOrderLocal.location_va = workOrderLocal.location_va.id
  }
  // Data which is not to be sent
  const attributesToDelete = [
    'selectedEmployee', 'selectedEmployees', 'selectedResponsible', 'revisions', 'created_by',
    'client', 'employee', 'responsible', 'status_ongoing'
  ]

  attributesToDelete.forEach(attribute => {
    delete workOrderLocal[attribute]
  })

  if (props.page === 'create') {
    try {
      const response = await createWorkOrder(workOrderLocal)
      const newWorkOrder = response.data

      // add suggestedEquipment because it otherwise gets added *somewhere*
      newWorkOrder.suggestedEquipment = []

      store.commit('workOrders.addToList', newWorkOrder)
      Say('success', t('workOrderCreateSuccess'))

      // set workOrder and originalWorkorder to the one we received from the api
      originalWorkOrder.value = cloneDeep(newWorkOrder)
      workOrder.value = cloneDeep(newWorkOrder)

      await router.push({name: 'WorkOrdersEdit', params: {id: newWorkOrder.id}})
    } catch (err: any) {
      sayErrorResponse(err)
    }
  } else if (props.page === 'edit') {
    try {
      const response = await updateWorkOrder(workOrderLocal.id, workOrderLocal)

      workOrder.revisions =  response.data.revisions

      // make a clone to prevent unsaved changes nag from triggering
      nextTick(() => {
        originalWorkOrder.value = cloneDeep(workOrder.value)
      })
      Say('success', t('workOrderEditSuccess'))

      // TODO: Figure out why this only works when it is at the bottom
      await saveEquipment()
    } catch (err: any) {
      sayErrorResponse(err)
    }
  }

}

const close = () => {
  workOrder.value = false
}

const update = (name: any, value: Boolean) => {
  const dialogLocal: {[key: string]: any} = dialog
  dialogLocal[name] = value
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}

.client-dialog .client-form {
  .contacts-projects {
    display: none;
  }

  .flex {
    max-width: 100% !important;
    flex-basis: 100%;
  }
}
</style>
