<script setup lang="ts">
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const emit = defineEmits(['trigger'])

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  checkoutPeriod: {
    type: Object,
    required: false
  },
  lastInvoiceIsRejectedProForma: {
    type: Boolean,
    required: false
  },
  lastInvoiceIsCreditNote: {
    type: Boolean,
    required: false
  }
})

const emitTrigger = (action) => {
  emit('trigger', {
    invoice: props.invoice,
    action: action,
    checkoutPeriod: props.checkoutPeriod,
  })
}

</script>

<template>
  <template v-if="invoice.type === 'pro_forma' || !invoice.type">
    <template v-if="!invoice.status || lastInvoiceIsRejectedProForma">
      <span @click="emitTrigger('openCreateDialog')" class="btn">{{ t('createProforma') }}</span>
    </template>
    <template v-else>
      <template v-if="invoice.status === 'PENDING'">
        <span @click="emitTrigger('openApproveDialog')" class="btn btn-green">{{ t('approve') }}</span>
        <span @click="emitTrigger('openRejectDialog')" class="btn btn-red">{{ t('reject') }}</span>
      </template>
      <template v-if="invoice.status === 'APPROVED'">
        <span @click="emitTrigger('openConvertToSalesInvoiceDialog')" class="btn btn-blue">{{ t('toInvoice') }}</span>
      </template>
      <template v-if="invoice.status === 'CONCEPT'">
        <span @click="emitTrigger('openEditView')" class="btn btn-blue">{{ t('edit') }}</span>
      </template>
    </template>
  </template>
  <template v-else-if="invoice.type === 'sales_invoice' && invoice.credit_note_count === 0">
    <template v-if="invoice.exact_online_attachment_invoice_with_attachments_id !== null">
      <span @click="emitTrigger('openCreditSalesInvoiceView')" class="btn btn-red">{{ t('toCredit') }}</span>
    </template>
  </template>
  <template v-else-if="invoice.type === 'credit_note' && lastInvoiceIsCreditNote">
    <span @click="emitTrigger('openCreateDialog')" class="btn">{{ t('createProforma') }}</span>
  </template>
</template>

<style scoped lang="scss">
.btn {
  height: auto;
  margin-right: 16px;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}
.btn-red {
  color: rgb(255, 0, 0);
}
.btn-blue {
  color: rgb(30, 136, 229);
}
.btn-green {
  color: rgb(0, 150, 0);
}
</style>
