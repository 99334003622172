<template>
  <v-container grid-lg class="pa-0 work-orders-view">
    <v-progress-circular indeterminate color="primary" v-show="loading" :size="70" :width="4"></v-progress-circular>
    <v-row v-if="!loading">
      <v-col sm="4" >

        <!-- Details -->
        <Card :title="t('details')" card-text-class="pa-0">
          <template v-slot:content>
            <InfoList two-line>
              <template v-slot:items>

                <!-- Branch -->
                <InfoListItem
                  icon="mdi-home-variant"
                  :title="job.location_va.city"
                  :subtitle="ucfirst(t('branch'))"
                />

                <!-- Opdracht -->
                <InfoListItem
                  v-if="job.job_name"
                  icon="mdi-clipboard"
                  :title="job.job_name"
                  :subtitle="ucfirst(t('job'))"
                />

                <!-- Omschrijving -->
                <InfoListItem
                  v-if="job.job_description"
                  icon="mdi-clipboard-text"
                  :title="job.job_description"
                  :subtitle="ucfirst(t('description'))"
                />

                <!-- Status -->
                <InfoListItem
                  :title="getJobStatusName(job)"
                  :subtitle="ucfirst(t('status'))"
                  icon="mdi-wrench"
                />

                <!-- Client -->
                <InfoListItem
                  v-if="job.client"
                  icon="mdi-domain"
                  :title="job.client.name"
                  :subtitle="ucfirst(t('client'))"
                  :routeLink="{ name: 'ClientsEdit', params: { id: job.client.id } }"
                />

                <!-- Client Ref -->
                <InfoListItem
                  v-if="job.client_ref"
                  icon="mdi-tag"
                  :title="job.client_ref"
                  :subtitle="ucfirst(t('clientReference'))"
                  :divider="true"
                />

                <!-- Use Old Invoicing -->
                <InfoListItem
                  icon="mdi-currency-eur"
                  :subtitle="t('useOldInvoicingMethod')"
                  :title="job.use_old_invoicing ? t('yes') : t('no')"
                  :divider="false"
                />
              </template>
            </InfoList>

          </template>
        </Card>

        <!-- Location and instructions -->
        <Card :title="`${t('location', 1)} & ${t('instructions')}`" card-text-class="pa-0">
          <template v-slot:content>

            <InfoList two-line>
              <template v-slot:items>

                <!-- To -->
                <InfoListItem
                  title=""
                  subtitle=""
                  icon="mdi-map-marker"
                  :divider="!!job.instructions"
                >
                  <template v-slot:content>
                    {{ getLocationAddress(job.location) }}
                  </template>
                </InfoListItem>

                <!-- Instructions -->
                <InfoListItem
                  v-if="job.instructions"
                  title=""
                  subtitle=""
                  :divider="false"
                  :avatar="false"
                >
                  <template v-slot:content>
                    <span v-html="job.instructions.replace(/\n/g, '<br>')"></span>
                  </template>
                </InfoListItem>

              </template>

            </InfoList>
          </template>
        </Card>
      </v-col>

      <v-col sm="4" >

        <!-- Location and instructions -->
        <Card :title="t('employee', 2)" card-text-class="pa-0">
          <template v-slot:content>

            <InfoList two-line>
              <template v-slot:items>

                <!-- Responsible -->
                <InfoListItem
                  v-if="job.responsible"
                  :title="job.responsible.name"
                  :subtitle="ucfirst(t('responsible'))"
                  icon="mdi-face-man"
                />

                <!-- Assigned employees -->
                <InfoListItem
                  v-if="job.selectedEmployees && job.selectedEmployees.length > 0"
                  :title="job.selectedEmployees.map((employee: any) => employee.name).join(', ')"
                  :subtitle="ucfirst(t('assignedEmployees'))"
                  icon="mdi-account-multiple"
                />

                <!-- Uitvoerders -->
                <InfoListItem
                  :title="uitvoerders"
                  :subtitle="ucfirst(t('clientResponsible'))"
                  icon="mdi-account-multiple-outline"
                  :divider="false"
                />

              </template>
            </InfoList>
          </template>
        </Card>

      </v-col>

      <v-col sm="4" >
        <revisions-card
          :job="job"
          v-if="job.revisions"
          border
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

import { fetchEmployees } from '@/api/employees'

import Card from '@/components/UI/Card.vue'
import Dialog from '@/components/Dialog'
import InfoList from '@/components/UI/InfoList.vue'
import InfoListItem from '@/components/UI/InfoListItem.vue'

import RevisionsCard from '../cards/RevisionsCard.vue'

import { defineComponent, nextTick, ref, watch, onBeforeUnmount, computed, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { shortDateFormat } from '@/utils/filters'
import { ucfirst } from '@/utils/filters'

defineComponent({
  Card,
  Dialog,
  InfoList,
  InfoListItem,
  RevisionsCard,
})

const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const jobId = computed(() => {
  return route.params.id
})

const job: any = computed(() => {
  return store.getters.jobsEdit
})

const uitvoerders = computed(() => {
  return [1, 2]
    .filter((index) => {
      return job.value['client_responsible_' + index] !== null
    })
    .map((index) => {
      return job.value['client_responsible_' + index].name
    })
    .join(', ')
})
const loading = ref(false)
const employees = ref([] as any)

watch(route , (value: any) => {
  if (route.name === 'WorkOrdersView') {
    // open workorder from jobs page
    return
  }
  if (value?.params?.id) {
    initialize()
    nextTick(() => {
      store.commit('app.setToggleButton', {
        route: { name: 'JobsEdit', id: jobId.value },
        text: t('edit')
      })
    })
  }
}, { deep: true })

onBeforeMount(() => {
  initialize()
})

onMounted(() => {
  store.commit('app.setToggleButton', {
    route: { name: 'JobsEdit', id: jobId.value },
    text: t('edit')
  })
})

onBeforeUnmount(() => {
  store.commit('app.clearToggleButton')
})

const initialize = () => {
  loading.value = true
  // Set the initial state so we have what to reset to
  store.dispatch('jobs.fetchSingleForEdit', jobId.value)
    .then(() => {
      return fetchEmployees({ rowsPerPage: -1 })
    })
    .then(({data: {data: data}}) => {
      employees.value = data

      loading.value = false
    })
}

const getNoteSubtitle = (note: any) => {
  const [date, time] = note.updated_at.split(' ')
  const at = t('at')
  const by = t('by')

  return `${date} ${at} ${time} ${by} ${note.name}`
}

const getImagePath = (image: any) => {
  return `/storage/jobNotes/${image}`
}

const getJobStatusName = (job: any) => {
  let status = t('jobStatuses.' + job.status)

  if (job.status === 'ONGOING' && job.status_ongoing) {
    status += ` - ${t('jobStatusesOngoing.' + job.status_ongoing.name)}`
  }

  return status
}

const getLocationAddress = (location: any) => {
  return location ? location.location : ''
}

const randomId = () => {
  return Math.floor(Math.random() * 1000000)
}
</script>

<style lang="scss" scoped>
.work-orders-view {
  h2 {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: normal;
    line-height: 32px;
    font-size: 16px;
    a, strong {
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }
  }

  hr {
    margin: 20px 0;
  }
  .v-card {
    margin-bottom: 20px;
  }

  .card {
    margin-bottom: 20px;
  }

  & .card__text {
    padding: 0!important;
  }

  .table-files {
    tbody {
      tr {
        td {
          padding-top: 10px;
          padding-bottom: 10px;

          &:first-child {
            width: 100px;
          }

          .preview {
            height: 48px;
            width: auto;
          }
        }
      }
    }
  }

  .table-equipment {
    margin-top: 10px;

    thead tr {
      height: auto;
    }

    thead tr th, tbody tr td {
      padding: 5px 10px
    }
  }

  .note {
    .downloads {
      img {
        max-width: 25%;
      }
    }
  }

  .add {
    cursor: pointer;

    &:hover {
      color: green;
    }
  }
}
</style>
