<template>
  <v-card flat>
    <v-card-text>
      <!-- Add users -->
      <v-autocomplete
        :label="$t('assignedEmployees')"
        :loading="isLoading"
        cache-items
        clearable
        chips
        deletable-chips
        multiple
        v-model="employees"
        :items="employeesItems"
        :search-input.sync="employeesSearch"
        item-value="id"
        item-title="name"
        @update:model-value="nextTick(() => emit('change', { employees: $event }))"
        variant="underlined"
      ></v-autocomplete>

      <!-- Responsible / Uitvoerder -->
      <v-autocomplete
        :label="$t('responsible')"

        :loading="isLoading"
        cache-items
        clearable
        v-model="responsibleId"
        :items="responsibleItems"
        :search-input.sync="responsibleSearch"
        item-value="id"
        item-title="name"
        @update:model-value="$nextTick(() => $emit('change', { employeeId: $event }))"
        variant="underlined"
      ></v-autocomplete>

      <v-autocomplete
        :label="$t('clientResponsible')"

        :loading="isLoading || isLoadingClientContacts"
        clearable
        v-model="clientResponsibleId"
        :items="clientResponsibleItems"
        :search-input.sync="clientResponsibleSearch"
        item-value="id"
        item-title="name"
        v-if="workOrder.client_id"
        variant="underlined"
      >
        <template v-slot:prepend>
          <v-icon
            v-tooltip="$t('add')"
            @click="$emit('show-contact-dialog')">mdi-plus</v-icon>
          <v-icon
            v-if="clientResponsibleId"
            icon="mdi-pencil"
            v-tooltip="$t('edit')"
            class="ml-2"
            @click="$emit('show-contact-dialog', clientResponsibleId)"
          />
          <v-icon v-else icon="mdi:void" class="ml-2" />
        </template>
      </v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { fetchClientContacts } from '@/api/clients'
import store from '@/store';
import emitArray from '@/utils/emit';
import { ref, onMounted, watch, computed, nextTick, onBeforeUnmount } from 'vue';
import { cloneDeep } from 'lodash-es';

import { useEventBus } from '@vueuse/core'
const createdBus = useEventBus('created-employees');
const updateBus = useEventBus('updated-employees');

const emit = defineEmits(emitArray.value)
const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  }
})

const employees = ref([])
const employeesSearch = ref('')
const responsibleId = ref(null)
const responsibleSearch = ref('')
const clientResponsibleId = ref(null)
const clientResponsibleItems = ref([] as any[])
const clientResponsibleSearch = ref('')
const isLoading = ref(true)
const isLoadingClientContacts = ref(true)
const originalWorkOrder = ref(null as any)

const employeesItems = computed(() => store.getters['cache.employees'])
const clients = computed(() => store.getters['cache.clients'])

const responsibleItems = computed(() => {
  return employeesItems.value.filter((employee: any) => employee.responsible === 1)
})

watch(
  () => props.workOrder.client_id,
  () => {
    fetchClientContactsLocal()
  }
)

createdBus.on(({ type, data }: any) => {
  if (type === 'executor') {
    clientResponsibleItems.value.push(data)
    clientResponsibleId.value = data.id
  }
})
updateBus.on(({ type, data }: any) => {
  if (type === 'executor') {
    clientResponsibleItems.value.forEach(obj => {
      if (obj.id == data.id) {
        obj.name = data.name
      }
    });
    clientResponsibleId.value = null
    nextTick(() => {
      clientResponsibleId.value = data.id
    })
  }
})


onBeforeUnmount(() => {
  createdBus.reset()
})

onMounted (async() => {
  fetchClientContactsLocal()
  originalWorkOrder.value = cloneDeep(props.workOrder)
  initializeInputs()
  isLoading.value = false

})

const fetchClientContactsLocal = async() => {
  if (props.workOrder.client_id) {
    isLoadingClientContacts.value = true
    const response = await fetchClientContacts({ clientId: props.workOrder.client_id })
    clientResponsibleItems.value = response.data || []
    isLoadingClientContacts.value = false
  } else {
    clientResponsibleItems.value = []
  }
}

const setResponsibleId = (contactId: any) => {
  clientResponsibleId.value = contactId
}

const initializeInputs = () => {
  nextTick(() => {
    employees.value = originalWorkOrder.value.employees ? originalWorkOrder.value.employees : []
    responsibleId.value = originalWorkOrder.value.responsible ? originalWorkOrder.value.responsible.id : null
    clientResponsibleId.value = originalWorkOrder.value.client_responsible_1 ? originalWorkOrder.value.client_responsible_1.id : null
  })
}

watch(
  () => clientResponsibleId.value,
  () => emit('change', { clientResponsibleId: clientResponsibleId.value })
)

defineExpose({
  initializeInputs,
  fetchClientContacts,
  setResponsibleId,
});
</script>
