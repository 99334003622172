import axios from "axios";

class JobAdditionalCostsAPI {

  jobId: string | undefined;

  setJobId(jobId: int | string) {
    this.jobId = jobId
  }

  /**
   * Show a list of the equipment items that are attached to this job that are attached do this
   * @param filters
   */
  index(filters: any = null) {
    return axios.get(`/jobs/${this.jobId}/additional-costs`)
  }

  /**
   * Move an equipment item to a location
   * @param data
   */
  store(data: object) {
    return axios.post(`/jobs/${this.jobId}/additional-costs`, data)
  }
  update(id: number | string, data: object) {
    return axios.put(`/jobs/${this.jobId}/additional-costs/${id}`, data)
  }

  delete(id: number) {
    return axios.delete(`/jobs/${this.jobId}/additional-costs/${id}`)
  }
}
export const jobAdditionalCostsAPI = new JobAdditionalCostsAPI()
