<template>
  <div>
    <v-row>
      <v-col sm="6" >
        <v-form v-on:submit.prevent="submitFom()" v-model="valid" ref="form">
          <v-card flat :border="true">
            <v-card-text>
              <v-text-field
                :label="t('name', 1)"
                v-model="client.name"
                required
                :rules="rules.name"
                ref="nameInput"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                :label="t('postcode', 1)"
                v-model="client.postcode"
                :rules="rules.postcode"
                @input="onInput('postcode', $event.target.value)"
                mask="#### AA"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                :label="t('city', 1)"
                v-model="client.city"
                @input="onInput('city', $event.target.value)"
                variant="underlined"
              ></v-text-field>

              <v-text-field
                :label="t('address', 1)"
                v-model="client.address"
                @input="onInput('address', $event.target.value)"
                variant="underlined"
              ></v-text-field>

              <v-textarea
                :label="t('comment', 2)"
                v-model="client.comments"
                rows="2"
                auto-grow
                hide-details
                @input="onInput('comments', $event.target.value)"
                variant="outlined"
              ></v-textarea>

              <v-autocomplete
                :label="t('exactOnlineAccount', 1)"
                v-model="client.exact_online_account_id"
                :items="exactOnlineAccounts"
                item-title="description"
                item-value="id"
                @input="onInput('exact_online_account', $event)"
                variant="underlined"
                class="mt-5"
                clearable
              ></v-autocomplete>

            </v-card-text>
            <v-card-actions>
              <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" :disabled="!valid">
                {{ buttonText }}
              </v-btn>
              <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
              <v-btn v-if="isDialog" color="primary" flat @click="$emit('close', false)">
                {{ $t('cancel') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>

      <v-col sm="6" class="contacts-projects">

        <!-- Contacts -->
        <v-card v-if="!isDialog" flat :border="true">

          <!-- Contact add / edit dialog -->
          <v-dialog v-model="contactDialogShow" max-width="500px">
            <v-card>
              <v-card-title>
                <h2>{{ ucfirst(t(contactMethod)) }} contact</h2>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  :label="t('name')"
                  v-model="contact.name"
                  :rules="rules.name"
                  variant="underlined"
                />
                <v-text-field
                  :label="t('phone')"
                  v-model="contact.phone"
                  variant="underlined"
                />
                <v-text-field
                  :label="t('email')"
                  :rules="rules.contact.email"
                  v-model="contact.email"
                  variant="underlined"
                />
                <v-text-field
                  :label="t('function')"
                  v-model="contact.function"
                  variant="underlined"
                />
              </v-card-text>
              <v-card-actions>
                <v-progress-circular indeterminate color="orange-darken-2" v-show="contactLoading"></v-progress-circular>
                <div v-if="!contactLoading">
                  <v-btn color="orange-darken-2" depressed :disabled="validateForm()" @click.stop="triggerContactSave()">
                    {{ t(contactMethod) }}
                  </v-btn>
                  <v-btn color="primary" flat @click.stop="contactDialogShow = false">
                    {{ t('cancel') }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-toolbar class="custom-bg" density="compact">
            <v-toolbar-title>
              <v-btn icon flat color="green" @click="triggerAddContactOpen">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
              Contacts
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
          <span v-if="client.contacts && client.contacts.length === 0">
            No contacts.
          </span>
            <v-table
              v-if="client.contacts && client.contacts.length > 0"
              height="355"
            >
              <tbody>
              <tr v-for="contact in client.contacts" :key="contact.id">
                <td>{{ contact.name }}</td>
                <td>{{ contact.phone }}</td>
                <td>{{ contact.email }}</td>
                <td>
                  <v-btn size="small" icon flat  @click="triggerEditContactOpen(contact)">
                    <v-icon color="grey-darken-5">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn size="small" icon flat @click="deleteClientContactLocal(contact.id)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <EquipmentAtClientLocations
          v-if="client.id"
          :client="client"
          class="locations-equipment"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { createClientContact, updateClientContact, deleteClientContact, getExactOnlineAccounts } from '@/api/clients'
import validationRules from '@/validations/clients'
import { ucfirst } from '@/utils/filters'
import sayErrorResponse from '@/mixins/sayErrorResponse'

import Say from '@/utils/Say'
import { defineComponent, onMounted, ref } from 'vue';
import emitArray from '@/utils/emit';
import { useI18n } from 'vue-i18n';

import { useEventBus } from '@vueuse/core'
import EquipmentAtClientLocations from '@/components/UI/EquipmentAtClientLocations.vue'
const createdBus = useEventBus('created');

const emit = defineEmits(emitArray.value)

const props = defineProps({
  client: {
    type: Object,
    required: true
  },
  isDialog: {
    type: Boolean,
    required: false
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  }
})

const valid= ref(false)
const rules= validationRules
const contact= ref({} as any)
const contactDialogShow= ref(false)
const contactMethod= ref('add')
const contactLoading= ref(false)
const form = ref()
const exactOnlineAccounts = ref([])

const {t} = useI18n()

onMounted(() => {
  // Get getExactOnlineAccounts
  getExactOnlineAccounts().then((res) => {
    exactOnlineAccounts.value = res.data
  })
})

const submitFom = () => {
  const $form = form.value
  // console.log('$form %o', $form)
  if (!$form.validate()) return false
  if (typeof props.submit === 'function') {
    props.submit(props.client)
  } else {
    $form.submit()
  }

  emit('created', props.client)
  createdBus.emit({ type: 'client', data: props.client })
  emit('close')
}
const triggerAddContactOpen = () => {
  contact.value = {}
  contactMethod.value = 'add'
  contactDialogShow.value = true
}
const triggerEditContactOpen = (contactData: any) => {
  contact.value = Object.assign({}, contactData)
  contactMethod.value = 'edit'
  contactDialogShow.value = true
}
const triggerContactSave = () => {
  contactLoading.value = true
  let saveMethod = contactMethod.value === 'add' ? createClientContact : updateClientContact
  saveMethod(Object.assign(contact.value, { client_id: props.client.id }))
    .then((res) => {
      contactLoading.value = false
      let contacts
      // Success
      // If added contact, add to store
      if (contactMethod.value === 'add') {
        contacts = props.client.contacts.slice(0)
        contacts.push(res.data.contact)
      } else {
        contacts = props.client.contacts.map((contact: any) => {
          if (contact.id === contact.id) {
            return contact
          }
          return contact
        })
      }
      props.onInput('contacts', contacts)
      // Close the modal, job is done
      contactDialogShow.value = false
    })
    .catch((err) => {
      contactLoading.value = false
      sayErrorResponse(err)
    })
}
const deleteClientContactLocal = (id: any) => {
  let confirmAction = confirm(t('clientContactDeleteConfirm'))
  if (confirmAction) {
    props.onInput('contacts', props.client.contacts.filter((contact: any) => {
      return contact.id !== id
    }))
    deleteClientContact(id).then(() => {
      Say('success', t('clientContactDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const validateForm = () => {
  if (!contact.value.name) {
    return true
  }
  return false
}
</script>

<style scoped lang="scss">
.contacts-projects {
  padding-left: 15px;
}
.locations-equipment {
  margin-top: 15px;
}
table.table {
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
</style>
