<template>

  <v-row wrap>
    <v-col sm="12">
      <v-card flat border>
        <v-card-text class="d-block px-0 py-0">
          <v-data-table-server
            v-if="workInProgressReportsList"
            :headers="headers"
            :items="workInProgressReportsList"
            :items-length="workInProgressReportsListTotalCount"
            :loading="workInProgressReportsListLoading"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @update:options="getWorkInProgressReports"
            @update:sort-by="sortEvent"
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }: {item : any}">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'WorkInProgressReportsView', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'WorkInProgressReportsView', params: { id: item.id }}">
                    {{ item.last_date_included }}
                  </router-link>
                </td>
                <td>
                  {{ item.status }}
                </td>
                <td>
                  &euro; {{ formatCurrency(item.total_excl_vat) }}
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.workInProgressReportFilterLabel {
  padding-top: 10px;
  width: 100px;
}
.workInProgressReportFilterLabel.twoRows {
  padding-top: 0;
}
.pageButtons {
  padding: 5px 10px 0 0;
}
.printOnlyPossibleForGroupedView {
  color: #999;
  font-size: 12px;
  padding: 3px 5px;
}
</style>

<script setup lang="ts">
import { datatableOptions } from '@/mixins/datatableOptions'
import { ref, computed, onBeforeMount } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { capitalcase } from '@/utils/filters'
import emitArray from "@/utils/emit";
import { computedAsync } from '@vueuse/core'

const emit = defineEmits(emitArray.value)

const {t} = useI18n()

const headers: any = ref([
  { title: t('id', 1), key: 'id', sortable: true, width: 100 },
  { title: t('lastDateIncluded', 1), key: 'last_date_included', sortable: true, width: 300 },
  { title: t('status', 1), key: 'status', sortable: true, width: 100 },
  { title: t('totalExclVat', 1), key: 'total_excl_vat', sortable: true, width: 100 },
])
const workInProgressReportsList = computed(() => store.getters.workInProgressReportsList)
const workInProgressReportsListLoading = computed(() => store.getters.workInProgressReportListLoading)
const workInProgressReportsListTotalCount = computed(() => store.getters.workInProgressReportsListTotalCount)

const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions(false)

onBeforeMount(() => {
  store.commit('app.setPageTitle', t('workInProgressReport', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('workInProgressReport', 2) }
  ])
})

const getWorkInProgressReports =  computedAsync(async () => {
  return await store.dispatch('workInProgressReports.fetch', Object.assign(
    pagination.value
  ))
})

const sortEvent = (value: any) => {
  if(value.length === 0) {
    return
  }
  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
</script>
