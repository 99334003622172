<template>
  <v-card flat border>
    <v-card-text>
      <template v-if="loading || !workInProgressReport">
        <p class="my-1">
          <v-progress-circular indeterminate color="primary" :size="16" width="3" class="mr-2"></v-progress-circular>
          <i>{{ t('workInProgressIsBeingLoaded') }}</i>
        </p>
      </template>
      <template v-else-if="workInProgressReport.completed_at">
        <table>
          <thead>
            <tr>
              <th colspan="7" class="text-normal"><i>{{ t('overviewGeneratedAt') }}: {{ formatDateTime(workInProgressReport.completed_at) }}</i></th>
            </tr>
            <tr>
              <th>{{ t('branch') }}</th>
              <th>{{ t('client') }}</th>
              <th>{{ t('job') }}</th>
              <th>{{ t('type') }}</th>
              <th>{{ t('dateStart') }}</th>
              <th>{{ t('dateEnd') }}</th>
              <th>{{ t('status') }}</th>
              <th>{{ t('statusSince') }}</th>
              <th class="text-right">{{ t('totalExclVat') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="line in workInProgressReport.lines">
              <tr>
                <td>{{ line.branch_name }}</td>
                <td>{{ line.client_name }}</td>
                <td>{{ line.job_name }}</td>
                <td>{{ line.type }}</td>
                <td>{{ formatDate(line.checkout_period_start_date) }}</td>
                <td>{{ formatDate(line.checkout_period_end_date) }}</td>
                <td>{{ line.status }}</td>
                <td>{{ formatDate(line.status_date) }}</td>
                <td class="text-right">&euro; {{ formatCurrency(line.total_excl_vat) }}</td>
              </tr>
            </template>
            <tr>
              <td colspan="8"><b>{{ t('totalExclVat') }}</b></td>
              <td class="text-right"><b>&euro; {{ formatCurrency(workInProgressReport.total_excl_vat) }}</b> </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <p class="mb-4">
          <b>ID:</b> {{ workInProgressReport.id }}
        </p>
        <p class="mb-4">
          <b>{{ t('lastDateIncluded') }}:</b> {{ formatDate(workInProgressReport.last_date_included) }}
        </p>
        <p class="mb-4">
          <b>{{ t('status') }}:</b> {{ workInProgressReport.status }}
        </p>
        <p class="mb-4">
          <b>{{ t('startedAt') }}:</b> {{ formatDateTime(workInProgressReport.started_at) }}
        </p>
        <template v-if="workInProgressReport.failed_at">
          <p class="mb-4">
            <b>{{ t('failedAt') }}:</b> {{ formatDateTime(workInProgressReport.failed_at) }}
          </p>
          <p>
            {{ workInProgressReport.failure_message }}
          </p>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import sayErrorResponse from '@/mixins/sayErrorResponse'
import { fetchWorkInProgressReport } from '@/api/workInProgressReports'

const { t } = useI18n()
const route = useRoute()
const store = useStore()

const loading = ref(false)

const workInProgressReport = ref<any>(null)

onBeforeMount(async () => {
  // UI
  store.commit('app.setPageTitle', t('viewWorkInProgressReport'))
  store.commit('app.setBreadcrumbs', [
    { text: t('workInProgressReport', 2), routeName: 'WorkInProgressReportsList' },
    { text: t('viewReport') }
  ])
})

onMounted(async () => {
  try {
    const response = await fetchWorkInProgressReport(route.params.id)
    if(!response.data.data.status) {
      loading.value = false
      sayErrorResponse(response);
    } else {
      loading.value = false
      workInProgressReport.value = response.data.data;
    }
  } catch (error) {
    loading.value = false
    sayErrorResponse(error);
  }
})

const formatDate = (date: string) => {
  if (!date) {return ''}
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}

const formatDateTime = (dateTime: string) => {
  const [date, time] = dateTime.split(' ');
  return `${formatDate(date)} ${t('at').toLowerCase()} ${time}`;
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

table, td, th {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

th {
  text-align: left;
}

th, td {
  font-size: 11px;
  padding: 4px 8px;
  white-space: nowrap;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-property: 'box-shadow', 'opacity', 'background', 'height';
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

tr:hover td,
tr.hover td {
  background-color: rgba(0, 0, 0, 0.2);
}

.text-normal {
  font-weight: normal;
}

.bg-white {
  background-color: #fff;
}
</style>
