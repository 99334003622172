<template>
  <v-row wrap>
    <v-col sm="6">
      <RentalSupplierForm
        :rentalSupplier="rentalSupplier"
        :submit="submit"
        :onInput="onInput"
        :loading="loading"
        :buttonText="$t('create')"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { onBeforeMount, defineComponent, ref, computed } from 'vue'
import { default as RentalSupplierForm } from './Form.vue'
import Say from '@/utils/Say'
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { t } = useI18n()
const router = useRouter()

defineComponent({
  RentalSupplierForm
})

const loading = ref(false)

const rentalSupplier = computed(() => {
  return store.getters.rentalSupplierCreate
})

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('rentalSupplierCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('rentalSupplier', 2), routeName: 'RentalSuppliers' },
    { text: t('rentalSupplierCreate') }
  ])
})

const submit = async() => {
  loading.value = true
  return await store.dispatch('rentalSuppliers.create')
    .then((id) => {
      // Display success
      loading.value = false
      Say('success', t('rentalSupplierCreateSuccess'))
      // Redirect to the rentalSupplier edit screen
      router.push({ name: 'RentalSuppliersEdit', params: { id: id } })
    }).catch((err) => {
      loading.value = false
      sayErrorResponse(err)
    })
}
const onInput = (name: string, value: any) => {
  store.commit('rentalSuppliers.createUpdateInput', { name: name, value: value })
}
</script>
