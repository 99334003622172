import axios from 'axios'

export function getSalesInvoiceAsPdf (SalesInvoiceId: number|string) {
  return axios.get('/sales-invoices/' + SalesInvoiceId + '/pdf', {
    responseType: 'blob'
  })
}

export function creditSalesInvoice (SalesInvoiceId: number|string) {
  return axios.post('/sales-invoices/' + SalesInvoiceId + '/credit')
}