<script setup lang="ts">
import {inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import Say from "@/utils/Say";
import SayErrorResponse from "@/mixins/sayErrorResponse";
import {createProForma} from "@/api/proFormas";

const {t} = useI18n()


const emit = defineEmits(['approved', 'cancelForm', 'loading'])
const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  checkoutPeriod: {
    type: Object,
    required: false
  },
  job: {
    type: Object,
    required: true
  }
})

const jobId = inject('jobId', 0)

const dialogTitle = inject('dialogTitle', null)
const formatDate = inject('formatDate')

onMounted(() => {
  dialogTitle.value = t('createProforma')
})

const formSubmitted = ref(false)
const submit = () => {
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true
  emit('loading', true)

  createProForma({
    start_date: props.checkoutPeriod.start_date,
    job_id: jobId.value,
    end_date: props.checkoutPeriod.end_date,
    checkout_period_id: props.checkoutPeriod.id
  }).then(response => {
    if (response.data.error) {
      SayErrorResponse(response.data.error);
      emit('loading', false)
    } else {
      Say('success', t('proFormaCreated'));
      emit('created')
    }
  }).catch((error) => {
    SayErrorResponse(error)
    emit('loading', false)
  }).finally(() => {
    formSubmitted.value = false
  })
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})
</script>

<template>
  <div>
    <template v-if="job.client.name === null || job.client.postcode === null || job.client.city === null || job.client.address === null">
      <p>
        {{ t('clientHasIncompleteAddress') }}
        <router-link
          :to="{ name: 'ClientsEdit', params: { id: job.client.id } }"
        >
          {{ t('clickHereToCompleteTheAddress') }}
        </router-link>
      </p>
    </template>
    <template v-else>
      <v-row class="mb-2">
        <v-col sm="6">
          <div class="datepickerWrapper">
            <div class="datepickerLabel">{{ t('period') }}</div>
            <div class="font-weight-bold">{{ formatDate(checkoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(checkoutPeriod.end_date) }}</div>
          </div>
        </v-col>
      </v-row>
      <template v-if="$i18n.locale === 'nl'">
        <p class="mb-2">
          Dit is alleen mogelijk als alle werkorders van onderstaande periode zijn goedgekeurd.
        </p>
        Zodra deze pro forma is aangemaakt:
        <ul class="ml-6">
          <li>Kunnen de werkorders die in deze periode vallen niet meer worden aangepast.</li>
          <li>Kan de huur start van alle materialen van dit project alleen worden ingesteld op een datum die na
            onderstaande einddatum komt.
          </li>
          <li>Kan de huur einde van alle materialen van dit project alleen worden ingesteld op een datum die gelijk is
            aan, of komt na onderstaande einddatum.
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="mb-2">
          This is only possible if all work orders for the period below have been approved.
        </p>
        Once this pro forma has been created:
        <ul class="ml-6">
          <li>The work orders that fall within this period can no longer be adjusted.</li>
          <li>The rental start of all equipment for this project can only be set on a date after the end date below.
          </li>
          <li>The rental end of all equipment for this project can only be set on a date equal to or after the end date
            below.
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>
