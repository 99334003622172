<template>
  <Preview
    v-if="isPreviewing"
    :preview-file="isPreviewing"
    @close="previewClosed"
  />
  <v-container grid-lg class="work-orders-view">
    <v-progress-circular indeterminate color="primary" v-show="loading" :size="70" :width="4"></v-progress-circular>
    <v-row v-if="!loading">
      <v-col sm="4" >

        <!-- Details -->
        <Card :title="t('details')" card-text-class="pa-0">
          <template v-slot:content>
            <InfoList two-line>
              <template v-slot:items>

                <!-- Branch -->
                <InfoListItem
                  icon="mdi-home-variant"
                  :title="workOrder.location_va.city"
                  :subtitle="ucfirst(t('branch'))"
                />

                <!-- Opdracht -->
                <InfoListItem
                  v-if="workOrder.job"
                  icon="mdi-clipboard"
                  :title="workOrder.job.job_name"
                  :subtitle="ucfirst(t('job'))"
                  :routeLink="{ name: 'JobsView', params: { id: workOrder.job.id } }"
                />

                <!-- WorkOrder -->
                <InfoListItem
                  v-if="workOrder.standard_name_prefix"
                  icon="mdi-file-document"
                  :title="workOrder.standard_name_prefix"
                  :subtitle="ucfirst(t('workOrder'))"
                />

                <!-- Category -->
                <InfoListItem
                  v-if="workOrder.category"
                  :title="workOrder.category"
                  :subtitle="ucfirst(t('category'))"
                  icon="mdi-shape"
                />

                <!-- Status -->
                <InfoListItem
                  :title="getWorkOrderStatusName(workOrder)"
                  :subtitle="ucfirst(t('status'))"
                  icon="mdi-wrench"
                />

                <!-- Client -->
                <InfoListItem
                  v-if="workOrder.client"
                  icon="mdi-domain"
                  :title="workOrder.client.name"
                  :subtitle="ucfirst(t('client'))"
                  :routeLink="{ name: 'ClientsEdit', params: { id: workOrder.client.id } }"
                />

                <!-- Client Ref -->
                <InfoListItem
                  v-if="workOrder.client_ref"
                  icon="mdi-tag"
                  :title="workOrder.client_ref"
                  :subtitle="ucfirst(t('clientReference'))"
                />

                <!-- Timespan -->
                <InfoListItem
                  :title="timespan"
                  :subtitle="ucfirst(t('duration'))"
                  icon="mdi-calendar-range"
                  :divider="false"
                />

              </template>
            </InfoList>

          </template>
        </Card>

        <Card v-if="!workOrder.is_for_equipment_movement && workOrder.equipment_placed.length === 0 && workOrder.equipment_collected.length === 0 && workOrder.status === 'PLANNED'" :title="t('job')" card-text-class="pa-0">
          <template v-slot:content>
            <div class="py-5 pr-5">
              <v-btn
                class="ml-5"
                color="primary"
                size="small"
                @click="openChangeJobDialog()"
              >
                {{ t('linkToOtherJob') }}
              </v-btn>
            </div>
          </template>
        </Card>

        <!-- Location and instructions -->
        <Card :title="`${t('location', 1)} & ${t('instructions')}`" card-text-class="pa-0">
          <template v-slot:content>

            <InfoList two-line>
              <template v-slot:items>

                <!-- To -->
                <InfoListItem
                  title=""
                  subtitle=""
                  icon="mdi-map-marker"
                  :divider="!!workOrder.instructions"
                >
                  <template v-slot:content>
                    {{ getLocationAddress(workOrder.location) }}
                  </template>
                </InfoListItem>

                <!-- Instructions -->
                <InfoListItem
                  v-if="workOrder.instructions"
                  title=""
                  subtitle=""
                  :divider="false"
                  :avatar="false"
                >
                  <template v-slot:content>
                    <span v-html="workOrder.instructions.replace(/\n/g, '<br>')"></span>
                  </template>
                </InfoListItem>

              </template>

            </InfoList>
          </template>
        </Card>
      </v-col>

      <v-col sm="4" >
        <!-- Location and instructions -->
        <Card :title="t('employee', 2)" card-text-class="pa-0">
          <template v-slot:content>

            <InfoList two-line>
              <template v-slot:items>

                <!-- Responsible -->
                <InfoListItem
                  v-if="workOrder.responsible"
                  :title="workOrder.responsible.name"
                  :subtitle="ucfirst(t('responsible'))"
                  icon="mdi-face-man"
                />

                <!-- Assigned employees -->
                <InfoListItem
                  v-if="workOrder.selectedEmployees.length > 0"
                  :title="workOrder.selectedEmployees.map((employee: any) => employee.name).join(', ')"
                  :subtitle="ucfirst(t('assignedEmployees'))"
                  icon="mdi-account-multiple"
                />

                <!-- Uitvoerders -->
                <InfoListItem
                  :title="uitvoerders"
                  :subtitle="ucfirst(t('clientResponsible'))"
                  icon="mdi-account-multiple-outline"
                  :divider="false"
                />

              </template>
            </InfoList>

          </template>
        </Card>

        <!-- Notes -->
        <Card :title="t('notes')" card-text-class="pa-0">
          <template v-slot:content>
            <v-card class="note ma-0" v-for="note in notes" :key="note.id">
              <v-card-text>
                <p class="title font-weight-medium">{{ note.title }}</p>
                <p class="subtitle py-1">{{ getNoteSubtitle(note) }}</p>

                <p>{{ note.content }}</p>

                <div class="downloads">
                  <!-- Loop through images and create dialogs for each -->
                  <Dialog
                    v-for="file in note.files"
                    :key="file.hash"
                    :visible="dialogVisibility[file.hash] !== undefined ? dialogVisibility[file.hash] : false"
                    content-class="dialog-image"
                    max-width="80%"
                    :title="file.filename"
                    @update:visible="updateDialogVisibility($event, file.hash)"
                  >
                    <template v-slot:activator>
                      <!-- Clickable image to open the dialog -->
                      <div
                        :style="{'background-image': loadPreview(file, 'bg')}"
                        class="noteImagePreview"
                        @click="previewDocument(file)"
                      ></div>
                    </template>
                    <template v-slot:content>
                      <!-- Display image inside the dialog -->
                      <img :src="loadPreview(file)" class="noteImage" />
                    </template>
                  </Dialog>
              </div>

              </v-card-text>
            </v-card>
          </template>
        </Card>

        <!-- Related workOrders -->
        <Card :title="ucfirst(t('relatedWorkOrders'))" card-text-class="pa-0" v-if="!workOrder.is_for_equipment_movement">
          <template v-slot:content>
            <v-card class="ma-0">
              <v-card-text>
                {{ t('viewAllWorkordersOfThisJob') }}
                <a @click="gotoProjectTab('Workorders', $event)" href="#">{{ t('inTheWorkordersTabOfTheJob') }}</a>.
              </v-card-text>
            </v-card>
          </template>
        </Card>
      </v-col>

      <v-col sm="4" >
        <revisions-card
          :workOrder="workOrder"
          v-if="workOrder.revisions"
        />
        <Card :title="t('file', 2)" v-if="workOrder.files.length > 0" card-text-class="pa-0">
          <template v-slot:content>
            <AttachedFilesList
              v-if="workOrder.files"
              :files="workOrder.files"
            />
          </template>
        </Card>
        <Card :title="ucfirst(t('equipmentSuggested'))" card-text-class="pa-0">
          <template v-slot:content>
            <v-table class="table table-equipment">
              <thead>
                <tr>
                  <th class="text-xs-left">{{ t('equipment', 1) }}</th>
                  <th class="text-xs-center">{{ t('amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(suggestedEquipment, i) of workOrder.suggestedEquipment" :key="i">
                  <td class="text-xs-left">
                    {{ suggestedEquipment.equipment.name }}
                    <span class="equipment-supplier" v-if="suggestedEquipment.rental_supplier_id">
                      {{t('rentalSupplier')}} : {{getRentalSupplierName(suggestedEquipment.rental_supplier_id)}}
                    </span>
                    <span class="equipment-note" v-if="suggestedEquipment.note">
                      {{t('notes')}} : {{suggestedEquipment.note}}</span>
                  </td>
                  <td class="text-xs-center">
                    {{ suggestedEquipment.quantity }}
                  </td>
                </tr>
                <tr v-if="!workOrder.suggestedEquipment || workOrder.suggestedEquipment.length === 0">
                  <td colspan="3">
                    {{ t('noEquipment') }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </template>
        </Card>
        <Card :title="t('approveWorkOrder', 2)" card-text-class="pa-0">
          <template v-slot:content>
            <div v-if="workOrder.status !== 'APPROVED' && workOrder.status !== 'CANCELLED'" class="py-5 pr-5">
              <div v-if="!workOrder.is_for_equipment_movement && workOrder.total_excl_vat === null" class="px-5">
                <span class="mdi mdi-alert-circle-outline approve-icon approve-icon-warning"></span> {{ t('fillInTotalHoursBeforeApproving') }}
              </div>
              <template v-else>
                <v-checkbox v-model="confirmApprove" hide-details class="ml-2 mb-3">
                  <template v-slot:label>
                    <span class="label-text">
                      {{ !workOrder.is_for_equipment_movement ? t('approveWorkOrderText') : t('approveWorkOrderEquipmentMovementsText') }}
                    </span>
                  </template>
                </v-checkbox>
                <v-btn
                  class="ml-5"
                  color="primary"
                  size="small"
                  @click="approveThisWorkOrder"
                  :disabled="!confirmApprove"
                >
                  {{ t('approve') }}
                </v-btn>
              </template>
            </div>
            <div v-else-if="workOrder.status === 'APPROVED'" class="py-5 px-5">
              <span class="mdi mdi-check-circle-outline approve-icon approve-icon-success"></span> {{ t('workOrderApproved') }}
            </div>
            <div v-else-if="workOrder.status === 'CANCELLED'" class="py-5 px-5">
              {{ t('canceledWorkOrderCanNotBeApproved') }}
            </div>
          </template>
        </Card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col sm="12">
        <Card class="employee-hours overflow-visible" :title="t('hour', 2)" >
          <template v-slot:content>
            <v-row>
              <v-col class="mb-2" cols="12" md="6" lg="4" v-for="employee in employeesHours" :key="employee.id">
                <v-row class="justify-space-between pt-4 pb-6 px-4" >
                  <h2 :key="`e${employee.id}`">{{ employee.name }}</h2>

                  <v-icon
                    v-if="!workOrder.is_in_pro_formas && workOrder.status !== 'APPROVED'"
                    class="add" @click="addEntry(employee, employee.hours)">mdi-plus-box</v-icon>
                </v-row>

                <div v-if="employee.hours.length === 0" class="px-1">
                  {{ t('noItems') }}.
                </div>

                <div>

                  <HoursEntry
                    v-for="entry in employee.hours"
                    :key="entry.id || randomId()"
                    :entry="entry"
                    :editable="!workOrder.is_in_pro_formas && workOrder.status !== 'APPROVED'"
                    @update="updateEntry(entry, $event)"
                    @remove="removeEntry(employee.hours, entry)"
                    class="mx-1"
                  />
                </div>
              </v-col>

              <v-col cols="12" md="6" lg="4" class="py-2">
                <v-row class="new-entry">
                  <div v-if="!workOrder.is_in_pro_formas && workOrder.status !== 'APPROVED'" class="px-3 py-4 new-entry-button" @click="employee = null; newEmployeeHoursDialog = true">
                    <v-icon>mdi-plus-box</v-icon> {{ t('newEmployeeHoursEntry') }}
                  </div>

                  <v-dialog v-model="newEmployeeHoursDialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <div class="headline">{{ t('workOrderSelectEmployee')}}</div>
                      </v-card-title>

                      <v-card-text>
                        <v-autocomplete variant="underlined" v-model="employee" :items="employeesWithoutHours" item-title="name" :return-object="true"></v-autocomplete>
                      </v-card-text>

                      <v-card-actions>
                        <v-row class="justify-end">
                          <v-btn
                            color="primary"
                            flat
                            @click.stop="addEmployeeHours(employee); newEmployeeHoursDialog = false"
                            :disabled="!employee"
                          >
                            {{ t('add') }}
                          </v-btn>

                          <v-btn
                            color="primary"
                            flat
                            @click.stop="newEmployeeHoursDialog = false"
                          >
                            {{ t('cancel') }}
                          </v-btn>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-3 mx-1">
                <div class="totalHours pt-4 pb-2">
                  {{ t('totalAmountRegistered') }}: <span class="font-weight-bold">{{ getTotalRegisteredHours(employeesHours) }} {{ t('hour').toLowerCase() }}</span>
                </div>
                <div v-if="!workOrder.is_for_equipment_movement" class="totalHours mt-2 pt-4 pb-2">
                  <div>
                    <div class="mb-4">
                      {{ t('hoursForInvoice') }}:
                      <v-icon
                        v-if="!workOrder.is_in_pro_formas && workOrder.status !== 'APPROVED'"
                        class="ml-2 edit" @click="openEditTotalHoursDialog()">mdi-pencil</v-icon>
                    </div>
                    <template v-if="workOrder.total_excl_vat !== null">
                      <table v-if="workOrder.hours.length > 0" class="table-hours mb-4">
                        <thead>
                          <tr>
                            <th width="300">{{ t('description') }}</th>
                            <th class="text-right">{{ t('internalArticleNumber') }}</th>
                            <th class="text-right">{{ t('hours') }}</th>
                            <th class="text-right">{{ t('hourlyRateExclVat') }}</th>
                            <th class="text-right">{{ t('totalExclVat') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="workOrderHour in workOrder.hours" :key="workOrderHour.id">
                            <td>{{ workOrderHour.description }}</td>
                            <td>{{ workOrderHour.internal_article_number }}</td>
                            <td class="text-right">{{ formatNumber(workOrderHour.pivot.hours, 2) }}</td>
                            <td class="text-right">&euro; {{ formatCurrency(workOrderHour.pivot.hourly_rate_excl_vat) }}</td>
                            <td class="text-right">&euro; {{ formatCurrency(workOrderHour.pivot.total_excl_vat) }}</td>
                          </tr>
                          <tr>
                            <td colspan="4" class="text-right"><b>Totaal:</b></td>
                            <td class="text-right"><b>&euro; {{ formatCurrency(workOrder.total_excl_vat) }}</b></td>
                          </tr>
                        </tbody>
                      </table>
                      <span v-else class="font-weight-bold">
                        {{ t('noHoursWillBeChargedForThisWorkOrder') }}
                      </span>
                    </template>
                    <span v-else class="font-weight-bold text-red">
                      {{ t('notYetFilledIn') }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
        </Card>

      </v-col>

    </v-row>

    <Portal to="page-buttons">
      <template v-if="workOrder.id && !workOrder.is_for_equipment_movement">
        <template v-if="workOrder.location.latitude && workOrder.location.longitude && workOrder.category !== 'Diverse'">
          <v-btn
            v-if="workOrder.id"
            color="primary"
            depressed
            size="small"
            class="mr-1"
            @click="duplicateWorkOrder"
          >
            {{ t('duplicate') }}
          </v-btn>
        </template>
        <template v-else-if="!workOrder.location.latitude || !workOrder.location.longitude">
          <v-tooltip :text="t('duplicatingNotPossibleBecauseOfLocationWithoutCoordinates')" location="left">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                color="primary"
                depressed
                size="small"
                class="mr-1"
              >
                {{ t('duplicate') }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-else-if="workOrder.category === 'Diverse'">
          <v-tooltip :text="t('duplicatingNotPossibleBecauseOfCategory')" location="left">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                color="primary"
                depressed
                size="small"
                class="mr-1"
              >
                {{ t('duplicate') }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </template>

      <router-link :to="printRoute" target="_blank" rel="noopener">
        <v-btn
          color="primary"
          depressed
          size="small"
          class="mr-1"
        >
          {{ t('print') }}
        </v-btn>
      </router-link>
    </Portal>
  </v-container>

  <v-row v-if="!loading">
    <v-col sm="12 pb-8">
      <Card class="overflow-visible" :title="t('equipmentMoved', 2)" >
        <template v-slot:content>
          <template v-if="!workOrder.is_for_equipment_movement">
            {{ t('viewAllEquipmentOfThisJob') }} <a @click="gotoProjectTab('Equipment', $event)" href="#">{{ t('inTheEquipmentTabOfTheJob') }}</a>.
            <Equipment :onlyShowWorkOrderId="Number(workOrderId)" :workOrderLocationId="Number(workOrder.location.id)"/>
          </template>
          <template v-else>
            {{ t('correctEquipmentOfWorkOrderForEquipmentMovements') }}
            <template v-if="workOrder.category === 'Materiaal brengen naar vestiging' || workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier'">
              <Equipment :onlyShowPlacedByWorkOrderId="Number(workOrderId)"/>
            </template>
            <template v-if="workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier'">
              <Equipment :onlyShowCollectedByWorkOrderId="Number(workOrderId)"/>
            </template>
          </template>
        </template>
      </Card>
    </v-col>
  </v-row>

  <v-dialog
    v-if="!loading && workOrder.equipment_placed.length === 0 && workOrder.equipment_collected.length === 0 && workOrder.status !== 'APPROVED' && workOrder.status !== 'CANCELLED'"
      v-model="isChangeJobDialogVisible"
      width="480"
      id="equipment-items-bulk-update-dialog"
      @afterLeave="closeChangeJobDialog"
    >
    <Card
      :title="t('linkToOtherJob', 2)"
      card-text-class="pa-0"
    >
      <template v-slot:content>
        <div class="py-5 px-5">
          <p class="mb-5">
            {{ t('jobsWithStatusPlannedAndOngoingAndSameClientAndBranch') }}:
          </p>
          <template v-if="jobs.length > 0">
            <v-autocomplete
              v-model="linkToOtherJobId"
              :items="jobs"
              item-title="name"
              item-value="id"
              hide-details
              class="mb-5"
              density="compact"
            />
            <v-btn
              color="primary"
              size="small"
              @click="linkToOtherJob"
              :disabled="!linkToOtherJobId"
            >
              {{ t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <p><i>{{ t('noJobsFound') }}</i></p>
          </template>
        </div>
      </template>
    </Card>
  </v-dialog>

  <v-dialog
    v-if="!loading"
      v-model="isEditTotalHoursDialogVisible"
      width="900"
      id="equipment-items-bulk-update-dialog"
      @afterLeave="closeEditTotalHoursDialog"
    >
      <v-card
          prepend-icon="mdi-pencil"
          :title="t('hoursForInvoice')"
          card-text-class="pa-0"
          >
          <template v-slot:text>
            <table class="table-hours table-hours-form">
              <thead>
                <tr>
                  <th>{{ t('description') }}</th>
                  <th class="text-right">{{ t('internalArticleNumber') }}</th>
                  <th class="text-right">{{ t('hourlyRateExclVat') }}</th>
                  <th>{{ t('quantity') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="totalHour in totalHours" :key="totalHour.id">
                  <td>{{ totalHour.description }}</td>
                  <td class="text-right">{{ totalHour.internal_article_number }}</td>
                  <td class="text-right">&euro; {{ formatCurrency(totalHour.hourly_rate_excl_vat) }}</td>
                  <td>
                    <v-text-field
                      v-model="totalHour.work_order_hours"
                      hide-details
                      class="text-right hour-input"
                      density="compact"
                      type="number"
                      min="0"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-slot:actions>
            <v-btn
                :text="t('save')"
                :loading="totalHoursSubmitted"
                @click="updateTotalHours"
            />
            <v-btn
                :text="t('cancel')"
                :disabled="totalHoursSubmitted"
                @click="closeEditTotalHoursDialog"
            />
          </template>
      </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { Portal } from 'portal-vue'

import { fetchEmployees } from '@/api/employees'
import {
  fetchWorkOrderHours,
  addWorkOrderHours,
  updateWorkOrderHours,
  removeWorkOrderHours,
  updateWorkOrderTotalHours,
  getWorkOrderTotalHours
} from '@/api/workOrders'

import Card from '@/components/UI/Card.vue'
import Dialog from '@/components/Dialog'
import InfoList from '@/components/UI/InfoList.vue'
import InfoListItem from '@/components/UI/InfoListItem.vue'

import HoursEntry from './HoursEntry.vue'
import RevisionsCard from './cards/RevisionsCard.vue'

import Say from '@/utils/Say'
import { defineComponent, nextTick, ref, watch, provide } from 'vue'
import { computed } from 'vue'
import { onBeforeMount } from 'vue'
import { onMounted } from 'vue'
import { onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { hourMinutesFromDateTime, prettyDateFormatWithoutHours, shortDateFormat } from '@/utils/filters'
import { ucfirst, fromStorage, formatBytes } from '@/utils/filters'
import sayErrorResponse from '@/mixins/sayErrorResponse'
import Equipment from '@/components/Jobs/View/Equipment.vue'
import { approveWorkOrder } from '@/api/workOrders'
import { linkWorkOrderToOtherJob } from '@/api/workOrders'
import { computedAsync } from '@vueuse/core'
import useProjectTabSelector from '@/composable/useProjectTabSelector';
import {loadImageData, streamDownload} from "@/helpers";

defineComponent({
  Card,
  Dialog,
  HoursEntry,
  InfoList,
  InfoListItem,
  RevisionsCard,
  Portal
})

const emit = defineEmits(["show-template-ui","hide-template-ui"]);

const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { projectTabSelector } = useProjectTabSelector()

const isChangeJobDialogVisible = ref(false)
const linkToOtherJobId = ref(null)
const jobs = ref([])

const isEditTotalHoursDialogVisible = ref(false)
const totalHours = ref(null)
const totalHoursSubmitted = ref(false)

const workOrderId = computed(() => {
  return route.params.id
})

const workOrder: any = computed(() => {
  return store.getters.workOrdersEdit
})

const jobId = computed(() => {
  return workOrder.value.job_id
})
provide('jobId', jobId)

const files = computed(() => {
  return workOrder.value.files
})
provide('files', files)

const notes = computed(() => {
  return workOrder.value.notes || []
})

const uitvoerders = computed(() => {
  return [1, 2]
    .filter((index) => {
      return workOrder.value['client_responsible_' + index] !== null
    })
    .map((index) => {
      return workOrder.value['client_responsible_' + index].name
    })
    .join(', ')
})

const timespan = computed(() => {
  let parts = [t('from'), shortDateFormat(workOrder.value.datetime_start.date)]
  if (workOrder.value.datetime_end.date) {
    parts = [...parts, t('to').toLowerCase(), shortDateFormat(workOrder.value.datetime_end.date)]
  }
  return parts.join(' ')
})

const employeeIdsWithHours = computed(() => {
  return new Set(employeesHours.value.map((employee: any) => employee.id))
})

const employeesWithoutHours = computed(() => {
  return employees.value.filter((employee: any) => !employeeIdsWithHours.value.has(employee.id))
})

const getTotalRegisteredHours = (employeesHours: any) => {
  let totalRegisteredHours = employeesHours.reduce((total: number, employee: any) => {
    return total + employee.hours.reduce((total: number, entry: any) => {
      return total + (entry.datetime_end ? (new Date(entry.datetime_end).getTime() - new Date(entry.datetime_start).getTime()) / 1000 / 60 / 60 : 0)
    }, 0)
  }, 0)
  return totalRegisteredHours.toFixed(2).replace('.', ',')
}

const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])
/**
 * This is the list of people that can supply equipment,
 * VA is always part of the list and at the top
 */
const suppliedByOptions = computed(() => {
  let options = [{id: null, name: ''}]
  for (const rentalSupplier of rentalSuppliers.value) {
    options.push({
      id: rentalSupplier.id,
      name: `${rentalSupplier.name} | ${ rentalSupplier.location.city}`,
    })
  }
  return options
})

const getRentalSupplierName = (rental_supplier_id) => {
  return suppliedByOptions.value.filter((supplier) => supplier.id === rental_supplier_id )[0]?.name ?? ''
}

const gotoProjectTab = (tabName: string, e: any) => {
  // use router to navigate to the correct tab
  e.preventDefault();
  projectTabSelector.value = tabName
  router.push({ name: 'JobsView', params: { id: jobId.value }})
}

const loading = ref(false)
const employees = ref([] as any)
const employeesHours = ref([] as any)
const employee = ref(null as any)
const newEmployeeHoursDialog = ref(false)
const printRoute = ref({ name: 'WorkOrdersPrint', id: workOrderId.value })
const dialogVisibility = ref<{ [key: string]: boolean }>({})
const confirmApprove = ref(false)

import Preview from "@/components/UI/Documents/Preview.vue";
import AttachedFilesList from "@/components/WorkOrders/cards/AttachedFilesList.vue";

watch(route , (value: any) => {
  if (value?.params?.id) {
    initialize()
    nextTick(() => {
      store.commit('app.setToggleButton', {
        route: { name: 'WorkOrdersEdit', id: workOrderId.value },
        text: t('edit')
      })
    })
  }
}, { deep: true })

watch(workOrder , (newValue, oldValue) => {
  if (newValue.is_in_pro_formas) {
    store.commit('app.setToggleButton', {
      route: { name: 'WorkOrdersEdit', id: workOrderId.value },
      text: t('edit'),
      title: t('workOrderInProFormaPeriod'),
      disabled: true
    })
  }
})

onBeforeMount(() => {
  initialize()
})

onMounted(() => {
  store.commit('app.setToggleButton', {
    route: { name: 'WorkOrdersEdit', id: workOrderId.value },
    text: t('edit')
  })
  store.dispatch('rentalSuppliers.fetch');
})

onBeforeUnmount(() => {
  store.commit('app.clearToggleButton')
})

const initialize = () => {
  loading.value = true
  // Set the initial state so we have what to reset to
  store.dispatch('workOrders.fetchSingleForEdit', workOrderId.value)
  .then(() => {
    // UI
    store.commit('app.setPageTitle', t('workOrder') + ' ' + workOrder.value.standard_name_prefix)
    store.commit('app.setBreadcrumbs', [
      { text: t('workOrder', 2), routeName: 'WorkOrdersList' },
      { text: t('workOrder') }
    ])
    if(workOrder.value.job_id) {
      store.dispatch('jobs.fetchSingleForEdit', workOrder.value.job_id)
    }

    return fetchEmployees({ rowsPerPage: -1 })
  })
  .then(({data: {data: data}}) => {
    employees.value = data

    loading.value = false
  })

  // Get the hour entries
  fetchWorkOrderHours(workOrderId.value)
    .then((res) => {
      employeesHours.value = res.data

      employeesHours.value.forEach((employee: any) => {
        employee.hours = employee.hours.filter((entry: any) => !entry.deleted_at)
      })
    })
    .catch((err) => {
      sayErrorResponse(err)
    })
  }
const duplicateWorkOrder = () => {
  store.dispatch('workOrders.duplicate', workOrderId.value).then((newWorkorderId) => {
    Say('success', t('workOrderDuplicateSuccess'))

    router.push({ name: 'WorkOrdersEdit', params: { id: newWorkorderId } })
  }).catch((err) => {
    sayErrorResponse(err)
  })
}

const getNoteSubtitle = (note: any) => {
  const [date] = note.updated_at.split('T')

  const at = t('at')
  const by = t('by')

  return `${prettyDateFormatWithoutHours(date)} ${at} ${hourMinutesFromDateTime(note.updated_at)} ${by} ${note.name}`
}

const getImagePath = (image: any) => {
  return `/storage/workOrderNotes/${image}`
}

const getWorkOrderStatusName = (workOrder: any) => {
  let status = t('workOrderStatuses.' + workOrder.status)

  if (workOrder.status === 'ONGOING' && workOrder.status_ongoing) {
    status += ` - ${t('workOrderStatusesOngoing.' + workOrder.status_ongoing.name)}`
  }

  return status
}

const getLocationAddress = (location: any) => {
  return location ? location.location : ''
}

const addEntry = (employee: any, entries: any) => {
  entries.unshift({
    work_order_id: workOrder.value.id,
    employee_id: employee.id,
    datetime_start: '',
    datetime_end: '',
    activities: ''
  })
}

const updateEntry = async(entry: any, updatedEntry: any) => {
  try {
    if (entry.id) {
      await updateWorkOrderHours(workOrderId.value, entry.id, updatedEntry)
    } else {
      entry.id = (await addWorkOrderHours(workOrderId.value, updatedEntry)).data.id
    }
    Object.assign(entry, updatedEntry)
  } catch (error: any) {
    sayErrorResponse(error)
  }
}

const removeEntry = async(entries: any, entry: any) => {
  try{
    if (entry.id) {
      await removeWorkOrderHours(workOrderId.value, entry.id)
    }

    entries.splice(entries.indexOf(entry), 1)
  } catch (error: any) {
    sayErrorResponse(error)
  }
}

const randomId = () => {
  return Math.floor(Math.random() * 1000000)
}

const addEmployeeHours = ({ id, name, company }: { id: any, name: string, company: any }) => {
  employeesHours.value.push({
    id,
    name,
    company,
    hours: []
  })
}

// Set the visibility of the clicked dialog to true
const openChangeJobDialog = () => {
  isChangeJobDialogVisible.value = true
  computedAsync(async () => {
    const getJobs = await store.dispatch('jobs.fetch', {
        page: 1,
        showHistoric: true,
        status: 'ONGOING,PLANNED',
        's-clients_name': workOrder.value.client.name
    })

    // add a new 'name' property to the jobs
    const allJobs = getJobs.map((job: any) => {
      job.name = job.client.name + ' | ' + job.job_name + (job.job_description ? ' | ' + job.job_description : '' ) + ' | ' + job.location.location
      return job
    })

    // remove curent job from the list
    jobs.value = allJobs.filter((job: any) => job.id !== workOrder.value.job_id)
  })
}
const closeChangeJobDialog = () => {
  isChangeJobDialogVisible.value = false
}

// Set the visibility of the clicked dialog to true
const openEditTotalHoursDialog = async () => {
  const response = await getWorkOrderTotalHours(workOrder.value.id)

  totalHours.value = response.data
  isEditTotalHoursDialogVisible.value = true
}
const closeEditTotalHoursDialog = () => {
  isEditTotalHoursDialogVisible.value = false
}

// Set the visibility of the clicked dialog to true
const openDialog = (image: string | number) => {
  dialogVisibility.value[image] = true
}

// Update the visibility of the dialog
const updateDialogVisibility = (newValue: boolean, image: string | number) => {
  dialogVisibility.value[image] = newValue
}

const approveThisWorkOrder = async () => {
  try {
    const response = await approveWorkOrder(workOrder.value.id)

    workOrder.value.revisions =  response.data.revisions
    workOrder.value.status = 'APPROVED'

    Say('success', t('workOrderApprovedSuccess'))
  } catch (err: any) {
    sayErrorResponse(err)
  }
}
const linkToOtherJob = async () => {
  if(!linkToOtherJobId.value) {
    Say('error', t('selectJob'))
    return
  }
  try {
    const response = await linkWorkOrderToOtherJob(workOrder.value.id, linkToOtherJobId.value)

    workOrder.value.revisions =  response.data.revisions
    workOrder.value.job_id = response.data.job_id
    workOrder.value.job = response.data.job

    isChangeJobDialogVisible.value = false
    linkToOtherJobId.value = null

    Say('success', t('workOrderLinkedToOtherJobSuccess'))
  } catch (err: any) {
    sayErrorResponse(err)
  }
}
const updateTotalHours = async () => {

  totalHoursSubmitted.value = true
  try {
    const response = await updateWorkOrderTotalHours(workOrder.value.id, totalHours.value)

    workOrder.value.hours = response.data.hours
    workOrder.value.total_excl_vat = response.data.total_excl_vat

    isEditTotalHoursDialogVisible.value = false

    Say('success', t('workOrderUpdatedTotalHoursSuccess'))
    totalHoursSubmitted.value = false
  } catch (err: any) {
    sayErrorResponse(err)
    totalHoursSubmitted.value = false
  }
}

const openDocument = (file: Object) => {
  streamDownload(file)
}

let isPreviewing = ref()

const previewDocument = (file: Object) => {
  if (previewImages.value[file.hash]) {
    file.data = previewImages.value[file.hash]
  }
  isPreviewing.value = file
}
const previewClosed = () => {
  isPreviewing.value = false
}
const previewImages = ref({})
const loadPreview = (file: Object, type: String = 'url') => {
  if (previewImages.value[file.hash]) {
    if (true === previewImages.value[file.hash]) {
      return;
    }
    if (type === 'bg') {
      return `url('${previewImages.value[file.hash]}')`
    }
    return previewImages.value[file.hash]
  }
  previewImages.value[file.hash] = true
  loadImageData(file, function(imageData) {
    previewImages.value[file.hash] = imageData
    file.data = imageData;
  })
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}
</script>

<style lang="scss" scoped>
.work-orders-view {
  h2 {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: normal;
    line-height: 32px;
    font-size: 16px;
    a, strong {
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }
  }

  hr {
    margin: 20px 0;
  }

  .v-card {
    margin-bottom: 20px;
  }

  .card {
    margin-bottom: 20px;
  }

  & .card__text {
    padding: 0!important;
  }

  .table-files {
    tbody {
      tr {
        td {
          padding-top: 10px;
          padding-bottom: 10px;

          &:first-child {
            width: 100px;
          }

          .preview {
            height: 48px;
            width: auto;
          }
        }
      }
    }
  }

  .employee-hours {
    h2 {
      margin-bottom: 0;
    }

    .entries {
      margin-bottom: 20px;
      padding: 10px;
    }

    .new-entry-button {
      color: rgba(0, 0, 0, 0.87);
      height: 100%;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }

      i {
        font-size: 24px;
      }
    }
  }

  .table-equipment {
    margin-top: 10px;

    thead tr {
      height: auto;
    }

    thead tr th, tbody tr td {
      padding: 5px 10px
    }
  }

  .note {
    .downloads {
      img {
        max-width: 25%;
      }
    }
  }

  .add {
    cursor: pointer;

    &:hover {
      color: green;
    }
  }
  .equipment-note,
  .equipment-supplier {
    display: block;
    font-style: italic;
  }
}

.employee-hours h2 {
  font-size: 14px;
}

.noteImage {
  max-width: 100%;
}
.noteImagePreview {
  background-size: cover !important;
  background-position: center !important;
  width: 70px;
  height: 70px;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
}
.label-text {
  color: #000;
  font-size: 13px;
}
.totalHours {
  border-top: 1px solid #e0e0e0;
  width: 100%;
}
.edit {
  margin-top: -5px;
  cursor: pointer;
}
.approve-icon {
  float: left;
  font-size: 24px;
  margin-right: 15px;
  margin-top: -8px;
}
.approve-icon-warning {
  color: orange;
}
.approve-icon-success {
  color: #33cc33;
}
.table-hours {
  border-collapse: collapse;
  margin-top: 10px;
  thead {
    th {
      border-bottom: 1px solid #e0e0e0;
      padding: 5px 10px;
      border: 1px solid #e0e0e0;
      text-align: left;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      padding: 5px 10px;
      border: 1px solid #e0e0e0;
      white-space: nowrap;
    }
  }
}
.table-hours-form {
  width: 100%;
}

:deep(.v-input.hour-input input) {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 32px;
}
</style>
