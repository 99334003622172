import axios from 'axios'

export function fetchClients (filters : any = null) {
  let requestData
  if (typeof filters !== 'undefined') {
    requestData = {
      params: filters
    }
  }
  return axios.get('/clients', requestData)
}

export function fetchClient (id: number|string) {
  return axios.get('/clients/' + id)
}

export function createClient (data: Record<string, any>) {
  return axios.post('/clients', data)
}

export function updateClient (id: number|string, data: Record<string, any>) {
  return axios.put('/clients/' + id, data)
}

export function deleteClient (id: number|string) {
  return axios.delete('/clients/' + id)
}

export function createClientContact (data: Record<string, any>) {
  return axios.post('/clients/contact', data)
}

export function updateClientContact (id: string|number) {
  return axios.put('/clients/contact', id)
}

export function deleteClientContact (data: Record<string, any>) {
  return axios.delete('/clients/contact', { data: {id: data}})
}

export function fetchClientContacts (data: Record<string, any>) {
  return axios.get('/clients/' + data.clientId + '/contacts', {
    params: {
      search: data.search
    }
  })
}

export function archiveClients (ids: any) {
  return axios.post('/clients/archive', { ids })
}

export function unarchiveClients (ids: Record<string, any>) {
  return axios.post('/clients/unarchive', { ids })
}

export function getExactOnlineAccounts () {
  return axios.get('/exact-online-accounts')
}

export function syncExactOnlineAccounts () {
  return axios.post('/exact-online-accounts/sync')
}
