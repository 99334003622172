import axios from 'axios'
import router from '../router'
import auth from '@/utils/auth'

export function initInterceptors() {
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if(response.headers.authorization) {
        axios.defaults.headers.common['Authorization'] = response.headers.authorization;
      }

      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const response = error.response

      if (!response) {
        return Promise.reject(error)
      }

      const status = response.status

      if (!status) {
        return Promise.reject(error)
      }

      const data = response.data
      const message = data.message ? data.message : response.statusText

      // If the user is not Unauthorized with a 401 header then redirect to the login page
      // The Unauthenticated check is kept to keep compatibility from before in case it is sent from server
      if (status === 401 || message.indexOf('Unauthenticated') !== -1) {
        if (auth.state.authenticated) {
          // logout the user, do not send a request to the server (trhe user is already logged out on the server)
          auth.logout({makeRequest: false}).then(() => {
            // fully reload the login page
            if (window.location.pathname !== '/login') {
              router.replace('/login').then(() => {
                window.location.reload();
              })
            }
          })
        } else {
          // fully reload the login page
          if (window.location.pathname !== '/login') {
            router.replace('/login').then(() => {
              window.location.reload();
            })
          }
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export default {
  initInterceptors,
}
