<script setup lang="ts">
import {formatBytes} from "@/utils/filters";
import {inject, provide, ref} from "vue";
import {loadImageData, streamDownload} from "@/helpers";
import Preview from "@/components/UI/Documents/Preview.vue";

const props = defineProps({
  files: {
    type: Array,
    default: []
  },
  allowEdit: {
    type: Boolean,
    default: false
  },
  showDescription: {
    type: Boolean,
    default: false
  },
})


const editFile = inject('editFile', false)
const editFileComments = inject('editFileComments', false)
const showFileCommentModal = inject('showFileCommentModal', false)
const removeFile = inject('removeFile', false)

const openDocument = (file: Object) => {
  streamDownload(file)
}
const previewImages = ref({})
const loadPreview = (file: Object, type: String = 'url') => {
  if (previewImages.value[file.hash]) {
    if (true === previewImages.value[file.hash]) {
      return;
    }
    if (type === 'bg') {
      return `url('${previewImages.value[file.hash]}')`
    }
    return previewImages.value[file.hash]
  }
  previewImages.value[file.hash] = true
  loadImageData(file, function (imageData) {
    previewImages.value[file.hash] = imageData
    file.data = imageData;
  })
}

let isPreviewing = ref()
const previewDocument = (file: Object) => {
  isPreviewing.value = file
}
const previewClosed = () => {
  isPreviewing.value = false
}
</script>

<template>
  <Preview
    v-if="isPreviewing"
    :preview-file="isPreviewing"
    @close="previewClosed"
  />
  <div
    v-if="files.length"
    class="table-files"
  >
    <div
      v-for="file in files"
      :key="file.id"
      class="file-line"
    >
      <div class="file-preview">
        <div
          v-if="file.image"
          :style="{'background-image': loadPreview(file, 'bg')}"
          class="preview bg-image"
          @click="previewDocument(file)"
        >
        </div>
        <div
          v-else
          class="preview bg-icon"
        >
          <v-icon
            class="el-icon el-icon-document"
            icon="mdi-file-document-outline"
            @click="openDocument(file)"
          ></v-icon>
        </div>
      </div>
      <div class="file-description" :class="showDescription ? 'show-text' : 'hide-text'">
        <div class="file-line-header" :title="file.filename">
            {{ file.filename }}
        </div>
        <span
          :class="`file-comments ${file.comments ? 'file-comments-edit' : 'file-comments-add'}`"
          @click="editFile = Object.assign({}, file); editFileComments = file.comments; showFileCommentModal = true;"
        >
          <template  v-if="showDescription">
            {{ !file.comments ? $t('addComment') : file.comments }}
          </template>
          <v-menu
            v-if="allowEdit"
            :offset="10"
            location="end center"
            open-on-hover
          >
            <template v-slot:activator="{ props, isActive }">
              <v-icon
                icon="true"
                size="default"
                v-bind="props"
                class="edit-trigger"
              >
                {{ !isActive ? 'mdi-pencil' : 'mdi-close' }}
              </v-icon>
            </template>
            <v-row class="bg-grey-lighten-2 rounded-xl">
              <v-icon
                icon="mdi-link-circle"
                class="ma-1"
                color="blue"
                size="large"
                :disabled="!file.hash"
                @click="openDocument(file)"
              ></v-icon>
              <v-icon
                icon="mdi-pencil"
                class="ma-1"
                size="large"
                @click="editFile = Object.assign({}, file); editFileComments = file.comments; showFileCommentModal = true;"
              ></v-icon>
              <v-icon
                icon="mdi-delete-circle"
                class="ma-1"
                color="red"
                size="large"
                @click.stop="removeFile(file.id)"
              ></v-icon>
            </v-row>
          </v-menu>
          <v-icon
            v-else
            icon="mdi-link-circle"
            class="ma-0"
            color="blue"
            size="large"
            :disabled="!file.hash"
            @click="openDocument(file)"
          ></v-icon>
        </span>
      </div>
      <span class="file-size">
        {{ formatBytes(file.size, 2) }}
      </span>
    </div>
  </div>
</template>


<style scoped lang="scss">
.table-files {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0 5px;
  margin: 0 -5px;

  .file-line {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    line-height: 1.2;
    padding: 5px;
    margin: 5px;
    width: calc(50% - 10px);

    .edit-trigger {
      position: absolute;
      top: -5px;
      left: 0;
      border-radius: 50%;
      padding: 5px;
      box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
    }
    .file-size {
      position: absolute;
      top: 5px;
      left: 5px;
      font-size: 11px;
      line-height: 11px;
      background-color: #F57828 !important;
      color: #FFFFFF !important;
      border-bottom-right-radius: 3px;
      padding: 3px;
      box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
    }

    .preview {
      width: 70px;
      height: 70px;
      cursor: pointer;

      &.bg-image {
        background-size: cover !important;
        background-position: center !important;
      }

      &.bg-icon {
        font-size: 50px;
        line-height: 70px;
      }
    }

    .file-description {
      padding-left: 10px;
      width: calc(100% - 80px);

      .file-line-header {
        margin-bottom: 10px;
        height: 1.2em;
        line-height: 1.2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
      }
      .file-comments {
        text-indent: 30px;
        display: block;
        position: relative;
        word-break: break-word;
      }

      &.hide-text {
        .file-comments {
          text-indent: initial;
        }
      }
    }

  }
}

.no-files-uploaded {
  padding-left: 35px;
  display: flex;
  align-items: center;
  .approve-icon {
    font-size: 24px!important;
    margin-right: 10px;
    color: orange;
  }
}

@media screen and (max-width: 1650px) {
  .table-files {
    .file-line {
      width: 100%;
    }
  }
}
</style>
