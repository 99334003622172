import axios from 'axios'

export function getEmails (params: any) {
  return axios.get('/emails', { params })
}

export function resendEmail (params: any) {
  return axios.post('/emails', params)
}

export function updateEvents (id: number) {
  return axios.get('/emails/' + id + '/events')
}