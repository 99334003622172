<template>
  <v-card>
    <v-card-title>
      <h2>{{ucfirst($t('add')) }} contact</h2>
    </v-card-title>
    <v-card-text>
      <v-text-field
        :label="$t('name')"
        v-model="name"
        required
        ref="nameInput"
      />
      <v-text-field
        :label="$t('phone')"
        v-model="phone"
      />
      <v-text-field
        :label="$t('email')"
        v-model="email"
      />
    </v-card-text>
    <v-card-actions>
      <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
      <div v-if="!loading">
        <v-btn color="orange-darken-2" depressed @click.stop="saveContact()">
          {{ props.clientContact ? $t('edit') : $t('add') }}
        </v-btn>
        <v-btn color="primary" flat @click="$emit('close', false)">
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import Say from '@/utils/Say'
import {createClientContact, fetchClientContacts, updateClientContact} from '@/api/clients'
import { onMounted, ref } from 'vue';
import { ucfirst } from '@/utils/filters';
import emitArray from '@/utils/emit';
import sayErrorResponse, {SayErrorResponse} from '@/mixins/sayErrorResponse';

import { useEventBus } from '@vueuse/core'
const createdBus = useEventBus('created-employees');
const updateBus = useEventBus('updated-employees');

const emit = defineEmits(emitArray.value)

const props = defineProps({
  clientId: {
    type: Number,
    required: true
  },
  clientContact: {
    type: Number,
    required: false,
    default: () => null
  },
})

const name= ref(null)
const phone= ref(null)
const email= ref(null)
const loading= ref(false)

onMounted(() => {
  if (props.clientContact) {
    loading.value = true
    fetchClientContacts({
      clientId: props.clientId
    }).then((response) => {
      const contact = response.data.filter((item) => item.id == props.clientContact)[0]

      name.value = contact.name
      phone.value = contact.phone
      email.value = contact.email
      loading.value = false

    }, (error) => {
      SayErrorResponse(error)
    })
  } else {
    name.value = null
    phone.value = null
    email.value = null
    loading.value = false
  }
})
const saveContact = async() => {
  loading.value = true
  try {
    let saveMethod = !props.clientContact ? createClientContact : updateClientContact
    const response = await saveMethod(Object.assign({
      name: name.value,
      phone: phone.value,
      email: email.value,
      client_id: props.clientId,
      id: props.clientContact
    }, {client_id: props.clientId}))

    loading.value = false
    if (!props.clientContact ){
      Say('success', 'Contact aangemaakt')
      emit('created', response.data.contact)
      createdBus.emit({type: 'executor', data: response.data.contact})
    } else {
      Say('success', 'Contact bijgewerkt')
      emit('update', response.data.contact)
      updateBus.emit({type: 'executor', data: response.data.contact})
    }
    emit('close')
  } catch (err: any) {
    loading.value = false
    sayErrorResponse(err)
  }
}
</script>
