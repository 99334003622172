import axios from 'axios'

export function getProForma (proFormaId: number|string) {
  return axios.get('/pro-formas/' + proFormaId)
}

export function getProFormaAsPdf (proFormaId: number|string) {
  return axios.get('/pro-formas/' + proFormaId + '/pdf', {
    responseType: 'blob'
  })
}

export function createProForma (data: object) {
  return axios.post('/pro-formas', data)
}

export function finalizeProForma (proFormaId: number|string) {
  return axios.put('/pro-formas/' + proFormaId + '/finalize')
}

export function rejectProForma (proFormaId: number|string) {
  return axios.put('/pro-formas/' + proFormaId + '/reject')
}

export function approveProForma (proFormaId: number|string) {
  return axios.put('/pro-formas/' + proFormaId + '/approve')
}

export function convertProFormaToSalesInvoice (proFormaId: number|string, data: Record<string, any>) {
  return axios.put('/pro-formas/' + proFormaId + '/convert-to-sales-invoice', data)
}

export function deleteProForma (proFormaId: number|string) {
  return axios.delete('/pro-formas/' + proFormaId)
}

export function createProFormaLine (proFormaId: number|string, data: Record<string, any>) {
  if (!data.pro_forma_id) {
    data.pro_forma_id = proFormaId
  }
  return axios.post('/pro-formas/' + proFormaId + '/lines', data)
}

export function updateProFormaLine (proFormaId: number|string, proFormaLineId: number|string, data: Record<string, any>) {
  if (!data.pro_forma_id) {
    data.pro_forma_id = proFormaId
  }
  return axios.put('/pro-formas/' + proFormaId + '/lines/' + proFormaLineId, data)
}

export function deleteProFormaLine (proFormaId: number|string, proFormaLineId: number|string) {
  return axios.delete('/pro-formas/' + proFormaId +'/lines/' + proFormaLineId)
}
