<script setup lang="ts">
import {computed, inject, ref, watch} from 'vue';
import {jobEquipmentItemLocationAPI as API} from '@/api/jobs/jobEquipmentItemLocation'
import {useI18n} from 'vue-i18n'
import Datepicker from '@/components/UI/Datepicker.vue'
import sayErrorResponse from '@/mixins/sayErrorResponse'
const { t } = useI18n()

const emit = defineEmits(["equipment-updated", "dialog-closed","show-template-ui","hide-template-ui"]);

const formSubmitted = ref(false)

// Injected from Job/View
const jobId = inject('jobId', null)
API.setJobId(jobId.value)

const job = inject('job', null)

// The list of all items, used to extract details of needed
const items = inject('items', [])

// Currently selected item, when in single item mode
const selectedItem = inject('rentItem', {})

// Currently selected items, when in grouped item mode
const selectedGroup = inject('rentGroup', {})


const selectedDetails = computed(() => {
  let data = {}
  if(mode.value === 'single') {
    data.name = selectedItem.value.name
    data.amount = 1
    data.placed_at_location = selectedItem.value.placed_at
    data.collected_from_location = selectedItem.value.collected_at
    data.rent_started_at = selectedItem.value.rent_started_at
    data.rent_ended_at = selectedItem.value.rent_ended_at
    data.location = selectedItem.value.location.location
    data.originalItem = selectedItem.value.originalItem
    data.rental_supplier_name = selectedItem.value.rental_supplier_name
  } else {
    data.name = selectedGroup.value.name
    data.amount = selectedGroup.value.count
    data.placed_at_location = selectedGroup.value.placed_at
    data.collected_from_location = selectedGroup.value.collected_at
    data.rent_started_at = selectedGroup.value.rent_started_at
    data.rent_ended_at = selectedGroup.value.rent_ended_at
    data.originalItem = selectedGroup.value.originalItem
    data.rental_supplier_name = selectedGroup.value.rental_supplier_name
  }
  if(data.amount === 1) {
    formData.value.amount = 1
  }

  return data
})

const mode = computed(() => {
  if(selectedItem.value && Object.keys(selectedItem.value).length) {
    return 'single'
  }
  if(selectedGroup.value && Object.keys(selectedGroup.value).length) {
    return 'grouped'
  }
  return null
})

// Show the Collect Form
const rentDialogVisible = ref(false as boolean)

const dialogTitle = computed(() => {
  if(mode.value === 'single') {
    return t('dialogRentalStartAndEnd')
  }
  if(mode.value === 'grouped') {
    return t('dialogRentalStartAndEnd')
  }
  return t('dialogRentalStartAndEnd')
})


/**
 * The Form and all the references needed to keep track of that.
 */
const rentForm = ref(null as HTMLFormElement | null)

// Keep track of the form validation state
const formValid = ref(true)


// The model keys being created/edited
const formData = ref({
  amount: null,
  rent_started_at_date: null,
  rent_ended_at_date: null,
})

const minRentalStartDate = computed(() => {
  if(!job.value.rentals_blocked_date) {
    return null
  }
  const minDate = new Date(job.value.rentals_blocked_date);
  minDate.setDate(minDate.getDate() + 1)
  return minDate
})
const minRentalEndDate = computed(() => {
  if(!job.value.rentals_blocked_date) {
    return null
  }
  return new Date(job.value.rentals_blocked_date);
})

/**
 * Form validation rules
 */
const formRules = ref({
  amount: [
    value => {
      if (mode.value === 'single') return true
      if (value) return true
      return 'Enter Amount'
    },
  ],
})

watch(
  () => [selectedItem.value, selectedGroup.value],
  () => {
    if(selectedItem.value?.id || selectedGroup.value?.id) {

      if(!formData.value.amount && selectedDetails.value.amount) {
        formData.value.amount = selectedDetails.value.amount
      }

      if(selectedDetails.value.originalItem.rent_started_at) {
        formData.value.rent_started_at_date = selectedDetails.value.originalItem.rent_started_at
      }

      if(selectedDetails.value.originalItem.rent_ended_at) {
        formData.value.rent_ended_at_date = selectedDetails.value.originalItem.rent_ended_at
      }

      rentDialogVisible.value = true

    } else {
      rentDialogVisible.value = false
    }
  }
)

const postFormData = computed(() => {
  let data = {};


  data.compositeItems = [];
  data.normalItems = [];
  if(mode.value === 'single') {
    if(selectedItem.value.composite_identifier) {
      data.compositeItems.push(selectedItem.value.composite_identifier)
    } else {
      data.normalItems.push(selectedItem.value.id)
    }
    data.process_grouped = false
  } else {
    if(selectedGroup.value.composite_equipment_id) {
      data.compositeItems.push(selectedGroup.value.HASH)
    } else {
      data.normalItems.push(selectedGroup.value.HASH)
    }
    data.process_grouped = true
  }
  data.amount = formData.value.amount

  // Also set the value to send to server for the dates

  data.rent_started_at = (formData.value.rent_started_at_date === 'Invalid date') ? null : formData.value.rent_started_at_date
  data.rent_ended_at = (formData.value.rent_ended_at_date === 'Invalid date') ? null : formData.value.rent_ended_at_date

  data.nullData = {}
  if(data.rent_started_at === null) {
    data.nullData.rent_started_at = null
  }
  if(data.rent_ended_at === null) {
    data.nullData.rent_ended_at = null
  }
  return data
})

/**
 * Save data to the server
 */
const submitForm = () => {
  if(formSubmitted.value) return

  formSubmitted.value = true

  // Make sure that the form is valid
  if (!formValid.value) {
    formSubmitted.value = false
    return
  }

  API.bulkUpdate({
    ...postFormData.value,
    ...{job_id: jobId?.value}
  })
    .then((response) => {
      emit("equipment-updated");
      closeDialog()
      formSubmitted.value = false
    })
    .catch((error) => {
      formSubmitted.value = false
      sayErrorResponse(error.response?.data?.message ?? error.message)
    })
}

const closeDialog = () => {
  emit("dialog-closed");
  selectedItem.value = {}
  selectedGroup.value = {}
  formData.value.rent_started_at_date = null
  formData.value.rent_ended_at_date = null
  rentForm.value.reset()
}
</script>

<template>
  <div>
    <v-dialog
      v-model="rentDialogVisible"
      width="400"
      @afterLeave="closeDialog"
      id="equipment-items-bulk-update-dialog"
    >
      <v-form
        v-model="formValid"
        ref="rentForm"
        @submit.prevent="submitForm"
      >
        <v-card
          max-width="400"
          prepend-icon="mdi-pencil"
          :title="dialogTitle"
        >
          <template v-slot:text>
            <v-table class="bulk-change-items-list mb-4" >
              <tbody>
              <tr>
                <th>{{t('amount')}}:</th><td>{{selectedDetails.amount}}</td>
              </tr>
              <tr>
                <th>{{t('name')}}:</th><td>{{selectedDetails.name}}</td>
              </tr>
              <tr>
                <th>{{t('rentalSupplier')}}:</th><td>{{selectedDetails.rental_supplier_name}}</td>
              </tr>
              <tr>
                <th>{{t('PlacedEquipmentAt')}}:</th><td>{{selectedDetails.placed_at_location}}</td>
              </tr>
              <tr>
                <th>{{t('CollectedEquipmentAt')}}:</th><td>{{selectedDetails.collected_from_location}}</td>
              </tr>
              <tr>
                <th>{{t('RentEquipmentStart')}}</th><td>{{selectedDetails.rent_started_at}}</td>
              </tr>
              <tr>
                <th>{{t('RentEquipmentEnd')}}:</th><td>{{selectedDetails.rent_ended_at}}</td>
              </tr>
              </tbody>
            </v-table>
            <v-text-field
              v-if="mode === 'grouped'"
              v-model="formData.amount"
              :rules="formRules.amount"
              :label="t('Amount')"
              min="1"
              :max="selectedDetails.amount"
              type="number"
              variant="underlined"
            />
            <v-row>
              <v-col sm="6">
                <div class="datepickerWrapper">
                  <div class="datepickerLabel">{{t('dateStart')}}</div>
                  <Datepicker
                    v-model="formData.rent_started_at_date"
                    :placeholder="t('dateStart')"
                    v-on:update:value-datetime="formData.rent_started_at_date = $event"
                    :teleport="false"
                    :min-date="minRentalStartDate"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <div class="datepickerWrapper">
                  <div class="datepickerLabel">{{t('dateEnd')}}</div>
                  <Datepicker
                    v-model="formData.rent_ended_at_date"
                    :placeholder="t('dateEnd')"
                    v-on:update:value-datetime="formData.rent_ended_at_date = $event"
                    :teleport="false"
                    :min-date="minRentalEndDate"
                  />
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:actions>
            <v-btn
              :text="t('save')"
              type="submit"
            />
            <v-btn
              :text="t('cancel')"
              type="reset"
              @click="closeDialog"
            />
          </template>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style scoped deep lang="scss">
.bulk-change-items-list {
  &.v-table {
    .v-table__wrapper > table > tbody > tr > td,
    .v-table__wrapper > table > tbody > tr > th,
    .v-table__wrapper > table > thead > tr > td,
    .v-table__wrapper > table > thead > tr > th,
    .v-table__wrapper > table > tfoot > tr > td,
    .v-table__wrapper > table > tfoot > tr > th {
      padding:3px;
      height:auto;
    }
  }
}
.v-dialog#equipment-items-bulk-update-dialog > .v-overlay__content > .v-card,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > .v-sheet,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-card,
.v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-sheet {
  overflow: initial;
}
</style>
